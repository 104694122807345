import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class BannerAdsModel extends Model {
  casts(): {} {
    return {
      banner_id: "number",
      advertiser_id: "number",
      image_file: "string",
      image_file_url: "string",
      image_link_url: "string",
      swf_file: "string",
      swf_link_url: "string",
      banner_code: "string",
      new_window: "number",
      size: "string",
      impression_total: "number",
      impression_limit: "number",
      click_total: "number",
      start_date: "string",
      end_date: "string",
      exp_date: "string",
      active: "string",
      status: "string",
    };
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: "banner_id",
    };
  }

  get id(){
    return this.banner_id
  }
}

export default BannerAdsModel;