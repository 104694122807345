import Model from "~/packages/core/models/Model";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";
import StatesModel from "~/packages/core/models/StatesModel";

class JobModel extends Model {
  casts(): {} {
    return {
      id: "number",
      date_created: "string",
      name: "string",
      jb_company_id: "number",
      city: "string",
      state: "string",
      state_other: "string",
      zipcode: "string",
      jb_duration_id: "number",
      description: "string",
      apply_method: "number",
      apply_value: "string",
      user_id: "string",
      is_removed: "number",
      jb_type_id: "number",
      country: "string",
      lat: "string",
      lng: "string",
      jb_currency_id: "number",
      min_salary_original: "number",
      min_salary_converted_to_usd: "number",
      imported_identifier: "string",
      created_at: "string",
      updated_at: "string",
      formated_date: "string",
      url: "string",
      is_new: "number",
      expired_date: "string",
      is_expired: "string",
      job_status_name: "string",
      duration: "object",
      status: "array",
      country_data:CountryWithStatesModel,
      state_data: StatesModel,
    };
  }

  /** SETTERS **/
  set SelectedCountry(countryData:any){
    this.country = countryData.value
  }

  set SelectedState(stateData:any){
    this.state = stateData.value
  }
}

export default JobModel;
