import validate from "/builder/cms-spa/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builder/cms-spa/middleware/auth.global.ts";
import containers_45global from "/builder/cms-spa/middleware/containers.global.ts";
import manifest_45route_45rule from "/builder/cms-spa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  containers_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "verify-token": () => import("/builder/cms-spa/middleware/verifyToken.ts")
}