import FeaturedEventModel from '~/packages/core/models/FeaturedEventModel';
import Collection from '~/packages/core/models/Collection';

class FeaturedEventsCollection extends Collection {
    /**
     * Define associated model to the FeaturedEventsCollection
     */
    model(): typeof FeaturedEventModel {
        return FeaturedEventModel;
    }

    /** Getters **/
}

export default FeaturedEventsCollection;
