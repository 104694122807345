import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CompanyTypeModel from "~/packages/core/models/CompanyTypeModel";
import CompanyTypesCollection from "~/packages/core/models/CompanyTypesCollection";

class CompanyTypesApi extends DefaultApi {
    protected path:string = 'company_types'
    protected collection:BaseCollection = CompanyTypesCollection
    protected model:BaseModel = CompanyTypeModel
}

export default CompanyTypesApi