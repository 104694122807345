import Collection from "~/packages/core/models/Collection";
import VendorDirectoryModel from "~/packages/core/models/marketing/VendorDirectoryModel";

class VendorDirectoryCollection extends Collection {
    //define VendorDirectoryModel to return model
    model(): typeof VendorDirectoryModel {
        return VendorDirectoryModel
    }

    /**** GETTERS ****/
    //@ts-ignore
    get vendorSelectOptions(): {} {
        return this.map((vendor: VendorDirectoryModel) => {
            //@ts-ignore
            return { value: vendor.company, company: vendor.company, id: vendor.id }
        });
    }

}

export default VendorDirectoryCollection