import Collection from "~/packages/core/models/Collection";
import MembershipModel from "~/packages/core/models/MembershipModel";

class MembershipsCollection extends Collection {
    //define Membership model to return model
    model(): typeof MembershipModel {
        return MembershipModel
    }
}

export default MembershipsCollection