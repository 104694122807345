import Collection from "~/packages/core/models/Collection";
import CompanyModel from "~/packages/core/models/CompanyModel";

class CompaniesCollection extends Collection {
    //define Company model to return model
    model(): typeof CompanyModel {
        return CompanyModel
    }

    /**** GETTERS ****/

}

export default CompaniesCollection