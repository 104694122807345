import CategoryModel from '~/packages/core/models/CategoryModel';
import Collection from '~/packages/core/models/Collection';

class CategoriesCollection extends Collection {
    private extractedNames:any = []
    /**
     * Define associated model to the CategoriesCollection
     */
    model(): typeof CategoryModel {
        return CategoryModel;
    }

    /*GETTERS*/
    get selectOptions(): {} {
        return this.map((category: CategoryModel) => {
            // @ts-ignore
            return { value: category.cat_id, name: category.name}
        });
    }

    /** there are instances where we need categories in multiselect
     * for that we need to structure the categories in the following format which supports groups and common selection
     *  { cat_id: 1, value: 1, label: 'Category 1', options: [...] }
     * **/
    get categoriesSelectOptions(): {} {
        return this.map((category: CategoryModel) => {
            // @ts-ignore
            return { cat_id: category.cat_id, value: category.cat_id, label: category.name, options: this.categoryChildrenOptions(category) }
        });
    }

    /* helper method to get the children in an object for multiselect
    * @param category
    * @returns {any[]}
     */
    categoryChildrenOptions(category: any[]): any[] {
        //When children available group them by showing label
        if(category.children && category.children.length > 0) {
            return category.children.map((cat: any) => {
                return  { cat_id: cat.cat_id, value: cat.cat_id, label: cat.name };
            });
        }
        //When there is no children tranform label as select option
        return [{cat_id: category.cat_id, value: category.cat_id, label: category.name}];
    }

     /**
   * Returns an array of all category IDs including their children
   * @returns Array of category IDs
   */
    getAllCategoryIds(): number[] {
        return this.flatMap(category =>
            [category.cat_id, ...(category.children?.map(child => child.cat_id) || [])]
        );
    }

}

export default CategoriesCollection;
