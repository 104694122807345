import Model from "~/packages/core/models/Model";

class CampaignAssetModel extends Model {
  static permission_name: string = "cs_campaign";
  casts(): {} {
    return {
      cs_asset_id: "number",
      status: "number",
      asset_type: "string",
      asset_id: "number",
      campaign_id: "number",
      guaranteed: "number",
      todate_leads: "number",
      conditions: "string",
      cma_lead_rate: "number",
      asset_type_raw: "number",
      notes:"string",
      asset: "any"
    };
  }

  get id() {
    return this.cs_asset_id
  }

}

export default CampaignAssetModel;
