import Collection from "~/packages/core/models/Collection";
import StatesModel from "~/packages/core/models/StatesModel";

class StatesCollection extends Collection {
    //define country_with_states model to return model
    model(): typeof StatesModel {
        return StatesModel
    }

    /**** GETTERS ****/
    //modifying the object that we get from response to show country_with_states list in Multiselect
    get SelectOptions(): {} {
        return this.map((state: StatesModel) => {
            // @ts-ignore
            return { value: state.code, title: state.name, country_code: state.country_code }
        });
    }
}

export default StatesCollection