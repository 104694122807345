// import BaseCollection from '~/packages/core/models/BaseCollection';
import AuthorModel from '~/packages/core/models/AuthorModel';
import Collection from '~/packages/core/models/Collection';

class AuthorsCollection extends Collection {
    /**
     * Define associated model to the ArticleCollection
     */
    model(): typeof AuthorModel {
        return AuthorModel;
    }

    /**** GETTERS ****/
    // @ts-ignore
    //modifying the object that we get from response to show authors list in Multiselect
    get authorSelectOptions(): {} {
        return this.map((author: AuthorModel) => {
            // @ts-ignore
            return { value: author.fullName, name: author.fullName, author_id: author.author_id }
        });
    }
}

export default AuthorsCollection;
