import { reduce, isNull, isUndefined, isArray } from 'lodash';

export const ObjectToQuery = (obj: any) => {
    const custom_conditions = ['type', 'status', 'cs_assets.campaign_id']
    const qs = reduce(obj, (result, value, key) => {
        if (!isNull(value) && !isUndefined(value)) {
            if (isArray(value)) {
                // Handle array values, including nested objects
                result += reduce(value, (result1, value1, index) => {
                    if (typeof value1 === 'object' && !isNull(value1)) {
                        // For each object in the array, convert its properties to query string format
                        result1 += reduce(value1, (result2, innerValue, innerKey) => {
                            if(custom_conditions.some(prop => prop in value1) && !isNull(innerValue) && !isUndefined(innerValue)) {
                                result2 += `${key}[${innerKey}]=${encodeURIComponent(innerValue)}&`;
                            }
                            else if (!isNull(innerValue) && !isUndefined(innerValue)) {
                                result2 += `${key}[${index}][${innerKey}]=${encodeURIComponent(innerValue)}&`;
                            }
                            return result2;
                        }, '');
                    } else {
                        // Handle primitive types in arrays (no object inside array)
                        result1 += `${key}[]=${encodeURIComponent(value1)}&`;
                    }
                    return result1;
                }, '');
            } else {
                // For non-array, non-object values
                result += `${key}=${encodeURIComponent(value)}&`;
            }
        }
        return result;
    }, '').slice(0, -1); // Remove the trailing '&' at the end of the query string

    return qs;
};
