import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import MainProcess from "~/config/Constants/MainProcess";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class GetThemeName extends BaseStaticAction
{
    protected static possibleThemeNames: string[] = [
        MainProcess.DARK_MODE_STRING,
        MainProcess.LIGHT_MODE_STRING
    ]
    static override execute() {
        let htmlClasses = document.getElementsByTagName('html')[0].className

        let theme = MainProcess.LIGHT_MODE_STRING

        this.possibleThemeNames.forEach(themeName => {
            if(htmlClasses.includes(themeName)) {
                theme = themeName
            }
        })

        return theme
    }
}