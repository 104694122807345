import BaseApi from "~/packages/core/services/BaseApi";
import BaseModel from "~/packages/core/models/BaseModel";

class PdfApi extends BaseApi {
    protected path:string = 'pdf'

    async generateThumb(model:BaseModel, modelName: string, pdfColName: string, columnName: string, container: string) {
        try{
            let data:any = {
                entityData: model.getModelData(),
                modelName,
                columnName,
                pdfColName,
                container
            };

            const { data:response } = await this.makeRequest(this.path + '/generate-thumb-from-pdf' , this.POST, data)
            //@ts-ignore
            return response.value
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }
}

export default PdfApi