import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CompanyModel from "~/packages/core/models/CompanyModel";
import CompaniesCollection from "~/packages/core/models/CompaniesCollection";

class CustomPopupsApi extends DefaultApi {
    protected path:string = 'custom_popups'
    protected collection:BaseCollection = CompaniesCollection
    protected model:BaseModel = CompanyModel
}

export default CustomPopupsApi