import AssetModel from '~/packages/core/models/AssetModel';
import Collection from '~/packages/core/models/Collection';

class AssetCollection extends Collection {
    /**
     * Define associated model to the AssetCollection
     */
    model(): typeof AssetModel {
        return AssetModel;
    }
}

export default AssetCollection;
