import AuthApi from "~/packages/core/services/AuthApi";
import AuthorApi from "~/packages/core/services/AuthorApi";
import RoleApi from "~/packages/core/services/RoleApi";
import PermissionApi from "~/packages/core/services/PermissionApi";
import SettingsApi from "~/packages/core/services/SettingsApi";
import CategoriesApi from "~/packages/core/services/content/CategoriesApi";
import BlogsApi from "~/packages/core/services/content/BlogsApi";
import AssetsOverrideApi from "~/packages/core/services/content/AssetsOverrideApi";
import GroupsApi from "~/packages/core/services/GroupsApi";
import UsersApi from "~/packages/core/services/users/UsersApi";
import TitleLevelsApi from "~/packages/core/services/TitleLevelsApi";
import JobFunctionsApi from "~/packages/core/services/JobFunctionsApi";
import UsersStatisticsApi from "~/packages/core/services/users/UsersStatisticsApi";
import MembershipsApi from "~/packages/core/services/MembershipsApi";
import UserGroupsApi from "~/packages/core/services/users/UserGroupsApi";
import CompaniesApi from "~/packages/core/services/CompaniesApi";
import CompanyTypesApi from "~/packages/core/services/CompanyTypesApi";
import CompanyIndustriesApi from "~/packages/core/services/CompanyIndustriesApi";
import CountriesWithStatesApi from "~/packages/core/services/CountriesWithStatesApi";
import BadWordsApi from "~/packages/core/services/BadWordsApi";
import EmailArchiveApi from "~/packages/core/services/EmailArchiveApi";
import CustomPopupsApi from "~/packages/core/services/marketing/CustomPopupsApi";
import VendorDirectoryApi from "~/packages/core/services/marketing/VendorDirectoryApi";
import EmailTemplatesApi from "~/packages/core/services/emailcenter/EmailTemplatesApi";
import BlockedPopupsApi from "~/packages/core/services/marketing/BlockedPopupsApi";
import InterstitialsApi from "~/packages/core/services/marketing/InterstitialsApi";
import JobsApi from "~/packages/core/services/jobboard/JobsApi";
import JobTypesApi from "~/packages/core/services/jobboard/JobTypesApi";
import JobDurationApi from "~/packages/core/services/jobboard/JobDurationApi";
import JobCurrenciesApi from "~/packages/core/services/jobboard/JobCurrenciesApi";
import CaptionsApi from "~/packages/core/services/tools/CaptionsApi";
import SitesApi from "~/packages/core/services/settings/SitesApi";
import UploadApi from "~/packages/core/services/UploadApi";
import AssetViewsApi from "~/packages/core/services/reports/AssetViewsApi";
import AssetCategoriesApi from "~/packages/core/services/reports/AssetCategoriesApi";
import AssetAdsApi from "~/packages/core/services/marketing/AssetAdsApi";
import PopupsApi from "~/packages/core/services/marketing/PopupsApi";
import CampaignFilterApi from "~/packages/core/services/marketing/CampaignFilterApi";
import EventsApi from "~/packages/core/services/content/EventsApi";
import CategorySponsorsApi from "~/packages/core/services/content/CategorySponsorsApi";
import PdfApi from "~/packages/core/services/PdfApi";
import BloggersApi from "~/packages/core/services/content/BloggersApi";
import AdminUsersApi from "~/packages/core/services/users/AdminUsersApi";
import FlaggedUsersApi from "~/packages/core/services/userpolicy/FlaggedUsersApi";
import ImportApi from "~/packages/core/services/tools/ImportApi";
import PreferencesApi from "~/packages/core/services/tools/PreferencesApi";
import ManageReferralApi from "~/packages/core/services/emailcenter/ManageReferralApi";
import BannerAdsApi from "~/packages/core/services/emailcenter/BannerAdsApi";
import SubscriptionMatrixApi from "~/packages/core/services/users/SubscriptionMatrixApi";
import CampaignsApi from "~/packages/core/services/clientservices/CampaignsApi";
import CampaignUsersApi from "~/packages/core/services/clientservices/CampaignUsersApi";
import PopupLevelsApi from "~/packages/core/services/marketing/PopupLevelsApi";
import StatesApi from "~/packages/core/services/settings/StatesApi";
import CountriesApi from "~/packages/core/services/settings/CountriesApi";
import ServerUploadApi from "~/packages/core/services/ServerUploadApi";
import CampaignHistoryApi from "~/packages/core/services/clientservices/CampaignHistoryApi";
import CampaignAssetsApi from "~/packages/core/services/clientservices/CampaignAssetsApi";
import CompanyAliasesApi from "~/packages/core/services/userpolicy/CompanyAliasesApi";
import TranscodingApi from "~/packages/core/services/tools/TranscodingApi";
import WebinarConversionApi from "~/packages/core/services/training/WebinarConversionApi";
import AssetsApi from "~/packages/core/services/content/AssetsApi";
import EmailMd5HashApi from "~/packages/core/services/tools/EmailMd5HashApi";
import AthenaApi from "~/packages/core/services/clientservices/AthenaApi";
import ChangelogApi from "~/packages/core/services/content/ChangelogApi";

const useApi = () => {
  const admins = new AdminUsersApi;
  const assets = new AssetsApi;
  const asset_ads = new AssetAdsApi;
  const asset_categories = new AssetCategoriesApi;
  const assets_related_override = new AssetsOverrideApi;
  const assets_views = new AssetViewsApi;
  const athena = new AthenaApi;
  const auth = new AuthApi;
  const authors = new AuthorApi;
  const bad_words = new BadWordsApi;
  const banner_ads = new BannerAdsApi;
  const blocked_popups = new BlockedPopupsApi;
  const blogs = new BlogsApi;
  const bloggers = new BloggersApi;
  const captions = new CaptionsApi;
  const campaigns = new CampaignsApi;
  const campaign_assets = new CampaignAssetsApi;
  const campaign_filters = new CampaignFilterApi;
  const campaign_history = new CampaignHistoryApi;
  const campaign_users = new CampaignUsersApi;
  const categories = new CategoriesApi;
  const category_sponsors = new CategorySponsorsApi;
  const changelog = new ChangelogApi;
  const companies = new CompaniesApi;
  const company_aliases = new CompanyAliasesApi;
  const company_industries = new CompanyIndustriesApi;
  const company_types = new CompanyTypesApi;
  const countries_with_states = new CountriesWithStatesApi;
  const countries = new CountriesApi;
  const custom_popups = new CustomPopupsApi;
  const email_archive = new EmailArchiveApi;
  const email_templates = new EmailTemplatesApi;
  const email_md5_hashes = new EmailMd5HashApi;
  const events = new EventsApi;
  const flagged_users = new FlaggedUsersApi;
  const groupsApi = new GroupsApi;
  const importApi = new ImportApi;
  const interstitials = new InterstitialsApi;
  const job_currency = new JobCurrenciesApi;
  const job_duration = new JobDurationApi;
  const job_functions = new JobFunctionsApi;
  const jobs = new JobsApi;
  const job_types = new JobTypesApi;
  const memberships = new MembershipsApi;
  const manage_referral = new ManageReferralApi;
  const pdf = new PdfApi;
  const permission = new PermissionApi;
  const preferences = new PreferencesApi;
  const popups = new PopupsApi;
  const popup_levels = new PopupLevelsApi;
  const role = new RoleApi;
  const settings = new SettingsApi;
  const server_upload = new ServerUploadApi;
  const sites = new SitesApi;
  const states = new StatesApi;
  const subscription_matrix = new SubscriptionMatrixApi;
  const title_levels = new TitleLevelsApi;
  const transcoding = new TranscodingApi;
  const upload = new UploadApi;
  const user_groups = new UserGroupsApi;
  const users = new UsersApi;
  const users_statistics = new UsersStatisticsApi;
  const vendor_directory = new VendorDirectoryApi;
  const webinar_conversion_api = new WebinarConversionApi;

  return {
    admins,
    assets,
    asset_ads,
    asset_categories,
    assets_related_override,
    assets_views,
    athena,
    auth,
    authors,
    bad_words,
    banner_ads,
    blocked_popups,
    blogs,
    bloggers,
    captions,
    campaigns,
    company_aliases,
    campaign_assets,
    campaign_filters,
    campaign_history,
    campaign_users,
    categories,
    category_sponsors,
    changelog,
    companies,
    company_industries,
    company_types,
    countries_with_states,
    countries,
    custom_popups,
    email_archive,
    email_templates,
    email_md5_hashes,
    events,
    flagged_users,
    groupsApi,
    importApi,
    interstitials,
    job_currency,
    job_duration,
    job_functions,
    jobs,
    job_types,
    memberships,
    manage_referral,
    pdf,
    permission,
    preferences,
    popups,
    popup_levels,
    role,
    settings,
    server_upload,
    sites,
    states,
    subscription_matrix,
    title_levels,
    transcoding,
    upload,
    user_groups,
    users,
    users_statistics,
    vendor_directory,
    webinar_conversion_api
  };
};
export default useApi;
