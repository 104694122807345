import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";
import VendorDirectoryModel from "~/packages/core/models/marketing/VendorDirectoryModel";

class VendorDirectoryApi extends DefaultApi {
    protected path:string = 'vendor_directory'
    protected collection:BaseCollection = VendorDirectoryCollection
    protected model:BaseModel = VendorDirectoryModel

    async searchVendors(query: string, payload?: {}, cast = true) {
        try {
            let url = `vendor_directory/all?fields[]=company&fields[]=id&order_direction=asc&search_fields=company&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new VendorDirectoryCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default VendorDirectoryApi