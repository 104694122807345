import Model from "~/packages/core/models/Model";

class AthenaAssetKeywordsModel extends Model {
  override casts(): {} {
    return {
        id: "number",
        campaign_name: "string",
        keywords: "string",
        cskeywords: "string",
        categories: "string",
        active: "number",
        date_created: "string",
        date_updated: "string",
        last_processed: "string",
        campaign_id: "number",
        content_csv:"any"
    };
  }
}

export default AthenaAssetKeywordsModel;
