import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";
import RoleCollection from "~/packages/core/models/RoleCollection";

class AdminUserModel extends Model {
  static permission_name:string = "madmin_user";
  casts(): {} {
    return {
      user_id: 'number',
      azure_id: "string",
      fullname: "string",
      username: "string",
      password: "string",
      access_level: "string",
      roles: RoleCollection
    };
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: "user_id",
    };
  }

  get id(): number {
    return this.user_id;
  }
}

export default AdminUserModel;
