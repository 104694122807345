import Model from "~/packages/core/models/Model";
import CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";
import AssetModel from "~/packages/core/models/AssetModel";
import {ModelOptions} from "~/packages/core/models/BaseModel";
import PopupSitesCollection from "~/packages/core/models/marketing/PopupSitesCollection";
import CastAssetModelByType from "~/packages/core/models/casters/CastAssetModelByType";

class PopupModel extends Model {
  static default_path:string = 'popups'
  static anonymous_path:string = 'anonymous_popups'
  static subscriber_path:string = 'subscriber_popups'
  static override permission_name:string = "asset_popup";
  static popup_type_anonymous = 'A';
  static popup_type_subscriber = 'S';
  static allowed_popup_types = [
      this.popup_type_anonymous,
      this.popup_type_subscriber
  ]

  static field_to_path_mapping: object = {
    override: PopupModel.anonymous_path,
    active: PopupModel.subscriber_path
  }
  casts() {
    return {
      // on the popup listing, this field bellow represents the popup website id actually... this is due to the fact
      // that when listing asset popups, the popup id might be duplicated as for each website there will be another
      // entry... that way the grid will not be properly loaded while there will basically be a duplicated key for
      // multiple entries (the number of entries will coincide with the number of websites selected...)
      id: "number",
      popup_id: "number",
      name: "string",
      asset_type: "string",
      popup_type: "string",
      asset_id: "number",
      no_subscribe_form: "number",
      custom_html: "string",
      redirect: "string",
      cs_campaigns_lead_filter_id: "number",
      date_created: "string",
      sites_concat: "string",
      current_popup_site: "string",
      site: "string",
      override: "boolean",
      active: "boolean",
      sites: PopupSitesCollection,
      display_criteria: "string",
      prequal: "string",
      campaign_filter: CampaignFilterModel,
      asset: CastAssetModelByType
    };
  }

  get SelectedCampaign () {
    return { value : this.campaign_filter.id, name: this.campaign_filter.name }
  }

  get SelectedAsset () {
    return { value : this.asset.id, title: this.asset.title }
  }

  get override_field_value () {
    return this.override
  }

  set override_field_value  (value) {
    // @ts-ignore
    this.override = value
    setTimeout(async () => {
      await (new usePopups()).setStatus(this, PopupModel.anonymous_path)
    }, 100)
  }

  get active_field_value () {
    return this.active
  }

  set active_field_value  (value) {
    // @ts-ignore
    this.active = value
    setTimeout(async () => {
      await (new usePopups()).setStatus(this, PopupModel.subscriber_path)
    }, 100)
  }

  assetQueryMap:any = {
    'interview' : { fields:['title','podcast_id'], order_direction:'asc', search_fields:'title,podcast_id' },
    'webinar' : { fields:['title','webinar_id'], order_direction:'asc', search_fields:'title,webinar_id' },
    'blogpost' : { fields:['title','post_id'], order_direction:'asc', search_fields:'title,post_id' }
  }

  //Method to return query for assets
   getQuery () {
    if(this.asset_type == 'interview' || this.asset_type == 'webinar' || this.asset_type == 'blogpost'){
      return this.assetQueryMap[this.asset_type]
    }
    else {
      return { fields:['title','id'], order_direction:'asc', search_fields:'title,id' }
    }
  }

  clearAsset () {
    if(this.asset_type) {
      this.asset = new (useAssetModel(this.asset_type).model)()
    } else {
      this.asset = new AssetModel()
    }
  }

  // asset types
  asset_types = [
    {
      optionsText: "Custom",
      optionsValue: "custom",
    },
    {
      optionsText: "Handbook",
      optionsValue: "handbook",
    },
    {
      optionsText: "Interview",
      optionsValue: "interview",
    },
    {
      optionsText: "Webinar",
      optionsValue: "webinar",
    },
    {
      optionsText: "Whitepaper",
      optionsValue: "whitepaper",
    },
    {
      optionsText: "Blogpost",
      optionsValue: "blogpost",
    }
  ]

  getDefaultOptions(): ModelOptions {
    return {
      identifier: "popup_id",
    };
  }
}

export default PopupModel;
