import Collection from "~/packages/core/models/Collection";
import BlockedPopupModel from "~/packages/core/models/marketing/BlockedPopupModel";

class BlockedPopupsCollection extends Collection {
    //define BlockedPopupModel to return model
    model(): typeof BlockedPopupModel {
        return BlockedPopupModel
    }

    /**** GETTERS ****/

}

export default BlockedPopupsCollection