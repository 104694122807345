import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CountriesCollection from "~/packages/core/models/CountriesCollection";
import CountryModel from "~/packages/core/models/CountryModel";

class CountriesApi extends DefaultApi {
    protected path:string = 'countries'
    protected collection:BaseCollection = CountriesCollection
    protected model:BaseModel = CountryModel
}


export default CountriesApi