import BlogPostModel from '~/packages/core/models/BlogPostModel';
import AssetCollection from '~/packages/core/models/AssetCollection';

class BlogPostCollection extends AssetCollection {
  /**
   * Define associated model to the BlogPostCollection
   */
  model(): typeof BlogPostModel {
    return BlogPostModel;
  }

  get selectOptions() {
    return this.map((asset: any) => {
      return {
        value: asset.post_id,
        title: asset.post_id + " - " + asset.title,
        id: asset.post_id,
      };
    });
  }
}

export default BlogPostCollection;
