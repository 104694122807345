import Model from "~/packages/core/models/Model";
import {trim} from "lodash";

class ImportModel extends Model {
     static permission_name: string = "import_gate";
    _getters_list: string[] = [
        'preview_selected_fields',
        'uploaded_file'
    ]

    casts() {
        return {
            id: "uid",
            preferences_selector: "number",
            upload_sp: "boolean",
            is_acq: "boolean",
            imp_new_comp: "boolean",
            is_update: "boolean",
            data_append_project: "boolean",
            deactivate_contacts: "boolean",
            deactivate_contacts_after: "boolean",
            is_event_subscribers: "boolean",
            is_cio_subscribers: "boolean",
            is_cyed_subscribers: "boolean",
            activate_contacts: "boolean",
            deactivate_contacts_reason: "string",
            csvfile: "any",
            fields: "array",
            filename: "string",
            teaser: "string"
        };
    }
    /**** GETTERS ****/
    get preview_selected_fields() {
        let fields = '';
        this.fields.forEach((field:string, k:number) => {
            fields += field + '; '
        })
        return trim(fields, '; ')
    }

    get uploaded_file() {
        // @ts-ignore
        return this.csvfile instanceof File ? this.csvfile.name : '';
    }
    /**
     * Getter example use case
     */
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + "...";
    //   }
    // }
    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */

    /** SETTERS */

    /** SETTERS END */
}

export default ImportModel;
