import LoginProcess from '~/config/Constants/LoginProcess';
import MainProcess from '~/config/Constants/MainProcess';
import useLog from "~/packages/core/composables/useLog";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (typeof window !== 'undefined') {
        const {authStore,logout, verifyToken } = useAuth();

        // Check if user is stored and has a valid token
        if (authStore.auth.isEmpty() || !authStore.token) {
            // Redirect to login if not logged in and trying to access protected routes
            if (to.path !== LoginProcess.LOGIN_PAGE) {
                return navigateTo(LoginProcess.LOGIN_PAGE, {replace: false});
            }
        } else {
            try {
                // Redirect to main page if logged in and trying to access login page
                if (to.path === LoginProcess.LOGIN_PAGE) {
                    if(authStore.needsAuthorizationRefresh()) {
                        await authStore.setAuthorization()
                    }

                    return navigateTo(MainProcess.BASE_PATH, {replace: false});
                }

            } catch (e) {
                useLog().trace(e)
                await logout();
                return navigateTo(LoginProcess.LOGIN_PAGE, {replace: false});
            }
        }
    }
});
