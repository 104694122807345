import Collection from '~/packages/core/models/Collection';
import BloggerModel from "~/packages/core/models/content/BloggerModel";

class BloggersCollection extends Collection {
    /**
     * Define associated model to the BloggersCollection
     */
    model(): typeof BloggerModel {
        return BloggerModel;
    }


    //modifying the object that we get from response to show authors list in Multiselect
    get bloggersSelectOptions(): {} {
        return this.map((blogger: BloggerModel) => {
            // @ts-ignore
            return { value: blogger.blogger_id, name: blogger.name, blogger_id: blogger.blogger_id }
        });
    }
}

export default BloggersCollection;