/*
 * useAssets extends defaultApi composable to use Api state and Common Api Notifications
 */

import type { Payload } from "~/packages/core/types/Api";
import BaseModel from "~/packages/core/models/BaseModel";

class useAssets extends useDefaultApi {
    protected override api: string = "assets";
    protected override excluded: string[] = [
        "authors",
        "premium",
        "prequal",
        "interviewee_list",
        "related",
        "vendor_directory",
    ];

    async getSearchedAsset(type: string, query: string, payload: Payload) {
        if (query) {
            return await useApi()[this.api].searchAssets(type, query, payload);
        }
    }

    override async get(id: string | number, options?: { payload?: Payload }, url?: string) {
        return super.get(
            id,
            {
                payload: options?.payload || {
                    with: [
                        "authors",
                        "prequal",
                        "vendorDirectory",
                        "premium",
                        "related",
                        "categories",
                    ],
                },
            },
            url
        );
    }

    override async create(model: BaseModel, payload?: Payload, url?: string, goBack = true) {
        return super.create(model, payload, url, goBack, async (entity) => {
            model.id = entity.asset_id;
            if (model) {
                return await this.handleRelation("authors", model, payload, url);
            }
        });
    }

    override async update(model: BaseModel, payload?: Payload, url?: string) {
        model.id = model.asset_id;
        return super.update(model, payload, url, async () => {
            if (model) {
                return await this.handleRelation("authors", model, payload, url);
            }
        });
    }
}

export default useAssets;