import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import JobTypesCollection from "~/packages/core/models/jobboard/JobTypesCollection";
import JobTypeModel from "~/packages/core/models/jobboard/JobTypeModel";

class JobTypesApi extends DefaultApi {
    protected path:string = 'job_type'
    protected collection:BaseCollection = JobTypesCollection
    protected model:BaseModel = JobTypeModel
}


export default JobTypesApi