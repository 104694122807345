import Model from "~/packages/core/models/Model";

class InterstitialModel extends Model {
  casts(): {} {
    return {
      id: "number",
      timestamp: "string",
      active: "string",
      date_start: "string",
      date_end: "string",
      adttl: "number",
      cookiettl: "number",
      url: "string",
      ciddetails: "string",
    };
  }
}

export default InterstitialModel;
