import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import EmailArchiveModel from "~/packages/core/models/EmailArchiveModel";
import EmailArchiveCollection from "~/packages/core/models/EmailArchiveCollection";

class EmailArchiveApi extends DefaultApi {
    protected path:string = 'email_archive'
    protected collection:BaseCollection = EmailArchiveCollection
    protected model:BaseModel = EmailArchiveModel
}

export default EmailArchiveApi