import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AdminUsersCollection from "~/packages/core/models/users/AdminUsersCollection";
import AdminUserModel from "~/packages/core/models/users/AdminUserModel";

class AdminUsersApi extends DefaultApi {
    protected path:string = 'admins'
    protected collection:BaseCollection = AdminUsersCollection
    protected model:BaseModel = AdminUserModel
}

export default AdminUsersApi