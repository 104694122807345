import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseModel from "~/packages/core/models/BaseModel";
import AssetCategoriesModel from "~/packages/core/models/reports/AssetCategoriesModel";

class AssetCategoriesApi extends DefaultApi {
  protected path: string = "asset_categories";
  protected model: BaseModel = AssetCategoriesModel;

  //generate report
  async generateReport(data: Object) {
    try {
      const { data: response, error } = await this.makeRequest(
        this.path,
        this.POST,
        data
      );

      this.handleError(error);
      //@ts-ignore
      return response.value;
    } catch (error) {
      this.handleError(error);
      useLog().trace(error);
    }
  }
}


export default AssetCategoriesApi