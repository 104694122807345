import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import JobDurationCollection from "~/packages/core/models/jobboard/JobDurationCollection";
import JobDurationModel from "~/packages/core/models/jobboard/JobDurationModel";

class JobDurationApi extends DefaultApi {
    protected path:string = 'job_duration'
    protected collection:BaseCollection = JobDurationCollection
    protected model:BaseModel = JobDurationModel
}


export default JobDurationApi