import Collection from "~/packages/core/models/Collection";
import ManageReferralModel from "~/packages/core/models/emailcenter/ManageReferralModel";

class ManageReferralsCollection extends Collection {
    //define ManageReferralModel to return model
    model(): typeof ManageReferralModel {
        return ManageReferralModel
    }

    /**** GETTERS ****/
    get SelectOptions(): {} {
        return this.map((referral: ManageReferralModel) => {
            // @ts-ignore
            return { value: referral.code, code: referral.code, id: referral.code }
        });
    }
}

export default ManageReferralsCollection