/*
* useBlogs extends defaultApi composable to use Api state and Common Api Notifications
 */

import BaseModel from "~/packages/core/models/BaseModel";
import type {Payload} from "~/packages/core/types/Api";

class useBlogs extends useDefaultApi {
    protected api:string = 'blogs'
    protected excluded:string[] = []

    async searchBlogs(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchBlogs(query)
    }
    
    override async create(model: BaseModel, payload?:Payload, url?:string, goBack:boolean = true) {
        super.create(model, payload, url, goBack, async (entity) => {
            model.blog_id = entity.blog_id;
            if (model) {
                //@ts-ignore
                return await useApi()[this.api].handleRelation('blogger', model)
            }
        })
    }
    
    override async update(model: BaseModel, payload?: Payload, url?:string) {
        super.update(model, payload)
        
        if(model){
            // @ts-ignore
            return await useApi()[this.api].handleRelation('blogger', model)
        }
    }
}

export default  useBlogs;