import Collection from "~/packages/core/models/Collection";
import AdminUserModel from "~/packages/core/models/users/AdminUserModel";

class AdminUsersCollection extends Collection {
    //define AdminUserModel to return model
    model(): typeof AdminUserModel {
        return AdminUserModel
    }
}

export default AdminUsersCollection