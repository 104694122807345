import UserModel from "~/packages/core/models/UserModel";
import { merge } from "lodash";

class FlaggedUserModel extends UserModel {
  static permission_name = "user";
  casts() {
    return merge(super.casts(),  {
      append_date_cms_contact_company_import: "string",
      session_id: "string",
      cookie_id: "string",
      id_hash: "string",
      active_website: "string",
      company: "string",
    });
  }
}


export default FlaggedUserModel;