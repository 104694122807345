import MadminUserModel from "~/packages/core/models/MadminUserModel";
import { useAuthStore } from "~/store/AuthStore";
import MainProcess from "~/config/Constants/MainProcess";
import LoginProcess from "~/config/Constants/LoginProcess";
import GetContainers from "~/packages/core/actions/config/GetContainers";
import {useSettingsStore} from "~/store/SettingsStore";
import GetContainersCdn from "~/packages/core/actions/config/GetContainersCdn";

const useAuth = () => {
  const userApi = useApi().auth;
  const authStore = useAuthStore();
  const globalState = useSettingsStore();
  const { notify } = useNotification();

  return {
    login: async () => {
      // @ts-ignore
      let response = await userApi.login(<MadminUserModel>authStore.auth);

      if (response && response.data.auth && response.data.token) {
        // save user in store
        authStore.store(<MadminUserModel>new MadminUserModel(response.data.auth), response.data.token);

        await authStore.setAuthorization(); // setting authorization

        // reset containers every time the user is logging in
        globalState.setContainers(await GetContainers.execute());
        globalState.setContainersCdn(await GetContainersCdn.execute());

        return useNuxtApp().$NuxtRouter.push(MainProcess.BASE_PATH)
      } else {
        notify('error', 'Invalid Credentials!')
      }
    },
    azureLogin: () => {
      // @ts-ignore
      userApi.azureLogin()
    },
    logout: async () => {
      //@ts-ignore
      let response = await userApi.logout();
      // if server logout was a success
      if(response.value.meta.success) {
          authStore.logout()
      }
      return navigateTo(LoginProcess.LOGIN_PAGE);
    },
    getAuthenticatedUser: () => {
      return authStore.getAuthenticatedUser()
    },
    verifyToken: async () => {
      return await userApi.verifyToken()
    },
    getAuthorizationPermissions: () => {
        return authStore.authorization?.permissions
    },
    // todo: add a method has ability in the api as in the composable, that would check current user's ability to access certain entity, gate
    authStore,
  };
};

export default useAuth;
