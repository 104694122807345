import Model from "~/packages/core/models/Model";

class CampaignHistoryModel extends Model {
  static permission_name: string = "cs_campaign";
  casts(): {} {
    return {
      history_id: "number",
      campaign_id: "number",
      cs_asset_id: "number",
      user: "string",
      campaign_action: "string",
      notes: "string",
      bdfield: "string",
      stamp: "string",
      leads: "number",
      bdfield_full_url: "string",
    };
  }

  get id() {
    return this.history_id
  }
}

export default CampaignHistoryModel;
