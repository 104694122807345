import Model from "~/packages/core/models/Model";
import {toString} from "lodash";

class AssetViewsModel extends Model {
    static permission_name: string = "asset_categories_report_gate"
    casts() : {} {
        return {
            text:"string",
            startDate:"string",
            endDate:"string",
            catIDS:"array"
        }
    }

    /** GETTERS **/
    get id() {
        return toString(this.text);
    }
}

export default AssetViewsModel;