import Collection from "~/packages/core/models/Collection";
import JobFunctionModel from "~/packages/core/models/JobFunctionModel";

class JobFunctionsCollection extends Collection {
    //define job_function model to return model
    model(): typeof JobFunctionModel {
        return JobFunctionModel
    }

    /**** GETTERS ****/
    //modifying the object that we get from response to show job_functions list in Multiselect
    get SelectOptions(): {} {
        return this.map((job_function: JobFunctionModel) => {
            // @ts-ignore
            return { value: job_function.id, name: job_function.job }
        });
    }
}

export default JobFunctionsCollection