import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import RelatedAssetsModel from "~/packages/core/models/RelatedAssetsModel";

class AssetsOverrideApi extends DefaultApi {
    protected path:string = 'assets_related_override'
    protected collection:BaseCollection = RelatedAssetsCollection
    protected model:BaseModel = RelatedAssetsModel
}


export default AssetsOverrideApi