import Collection from "~/packages/core/models/Collection";
import SlideModel from "~/packages/core/models/training/SlideModel";

class SlidesCollection extends Collection {
    //define SlotModel to return model
    model(): typeof SlideModel {
        return SlideModel
    }

    /**** GETTERS ****/

}

export default SlidesCollection