<template>
  <div class="relative">
    <div @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <slot name="trigger"></slot>
    </div>
    <transition name="fade">
      <div
          v-show="isVisible"
          :id="tooltipId"
          role="tooltip"
          :class="tooltipClasses"
          style="min-width: max-content; max-width: 350px; white-space: normal;">
        <slot></slot>
        <div :class="arrowClasses"></div>
      </div>
    </transition>
  </div>
</template>

<script setup>
  const props = defineProps({
    placement: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'right', 'bottom', 'left'].includes(value)
    }
  });

  const tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
  const isVisible = ref(false);

  const showTooltip = () => isVisible.value = true;
  const hideTooltip = () => isVisible.value = false;

  const tooltipClasses = computed(() => [
    'absolute z-2100 py-2 px-3 text-xs font-medium text-white bg-gray-700 rounded-lg shadow-sm',
    'transition-opacity duration-300',
    {
      'bottom-full left-1/2 transform -translate-x-1/2 mb-3': props.placement === 'top',
      'top-1/2 left-full transform -translate-y-1/2 ml-3': props.placement === 'right',
      'top-full left-1/2 transform -translate-x-1/2 mt-3': props.placement === 'bottom',
      'top-1/2 right-full transform -translate-y-1/2 mr-3': props.placement === 'left',
    }
  ]);

  const arrowClasses = computed(() => [
    'absolute w-3 h-3 bg-gray-700 transform rotate-45',
    {
      'left-1/2 -translate-x-1/2 -bottom-1.5': props.placement === 'top',
      'top-1/2 -translate-y-1/2 -left-1.5': props.placement === 'right',
      'left-1/2 -translate-x-1/2 -top-1.5': props.placement === 'bottom',
      'top-1/2 -translate-y-1/2 -right-1.5': props.placement === 'left',
    }
  ]);
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
