import Model from "~/packages/core/models/Model";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";

class CampaignFilterCriteriaModel extends Model {
  casts(): {} {
    return {
      start_date: "string",
      end_date: "string",
      revenue_min: "string",
      revenue_max: "string",
      employees_min: "string",
      employees_max: "string",
      aum_min: "string",
      aum_max: "string",
      states: "object",
      countries: "object",
      titles: "object",
      jobs: "object",
      companies: "object",
      industries: "object",
      webinar_date: "object",
      excluded_assets: "object",
      related: RelatedAssetsCollection
    };
  }

  assetTypeOptions = [
    {optionsValue: "articles", optionsText: "article"},
    {optionsValue: "handbooks", optionsText: "handbook"},
    {optionsValue: "podcasts", optionsText: "interview"},
    {optionsValue: "webinars", optionsText: "webinar"},
    {optionsValue: "whitepapers", optionsText: "whitepaper"},
  ]
}

export default CampaignFilterCriteriaModel;
