import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import useLog from "~/packages/core/composables/useLog";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class DownloadBlob extends BaseStaticAction {
    static execute(blob?: Blob, filename?: string, type?:string): void {
        if(!blob || !filename) {
            useLog().info([{blob, filename}])
            return;
        }

        blob = new Blob([blob], {type: type || null});

        const url = URL.createObjectURL(blob);

        // Create an anchor element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Use the desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}