import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseModel from "~/packages/core/models/BaseModel";
import {Payload} from "~/packages/core/types/Api";

class DefaultApiNoCrud extends DefaultApi {

    //Search a list
    async search(payload?:Payload, url?:any, cast = true) {
        this.throwMethodNotAllowedError()
    }
    // Retrieve a list of entity
    async list (payload?:Payload, url?:any, cast:boolean = true){
        this.throwMethodNotAllowedError()
    }

    // Retrieve single entity
    async get(id:any, payload?:Payload, url?:any, cast:boolean = true){
        this.throwMethodNotAllowedError()
    }

    //create entity
    async create(model:BaseModel, payload?:Payload, url?:any, cast:boolean = true){
        this.throwMethodNotAllowedError()
    }

    //update entity
    async update(model:BaseModel, id:any, payload?:Payload, url?:any, cast:boolean = true){
        this.throwMethodNotAllowedError()
    }

    // Delete Entity
    async delete(id:any, url?:any){
        this.throwMethodNotAllowedError()
    }

    // handle relations
    async handleRelation(relation:string, model:any, url?:any) {
        this.throwMethodNotAllowedError()
    }

    throwMethodNotAllowedError() {
        throw new Error("Method not allowed!")
    }
}


export default DefaultApiNoCrud