/*
* useUsers extends defaultApi composable to use Api state and Common Api Notifications
 */

import { type Payload } from "~/packages/core/types/Api";
import UserModel from "~/packages/core/models/UserModel";
import {useGlobalState} from "~/store/useGlobalState";
const usersStatisticsApi = useApi().users_statistics;
class useUsers extends useDefaultApi {
  protected override api: string = "users";
  protected override excluded: string[] = ["title_relation", "job_function", 'state_data', 'country_data', 'enews'];

  async getUserStatistics(options?:{url?: string, payload?: Payload}): Promise<any[]> {
    try {
      if(options?.payload != undefined){
        options.payload = options.payload || { per_page: 25 };
      }
      return await usersStatisticsApi.list(options?.payload, options?.url);
    } catch (error) {
      // @ts-ignore
      useNotification().notify("error", "Something went wrong please try again!");
      throw error;
    }
  }

  async getResetPasswordLink(model:UserModel) {
    useGlobalState().isApiLoading = true;
    try {
      //@ts-ignore
      const response = await useApi()[this.api].getResetPasswordLink(model.getModelData())

      if(response.value.type === "success") {
        // @ts-ignore
        useNotification().notify('success', response.value.messages[0], { dangerouslyHTMLString: true, autoClose: false });
      }
    }
    catch (error){
      // @ts-ignore
      useNotification().notify('error',"Something went wrong please try again!");
    }
    finally {
      useGlobalState().isApiLoading = false;
    }
  }
}

export default  useUsers;