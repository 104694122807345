import { defineStore } from 'pinia'
import MadminUserModel from '~/packages/core/models/MadminUserModel'
import BaseModel from '~/packages/core/models/BaseModel';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // @ts-ignore
        auth: new MadminUserModel(),
        token: null,
        authorization: {
            permissions: '', // Decrypted permissions stored as a key-value pair
            last_updated: 0, // Timestamp of the last permissions update
            cache_time: 300000 // restore time for authorization starts after five minutes
        },
        // returnUrl:   null
    }),
    actions: {
        // Authentication methods
        store(user: MadminUserModel, token: string) {
            // @ts-ignore
            this.auth = <MadminUserModel>user
            // @ts-ignore
            this.token = token // Store the token
        },
        logout() {
            this.$reset()
        },
        getAuthenticatedUser() {
            return this.auth
        },
        // Authorization methods
        /**
         * set permissions
         * @param permissions
         */
        async setAuthorization() {
            this.authorization.permissions = await useApi().auth.getPermissions();
            this.authorization.last_updated = Date.now()
        },
        needsAuthorizationRefresh() {
            // Check if permissions need to be refreshed (older than 5 minutes)
            return (Date.now() - this.authorization.last_updated) > this.authorization.cache_time;
        }
    },
    // @ts-ignore
    persist: {
        // @ts-ignore
        storage: persistedState.localStorage,
        debug: true,
        afterRestore: context => {
            // hack for making sure users collection is properly set
            if(!(context.store.auth.prototype instanceof BaseModel)) {
                context.store.auth = new MadminUserModel(context.store.auth)
            }

            // // Handle decryption if needed after restoring state
            // // If you store encrypted permissions, decrypt them here
            // try {
            //     const encryptedPermissions = context.store.permissions;
            //     if (typeof encryptedPermissions === 'string') {
            //         context.store.authorization.permissions = JSON.parse(
            //             CryptoJS.AES.decrypt(encryptedPermissions, 'base64:0LnV8sWNgcv5KIQFvH1JvXa5PVHyltZZMbeatcRYRNQ=').toString(CryptoJS.enc.Utf8)
            //         );
            //     }
            // } catch (error) {
            //     useLog().error('Error decrypting restored permissions:', error);
            //     context.store.authorization.permissions = {}; // Clear permissions if decryption fails
            // }
        }
    },
})