import HasAbility from "~/packages/core/actions/user/HasAbility";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('can', {
        mounted (el, binding, vnode, prevVnode) {
            if(!HasAbility.execute(binding.value)) {
                // remove element

                if(el.parentElement) {
                    el.parentElement.removeChild(el)
                }
            }
        }
    })
})