import Model from "~/packages/core/models/Model";
import type { ModelOptions } from "~/packages/core/models/BaseModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import CategoryModel from "~/packages/core/models/CategoryModel";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";

class CategorySponsorModel extends Model {
  casts() {
    return {
      cat_id: "number",
      sponsor_id: "number",
      active: "string",
      views: "number",
      maxviews: "number",
      start_date: "string",
      end_date: "string",
      category: "object",
      authors: AuthorsCollection,
      vendor_directory: VendorDirectoryCollection
    };
  }
  getDefaultOptions(): ModelOptions {
    return {
      identifier: "sponsor_id",
    };
  }

  get id() {
    return this.sponsor_id;
  }

  set categoryId(category: any) {
    this.cat_id = category.cat_id;
  }
}

export default CategorySponsorModel;
