import BaseCollection from '~/packages/core/models/BaseCollection'
import PermissionModel from '~/packages/core/models/PermissionModel'

class PermissionCollection extends BaseCollection {
    /**
     * Define associated model to the ArticleCollection
     */
    model(): typeof PermissionModel {
        return PermissionModel;
    }

    get groupedByEntity() {
        let grouped = {}
        // @ts-ignore
        this.forEach((model) => {
            // @ts-ignore
            if(grouped[model.entity]) {
                // @ts-ignore
                grouped[model.entity].push(model)
            } else {
                // @ts-ignore
                grouped[model.entity] = [model]
            }
        })

        return grouped
    }
}

export default PermissionCollection;
