import InterviewModel from '~/packages/core/models/InterviewModel';
import Collection from '~/packages/core/models/Collection';

class InterviewCollection extends Collection {
    /**
     * Define associated model to the InterviewCollection
     */
    model(): typeof InterviewModel {
        return InterviewModel;
    }

    /*Getters*/
    get selectOptions() {
        return this.map((asset: any) => {
            return { value: asset.podcast_id, title: asset.podcast_id + ' - ' + asset.title, id: asset.podcast_id }
        });
    }
}

export default InterviewCollection;