import Model from "~/packages/core/models/Model";
import CompanyTypeModel from "~/packages/core/models/CompanyTypeModel";
import CompanyIndustriesModel from "~/packages/core/models/CompanyIndustriesModel";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";
import StatesModel from "~/packages/core/models/StatesModel";

class CompanyModel extends Model {
  casts(): {} {
    return {
        id: "number",
        account_id: "string",
        account_name: "string",
        annual_revenue: "number",
        type: "string",
        industry: "string",
        employees: "number",
        created_date: "string",
        sales_person: "string",
        billing_street: "string",
        billing_city: "string",
        billing_state_province: "string",
        billing_zip_postal_code: "string",
        billing_country: "string",
        website: "string",
        asset_under_management: "number",
        certificate_charter_number: "string",
        type_id: "number",
        industry_id: "number",
        beds_no: "string",
        sic_1: "number",
        sic_2: "number",
        zoom_industry_hierarchical_category: "string",
        zoom_industry: "string",
        zoom_secondary_industry_hierarchical_category: "string",
        zoom_secondary_industry:"string",
        zoom_ticker: "string",
        zoom_company_url: "string",
        zoom_fax: "string",
        zoom_phone:"string",
        zoom_parent_account_name: "string",
        is_reference: "number",
        address: "string",
        country:CountryWithStatesModel,
        state: StatesModel,
        company_type: CompanyTypeModel,
        company_industry: CompanyIndustriesModel,
    }
  }

    /** GETTERS **/
    //get selected options for select retrieved from server response
    //TODO - COUNTRY AND STATE RELATION GETTERS SHOULD BE MAPPED WHEN AVAILABLE
    get SelectedTypeOptions () {
        return { value : this.company_type.id, title: this.company_type.type }
    }

    get SelectedIndustryOptions () {
        return { value: this.company_industry.id, title: this.company_industry.industry }
    }

    /** SETTERS **/
    //set selected typeID, industryID, country, state from client by grabbing the id from select
    //TODO - COUNTRY AND STATE RELATION SETTERS SHOULD BE MAPPED WHEN AVAILABLE
    set SelectedTypeId(type:any){
        this.type_id = type.value
    }

    set SelectedIndustryId(industry:any){
        this.industry_id = industry.value
    }
    set SelectedCountry(country:any){
        this.billing_country = country.value
    }

    set SelectedState(state:any){
        this.billing_state_province = state.value
    }

    /** Methods **/
     generateMergeUrl(dataArray:any) {
        const fields = ["account_name", "annual_revenue", "employees", "website", "type_id"];
        const selectedFields = {
            account_name: 1,
            annual_revenue: 2,
            employees: 2,
            website: 2,
            type_id: 1
        };

        const urlParts = [
            "action=merge",
            ...dataArray.flatMap((data:any, index:any) => {
                const idx = index + 1;
                return [
                    `company_id[${idx}]=${data.id}`,
                    ...fields.map(field =>
                        data[field] !== undefined ? `${field}[${idx}]=${encodeURIComponent(data[field])}` : null
                    ).filter(Boolean)
                ];
            }),
            ...Object.entries(selectedFields).map(
                ([key, value]) => `selected[${key}]=${value}`
            )
        ];

        return urlParts.join("&");
    }

}

export default CompanyModel