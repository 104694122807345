import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import UserGroupsCollection from "~/packages/core/models/UserGroupsCollection";
import UserGroupModel from "~/packages/core/models/UserGroupModel";

class UserGroupsApi extends DefaultApi {
    protected path:string = 'groups'
    protected collection:BaseCollection = UserGroupsCollection
    protected model:BaseModel = UserGroupModel
}

export default UserGroupsApi