/*
* useAuthors extends defaultApi composable to use Api state and Common Api Notifications
 */

class useAuthors extends useDefaultApi {
    protected api = 'authors'

    async searchAuthors(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchAuthors(query)
    }
}


export default  useAuthors;