<template>
  <div >
    <span>{{time.join(' | ')}}</span>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  params: {
    type: null,
  },
});
const time = [] as any[]

props.params.data.files.forEach(async (file:any) => {
  time.push(file.last_modified);
})
</script>