import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";
import CountriesWithStatesCollection from "~/packages/core/models/CountriesWithStatesCollection";

class CountriesWithStatesApi extends DefaultApi {
    protected path:string = 'countries/with-states'
    protected collection:BaseCollection = CountriesWithStatesCollection
    protected model:BaseModel = CountryWithStatesModel
}

export default CountriesWithStatesApi