import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import BlockedPopupsCollection from "~/packages/core/models/marketing/BlockedPopupsCollection";
import BlockedPopupModel from "~/packages/core/models/marketing/BlockedPopupModel";

class BlockedPopupsApi extends DefaultApi {
  protected path:string = 'blocked_asset_popups'
  protected collection:BaseCollection = BlockedPopupsCollection
  protected model:BaseModel = BlockedPopupModel
}


export default BlockedPopupsApi