import Collection from '~/packages/core/models/Collection';
import CategorySponsorModel from "~/packages/core/models/content/CategorySponsorModel";

class CategorySponsorsCollection extends Collection {
    /**
     * Define associated model to the CategorySponsorsCollection
     */
    model(): typeof CategorySponsorModel {
        return CategorySponsorModel;
    }

    /*Getters*/
}

export default CategorySponsorsCollection;