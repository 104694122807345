/*
* useCampaignFilters extends defaultApi composable to use Api state and Common Api Notifications
 */

class useCampaignFilters extends useDefaultApi {
    protected api = 'campaign_filters'

    async searchCampaignFilters(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchCampaignFilters(query)
    }
}


export default  useCampaignFilters;