import defaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignFiltersCollection from "~/packages/core/models/marketing/CampaignFiltersCollection";
import CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";

class CampaignFilterApi extends defaultApi {
    protected path:string = 'campaign_filters'
    protected collection:BaseCollection = CampaignFiltersCollection
    protected model:BaseModel = CampaignFilterModel
    async searchCampaignFilters(query: string, payload?: {}, cast = true) {
        try {
            let url = `${this.path}/all?fields[]=id&fields[]=name&order_by=name&order_direction=asc&search_fields=name,id&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new CampaignFiltersCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default CampaignFilterApi;
