import Collection from "~/packages/core/models/Collection";
import TranscodingStatusModel from "~/packages/core/models/tools/TranscodingStatusModel";

class TranscodingStatusCollection extends Collection {
    /**
     * Define associated model to the TranscodingCollection
     */
    model(): typeof TranscodingStatusModel {
        return TranscodingStatusModel;
    }
}

export default TranscodingStatusCollection;