const useNotification = () =>{
    return {
        notify : (type:any,msg:any, options:object = {}) => {

            // @ts-ignore
            return  useNuxtApp().$toast[type](msg, options);
        }
    }
}

export default useNotification;