import BaseApi from '~/packages/core/services/BaseApi'
import DefaultApi from "~/packages/core/services/DefaultApi";

class SettingsApi extends DefaultApi {
    protected path:string = 'settings'

    async getWebsiteMatrix() {
        try {
            let url = this.path + '/website-matrix'

            const { data: response } = await this.makeRequest(url, this.GET)

            return response.value
        } catch (error) {
            useLog().trace(error)
        }
    }

    async getContainers() {
        try {
            let url = this.path + '/containers'

            const { data: response } = await this.makeRequest(url, this.GET)

            return response.value
        } catch (error) {
            useLog().trace(error)
        }
    }

    async getContainersCdn() {
        try {
            let url = this.path + '/containers-cdn'

            const { data: response } = await this.makeRequest(url, this.GET)

            return response.value
        } catch (error) {
            useLog().trace(error)
        }
    }
    
    async getSites() {
        try {
            let url = this.path + '/sites'
            
            const { data: response } = await this.makeRequest(url, this.GET)
            
            return response.value
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default SettingsApi
