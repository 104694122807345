import { defineStore } from 'pinia'

interface driverStateI {
    dashboard_drive: boolean
}

export const useDriverStore = defineStore('driver', {
    state: () => {
        return {
            driverState : {
                dashboard_drive: false
            } as driverStateI
        }
    },
    persist: {
        // @ts-ignore
        storage: persistedState.localStorage,
        debug: true,
    }
})