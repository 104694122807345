import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import JobCurrenciesCollection from "~/packages/core/models/jobboard/JobCurrenciesCollection";
import JobCurrencyModel from "~/packages/core/models/jobboard/JobCurrencyModel";

class JobCurrenciesApi extends DefaultApi {
    protected path:string = 'job_currency'
    protected collection:BaseCollection = JobCurrenciesCollection
    protected model:BaseModel = JobCurrencyModel
}


export default JobCurrenciesApi