import Collection from "~/packages/core/models/Collection";
import AssetAdsModel from "~/packages/core/models/marketing/AssetAdsModel";

class AssetAdsCollection extends Collection {
    //define AssetAdsModel to return model
    model(): typeof AssetAdsModel {
        return AssetAdsModel
    }

    /**** GETTERS ****/

}

export default AssetAdsCollection