import Collection from "~/packages/core/models/Collection";
import PreferenceModel from "~/packages/core/models/tools/PreferenceModel";

class PreferencesCollection extends Collection {
    /**
     * Define associated model to the PreferencesCollection
     */
    model(): typeof PreferenceModel {
        return PreferenceModel;
    }

    get PreferencesSelectOptions(): {} {
        return this.map((preference: PreferenceModel) => {
            // @ts-ignore
            return { value: preference.id, name: preference.name, id: preference.id, criteria: preference.criteria }
        });
    }
}

export default PreferencesCollection;