import CasterBase from "~/packages/core/models/casters/CasterBase";
import AssetModel from "~/packages/core/models/AssetModel";

export default class CastAssetModelByType extends CasterBase {
    override cast() {
        // @ts-ignore
        let assetModel = AssetModel

        if(this.data?.asset_type) {
            assetModel = useAssetModel(this.data?.asset_type).model
        }

        return new assetModel(this.data)
    }
}