import Collection from '~/packages/core/models/Collection';
import CampaignUserModel from '~/packages/core/models/clientservices/CampaignUserModel';

class CampaignUsersCollection extends Collection {
    /**
     * Define associated model to the CampaignUsersCollection
     */
    model(): typeof CampaignUserModel {
        return CampaignUserModel;
    }

    /** Getters **/
    get campaignUsersSelectOptions(): {} {
        return this.map((campaignUser: CampaignUserModel) => {
            // @ts-ignore
            return { value: campaignUser.id, name_first: campaignUser.fullName, id: campaignUser.id }
        });
    }
}

export default CampaignUsersCollection;
