import Model from "~/packages/core/models/Model";
import CountryWithStatesModel from "~/packages/core/models/CountryWithStatesModel";
import StatesModel from "~/packages/core/models/StatesModel";

class VendorDirectoryModel extends Model {
  casts(): {} {
    return {
      id: "number",
      listing_type: "string",
      company: "string",
      address: "string",
      address_2: "string",
      city: "string",
      country: "string",
      state: "string",
      state_other: "string",
      zipcode: "string",
      phone: "string",
      description: "string",
      url: "string",
      image: "string",
      date_created: "string",
      email: "string",
      website: "string",
      canonical_url: "string",
      sites: "string",
      username: "string",
      country_data: CountryWithStatesModel,
      state_data: StatesModel,
    };
  }

  /** Setters **/
  set SelectedCountry(country:any){
    this.country = country.value
  }

  set SelectedState(state:any){
    this.state = state.value
  }
}


export default VendorDirectoryModel;