import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import CategoryModel from "~/packages/core/models/CategoryModel";

class CategoriesApi extends DefaultApi {
    protected path:string = 'categories'
    protected collection:BaseCollection = CategoriesCollection
    protected model:BaseModel = CategoryModel

}

export default CategoriesApi;
