import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AthenaAssetKeywordsCollection from "~/packages/core/models/clientservices/AthenaAssetKeywordsCollection";
import AthenaAssetKeywordsModel from "~/packages/core/models/clientservices/AthenaAssetKeywordsModel";

class AthenaApi extends DefaultApi {
  protected path:string = 'athena_asset_keywords'
  protected collection:BaseCollection = AthenaAssetKeywordsCollection
  protected model:BaseModel = AthenaAssetKeywordsModel
  
  async upload(data:any) {
    try {
      const request_url = this.path + '/upload';
      
      const { data:response, error } = await this.makeFileRequest(request_url, this.POST, data)
      this.handleError(error);
      //@ts-ignore
      return response.value;
    }
    catch(error){
      this.handleError(error);
      useLog().trace(error)
    }
  }
  
  async generateReport(model:any) {
    try{
      const request_url = this.path + '/generate';
      const { data:response, error } = await this.makeRequest(request_url, this.POST, model.getModelData(['content_csv']))
      this.handleError(error)
      //@ts-ignore
      return response.value
    }
    catch(error){
      this.handleError(error)
      useLog().trace(error)
    }
  }
}

export default AthenaApi