import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import SubscriptionMatrixModel from "~/packages/core/models/users/SubscriptionMatrixModel";
import SubscriptionMatrixCollection from "~/packages/core/models/users/SubscriptionMatrixCollection";
import type {Payload} from "~/packages/core/types/Api";

class SubscriptionMatrixApi extends DefaultApi {
    protected path:string = 'settings/subscription-matrix'
    protected collection:BaseCollection = SubscriptionMatrixCollection
    protected model:BaseModel = SubscriptionMatrixModel

    async list (payload?:Payload, url?:any, cast:boolean = true){
        try{
            const request_url = this.getRequestUrl(this.path, url, { payload });
            const {data:response} = await this.makeRequest(request_url, this.GET);
            // @ts-ignore
            return  response
        }
        catch (error){
            useLog().trace(error)
        }
    }
}

export default SubscriptionMatrixApi