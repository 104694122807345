import Collection from "~/packages/core/models/Collection";
import SiteModel from "~/packages/core/models/settings/SiteModel";

class SitesCollection extends Collection {
    /**
     * Define associated model to the SitesCollection
     */
    model(): typeof SiteModel {
        return SiteModel;
    }

    /** Getters **/
    get sitesOptions() {
        return this.map((site: SiteModel) => {
            return {
                optionsText: site.FQDN2,
                optionsValue: site.site_character
            }
        })
    }
}

export default SitesCollection;