import {useSettingsStore} from "~/store/SettingsStore";
import GetContainers from "~/packages/core/actions/config/GetContainers";
import GetContainersCdn from "~/packages/core/actions/config/GetContainersCdn";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (typeof window !== 'undefined') {
        const { authStore } = useAuth();
        const globalState = useSettingsStore();

        if(!authStore.auth.isEmpty()) {
            if(!globalState.getContainers()) {
                globalState.setContainers(await GetContainers.execute());
            }

            if(!globalState.getContainersCdn()) {
                globalState.setContainersCdn(await GetContainersCdn.execute());
            }
        }
    }
});
