import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import EmailTemplatesCollection from "~/packages/core/models/emailcenter/EmailTemplatesCollection";
import EmailTemplateModel from "~/packages/core/models/emailcenter/EmailTemplateModel";

class EmailTemplatesApi extends DefaultApi {
    protected path:string = 'email_templates'
    protected collection:BaseCollection = EmailTemplatesCollection
    protected model:BaseModel = EmailTemplateModel
}

export default EmailTemplatesApi