import { merge } from "lodash";
import AssetModel from "~/packages/core/models/AssetModel";
import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";
import type { ModelOptions } from "~/packages/core/models/BaseModel";

class HandbookModel extends AssetModel {
  static fields: string[] = ["title", "id"];
  static search_fields: string = "title,id";

  casts() {
    return merge(super.casts(), {
      id: "number",
      gated_asset: "number",
      sponsorship_logo: "number",
      title: "string",
      folder: "string",
      pdf: "string",
      author: "string",
      description: "string",
      category: "string",
      dl_btn_text: "string",
      source: "string",
      date_pub: "string",
      date_posted: "string",
      downloads: "number",
      keywords: "string",
      active: "string",
      active_campaign: "number",
      vendor_id: "number",
      required_level: "number",
      required_level_vp: "number",
      qone: "string",
      qone_a: "string",
      qtwo: "string",
      qtwo_a: "string",
      qthree: "string",
      qthree_a: "string",
      qfour: "string",
      qfour_a: "string",
      cbquestion: "string",
      cbanswer: "string",
      qmessage: "string",
      price: "string",
      hb_frontpage: "string",
      url: "string",
      logo: "string",
      custom_ch_image: "string",
      image_caption: "string",
      email_title: "string",
      email_content: "string",
      email_teaser: "string",
      popup_details: "object",
      canonical_url: "string",
      cloned_hierarchy: "string",
      landing_only: "number",
      lang: "string",
      premium: "object",
      authors: AuthorsCollection,
      vendor_directory: VendorDirectoryCollection,
      categories: CategoriesCollection,
      related: RelatedAssetsCollection,
    });
  }

  /**** GETTERS ****/
  override getDefaultOptions(): ModelOptions {
    return {
      identifier: "id",
    };
  }

  /**** SETTERS ****/
  //setting vendor id on sponsor form
  set VendorID(vendorID: Number) {
    //@ts-ignore
    if (this.vendor_id != vendorID) this.vendor_id = vendorID;
  }
}

export default HandbookModel;
