import Model from "~/packages/core/models/Model";

class BlockedPopupModel extends Model {
  static permission_name:string = "blocked_asset_popup"
  casts(): {} {
    return{
      id: "number",
      asset_type: "string",
      asset_id: "number",
    }
  }

  //asset-types select options

  assetTypesOptions = [
    {
      optionsText: "Article",
      optionsValue: "article",
    },
    {
      optionsText: "Handbook",
      optionsValue: "handbook",
    },
    {
      optionsText: "Interview/Podcast",
      optionsValue: "podcast",
    },
    {
      optionsText: "Webinar",
      optionsValue: "webinar",
    },
    {
      optionsText: "Whitepaper",
      optionsValue: "whitepaper",
    },
  ];
}

export default BlockedPopupModel;