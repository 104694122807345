import GroupModel from "~/packages/core/models/GroupModel";
import {merge} from "lodash";
import FeaturedMenusItemsCollection from "~/packages/core/models/tools/FeaturedMenusItemsCollection";

class FeaturedMenuModel extends GroupModel {
    casts() :{} {
        return merge(super.casts(), {
            id:"uid",
            active:"string",
            featured_menus: FeaturedMenusItemsCollection,
        })
    }
}

export default FeaturedMenuModel;