import Collection from "~/packages/core/models/Collection";
import UserGroupModel from "~/packages/core/models/UserGroupModel";

class UserGroupsCollection extends Collection {
    //define user_group model to return model
    model(): typeof UserGroupModel {
        return UserGroupModel
    }
}

export default UserGroupsCollection