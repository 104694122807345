import Collection from "~/packages/core/models/Collection";
import PopupModel from "~/packages/core/models/marketing/PopupModel";
import ValueToBase64 from "~/packages/core/actions/conversion/ValueToBase64";

class PopupsCollection extends Collection {
    //define PopupModel to return model
    model(): typeof PopupModel {
        return PopupModel
    }

    getEncodedPopupIds() {
        return ValueToBase64.execute(this.getModelPropertyArray('popup_id'))
    }

    /**** GETTERS ****/

}

export default PopupsCollection