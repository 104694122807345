import BaseApi from '~/packages/core/services/BaseApi'
import MadminUserModel from '~/packages/core/models/MadminUserModel'
import LoginProcess from '~/config/Constants/LoginProcess'
import MainProcess from '~/config/Constants/MainProcess'
import {useAuthStore} from '~/store/AuthStore'
import GetContainers from "~/packages/core/actions/config/GetContainers";
import {useSettingsStore} from "~/store/SettingsStore";
import GetContainersCdn from "~/packages/core/actions/config/GetContainersCdn";

class AuthApi extends BaseApi {
    async login(user: MadminUserModel, model = MadminUserModel) {
        let url = 'login';

        let options = {
            post_data: user
        }

        const {
            data: response,
            // error,
            // pending,
            // refresh,
        } = await this.makeRequest(url, this.POST, options);

        return response.value;
    }

    async azureLogin() {
        let server_url = this.getServerUrl()

        const globalState = useSettingsStore();

        // auth popup attributes
        let popup_params = `scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=600,height=500`;

        // open the auth popup
        let newWindow = window.open(server_url + LoginProcess.AZURE_LOGIN_PATH, LoginProcess.AZURE_AUTH_POPUP_NAME, popup_params);

        // after opening the auth popup add the listener on window, that will finalize the login process
        window.addEventListener('message', async (event: MessageEvent) => {
            if (event.origin !== this.getServerUrl()) {
                // @ts-ignore
                useLog().trace('The origin of the login server: ' + event.origin + ', is not the same with the one approved origin: ' + this.getServerUrl() + '!')
                return
            }

            let data = JSON.parse(event.data)

            // add user to store
            const authStore = useAuthStore();

            authStore.store(new MadminUserModel(data), data.token)

            // reset containers every time the user is logging in
            globalState.setContainers(await GetContainers.execute());
            globalState.setContainersCdn(await GetContainersCdn.execute());

            return navigateTo(MainProcess.BASE_PATH)
        });
    }

    async logout() {
        let url = 'logout';

        const {
            data: response,
            // error,
            // pending,
            // refresh,
        } = await this.makeRequest(url, this.POST);

        return response
    }

    async verifyToken() {
        let url = 'auth/verify-token';
        return await this.makeRequest(url, this.POST);
    }

    async getPermissions() {
        let url = 'auth/permissions';
        const {
            data: response,
            // error,
            // pending,
            // refresh,
        } = await this.makeRequest(url, this.POST);

        return response.value.data;
    }
}

export default AuthApi;
