import ChangelogModel from '~/packages/core/models/content/ChangelogModel';
import Collection from '~/packages/core/models/Collection';

class ChangelogsCollection extends Collection {
    /**
     * Define associated model to the ChangelogsCollection
     */
    override model(): typeof ChangelogModel {
        return ChangelogModel;
    }
}

export default ChangelogsCollection;