import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class WebinarConversionModel extends Model {
    casts(): {} {
        return {
            actions: "array",
            filename: "string",
            files: "array"
        }
    }

    getDefaultOptions(): ModelOptions {
        return {
            identifier: "filename",
        };
    }
}

export default WebinarConversionModel;