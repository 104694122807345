import DefaultApi from "~/packages/core/services/DefaultApi";
import ChangelogsCollection from "~/packages/core/models/content/ChangelogsCollection";
import ChangelogModel from "~/packages/core/models/content/ChangelogModel";
import type BaseCollection from "~/packages/core/models/BaseCollection";
import type BaseModel from "~/packages/core/models/BaseModel";

class ChangelogApi extends DefaultApi {
    protected override path:string = 'changelog' 
    protected override collection:BaseCollection = ChangelogsCollection
    protected override model:BaseModel = ChangelogModel
}

export default ChangelogApi