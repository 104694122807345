/*
* useAdminUsers extends defaultApi composable to use Api state and Common Api Notifications
 */

import BaseModel from "~/packages/core/models/BaseModel";
import {useGlobalState} from "~/store/useGlobalState";
import {type Payload} from "~/packages/core/types/Api";
import GoBack from "~/packages/core/actions/utility/GoBack";

class useAdminUsers extends useDefaultApi {
    protected api:string = 'admins'
    protected excluded:string[] = []

    override async create(model: BaseModel, payload?:Payload, url?:string, goBack:boolean = true) {
        super.create(model, payload, url, goBack, async (entity) => {
            model.user_id = entity.user_id;
            if (model) {
                //@ts-ignore
                return await useApi()[this.api].handleRelation('roles', model)
            }
        })
    }

    override async update(model: BaseModel, payload?: Payload, url?:string) {
        super.update(model, payload)

        if(model){
            // @ts-ignore
            return await useApi()[this.api].handleRelation('roles', model)
        }
    }
}

export default  useAdminUsers;