import { defineStore } from 'pinia';
import {isEmpty} from "lodash";

export const useSettingsStore = defineStore('settings', {
    state: () => {
        return {
            settings: {
                websites: Array,
                containers: {},
                containersCdn: {}
            }
        };
    },
    actions: {
        // setters
        setContainers(containers: Object) {
            this.settings.containers = containers
        },
        setContainersCdn(containersCdn: Object) {
            this.settings.containersCdn = containersCdn
        },

        // getters
        getContainers(): Record<string, any> {
            return !isEmpty(this.settings.containers) ? this.settings.containers : false;
        },
        getContainersCdn(): Record<string, any> {
            return !isEmpty(this.settings.containersCdn) ? this.settings.containersCdn : false;
        },
    },
    persist: {
        // @ts-ignore
        storage: persistedState.localStorage,
    },
});



