import Collection from "~/packages/core/models/Collection";
import FlaggedUserModel from "~/packages/core/models/userpolicy/FlaggedUserModel";

class FlaggedUsersCollection extends Collection {
    //define FlaggedUserModel to return model
    model(): typeof FlaggedUserModel {
        return FlaggedUserModel
    }
}

export default FlaggedUsersCollection