import Collection from "~/packages/core/models/Collection";
import WebinarModel from "~/packages/core/models/training/WebinarModel";

class WebinarsCollection extends Collection {
    //define WebinarModel to return model
    model(): typeof WebinarModel {
        return WebinarModel
    }

    /**** GETTERS ****/
    get selectOptions() {
        return this.map((asset: any) => {
            return { value: asset.title, title: asset.webinar_id + ' - ' + asset.title, id: asset.webinar_id }
        });
    }
}

export default WebinarsCollection