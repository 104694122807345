import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import SitesCollection from "~/packages/core/models/settings/SitesCollection";
import SiteModel from "~/packages/core/models/settings/SiteModel";

class SitesApi extends DefaultApi {
    protected path:string = 'settings/sites'
    protected collection:BaseCollection = SitesCollection
    protected model:BaseModel = SiteModel
}


export default SitesApi