import Collection from "~/packages/core/models/Collection";
import CompanyIndustriesModel from "~/packages/core/models/CompanyIndustriesModel";
import CompanyTypeModel from "~/packages/core/models/CompanyTypeModel";

class CompanyIndustriesCollection extends Collection {
    //define Company_types model to return model
    model(): typeof CompanyIndustriesModel {
        return CompanyIndustriesModel
    }

    /**** GETTERS ****/
    //modifying the object that we get from response to show company_types list in Multiselect
    get SelectOptions(): {} {
        return this.map((company_industry: CompanyIndustriesModel) => {
            // @ts-ignore
            return { value: company_industry.id, title: company_industry.industry }
        });
    }

    get campaignSelectOptions(): {} {
        return this.map((company_industry: CompanyIndustriesModel) => {
            // @ts-ignore
            return { value: company_industry.id, name: company_industry.industry }
        });
    }
}

export default CompanyIndustriesCollection