import Collection from "~/packages/core/models/Collection";
import TranscodingModel from "~/packages/core/models/tools/TranscodingModel";

class TranscodingCollection extends Collection {
    /**
     * Define associated model to the TranscodingCollection
     */
    model(): typeof TranscodingModel {
        return TranscodingModel;
    }
}

export default TranscodingCollection;