import FeaturedEventModel from "~/packages/core/models/FeaturedEventModel";
import FeaturedWebinarModel from "~/packages/core/models/FeaturedWebinarModel";
import FeaturedMenuModel from "~/packages/core/models/tools/FeaturedMenuModel";
import AdvisorsBoardModel from "~/packages/core/models/clientservices/AdvisorsBoardModel";

const modelByType = {
    featured_events : FeaturedEventModel,
    featured_webinars : FeaturedWebinarModel,
    featured_menu : FeaturedMenuModel,
    board_of_advisers:AdvisorsBoardModel
};
const useGroupsModel = (type: string) => {
    //@ts-ignore
    const model = modelByType[type];
    return { model };
    // return {model};
};
export default useGroupsModel;
