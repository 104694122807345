import Model from "~/packages/core/models/Model";

class ManageReferralModel extends Model {
    static permission_name: string = "manageable_rf_code_segments";
    casts() {
        return {
            id:"number",
            code:"string"
        }
    }
}

export default ManageReferralModel