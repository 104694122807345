import AuthorsCollection from "~/packages/core/models/AuthorsCollection";
import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AuthorModel from "~/packages/core/models/AuthorModel";

class AuthorApi extends DefaultApi {
    protected path:string = 'authors'
    protected collection:BaseCollection = AuthorsCollection
    protected model:BaseModel = AuthorModel

    async searchAuthors(query: string, payload?: {}, cast = true) {
        try {
            let url = `authors/all?fields[]=author_id&fields[]=name_last&fields[]=name_first&order_by=name_last&order_direction=asc&search_fields=name_first,name_last&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new AuthorsCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default AuthorApi;
