import PrequalModel from '~/packages/core/models/PrequalModel';
import Collection from '~/packages/core/models/Collection';

class PrequalCollection extends Collection {
    /**
     * Define associated model to the PrequalCollection
     */
    model(): typeof PrequalModel {
        return PrequalModel;
    }
}

export default PrequalCollection;
