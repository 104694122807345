import type { ModelOptions } from "~/packages/core/models/BaseModel";
import Model from "~/packages/core/models/Model";

class ChangelogModel extends Model {
  override casts(): {} {
    return {
      id: "number",
      type: "string",
      type_id: "number",
      website: "string",
      name: "string",
      action: "string",
      user: "string",
      link: "string",
      stamp: "string",
    };
  }
}

export default ChangelogModel;