import Collection from "~/packages/core/models/Collection";
import JobDurationModel from "~/packages/core/models/jobboard/JobDurationModel";

class JobDurationCollection extends Collection {
    /**
     * Define associated model to the JobsCollection
     */
    model(): typeof JobDurationModel {
        return JobDurationModel;
    }
}

export default JobDurationCollection;