<template>
  <Teleport to="body">
    <Transition name="modal">
    <div
      v-if="modelValue"
      :inert="!modelValue"
      id="defaultModal"
      tabindex="-1"
      aria-hidden="!modelValue"
      class="fixed inset-0 w-full bg-black bg-opacity-60 h-full flex justify-center px-3 py-6 z-2100"
      @keydown.esc="closeModal"
    >
      <div
        ref="modalRef"
        v-bind="$attrs"
        class="relative z-50 min-h-[150px] max-h-[95%] p-6 self-center w-auto rounded-lg shadow bg-[#FAFAFB] dark:bg-gray-900 overflow-y-auto custom-scrollbar"
      >
        <slot/>
        <button @click.prevent="closeModal" type="button" class="absolute text-gray-900 dark:text-white right-4 top-2 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg><span class="sr-only">Close</span></button>
      </div>
    </div>
    </Transition>
  </Teleport>
</template>
<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['update:modelValue']);

const closeModal = () => {
  emits('update:modelValue', false);
};

// click outside to close modal
const modalRef = ref(null);
onClickOutside(modalRef, closeModal);

// close modal on escape keypress
onMounted(() => {
  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') closeModal();
  });
});
</script>
<style scoped>
.modal-enter-active, .modal-leave-active {
  transition: all 0.25s ease;
}

.modal-enter-from, .modal-leave-to {
  opacity: 0;
  transform: scale(1.1);
}

</style>