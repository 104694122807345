import { default as changelogoltm1Ld7WdMeta } from "/builder/cms-spa/pages/changelog.vue?macro=true";
import { default as indexBGVIeyCJnCMeta } from "/builder/cms-spa/pages/clientservices/advisersboard/index.vue?macro=true";
import { default as indexqVVpsIxn5nMeta } from "/builder/cms-spa/pages/clientservices/athena/index.vue?macro=true";
import { default as _91id_93hea1s1ct9PMeta } from "/builder/cms-spa/pages/clientservices/authors/[id].vue?macro=true";
import { default as addR1PiJQffonMeta } from "/builder/cms-spa/pages/clientservices/authors/add.vue?macro=true";
import { default as indexiwlTSVYT5pMeta } from "/builder/cms-spa/pages/clientservices/authors/index.vue?macro=true";
import { default as _91id_93Y33V7bbYRkMeta } from "/builder/cms-spa/pages/clientservices/campaigns/[id].vue?macro=true";
import { default as addArR8FVpLdNMeta } from "/builder/cms-spa/pages/clientservices/campaigns/add.vue?macro=true";
import { default as _91id_93XnfKea9wYMMeta } from "/builder/cms-spa/pages/clientservices/campaigns/details/[id].vue?macro=true";
import { default as indexEM93lgnTM1Meta } from "/builder/cms-spa/pages/clientservices/campaigns/index.vue?macro=true";
import { default as addwokwtNXNPdMeta } from "/builder/cms-spa/pages/clientservices/campaignusers/add.vue?macro=true";
import { default as indexNU47sVlPoXMeta } from "/builder/cms-spa/pages/clientservices/campaignusers/index.vue?macro=true";
import { default as index27qwIv2F8KMeta } from "/builder/cms-spa/pages/clientservices/ciscocampaign/index.vue?macro=true";
import { default as indexnZLz1pbVZDMeta } from "/builder/cms-spa/pages/clientservices/fortscalecampaign/index.vue?macro=true";
import { default as indexFLtg9SAVLuMeta } from "/builder/cms-spa/pages/clientservices/hidcampaign/index.vue?macro=true";
import { default as indexP15eI07SrfMeta } from "/builder/cms-spa/pages/clientservices/ibmcampaign/index.vue?macro=true";
import { default as indexWvbhkHZBNwMeta } from "/builder/cms-spa/pages/clientservices/oncampaign/index.vue?macro=true";
import { default as indextHpz0eboWmMeta } from "/builder/cms-spa/pages/clientservices/updateincompleteleads/index.vue?macro=true";
import { default as _91id_93vuzWzEmuX3Meta } from "/builder/cms-spa/pages/content/article/[id].vue?macro=true";
import { default as addFxPMmHAb3YMeta } from "/builder/cms-spa/pages/content/article/add.vue?macro=true";
import { default as indexcIiV6Q77NNMeta } from "/builder/cms-spa/pages/content/article/index.vue?macro=true";
import { default as indexzxeHhGFGU7Meta } from "/builder/cms-spa/pages/content/assetoverride/index.vue?macro=true";
import { default as _91id_934FT95GQ1hKMeta } from "/builder/cms-spa/pages/content/blogs/[id].vue?macro=true";
import { default as addpostHIF9bk1TzUMeta } from "/builder/cms-spa/pages/content/blogs/addpost.vue?macro=true";
import { default as _91id_93fzrGTQN2PLMeta } from "/builder/cms-spa/pages/content/blogs/bloggers/[id].vue?macro=true";
import { default as addXQrLCtcoyQMeta } from "/builder/cms-spa/pages/content/blogs/bloggers/add.vue?macro=true";
import { default as indexDjrgZluXzYMeta } from "/builder/cms-spa/pages/content/blogs/bloggers/index.vue?macro=true";
import { default as _91id_93ecOj8hHmm3Meta } from "/builder/cms-spa/pages/content/blogs/blogsites/[id].vue?macro=true";
import { default as addblogf1bxP4e8E6Meta } from "/builder/cms-spa/pages/content/blogs/blogsites/addblog.vue?macro=true";
import { default as indexkFfCmnowwoMeta } from "/builder/cms-spa/pages/content/blogs/blogsites/index.vue?macro=true";
import { default as indexwcvqERuQTgMeta } from "/builder/cms-spa/pages/content/blogs/index.vue?macro=true";
import { default as _91id_939SXoUgZUA8Meta } from "/builder/cms-spa/pages/content/categories/[id].vue?macro=true";
import { default as addTZYN5N9nOBMeta } from "/builder/cms-spa/pages/content/categories/add.vue?macro=true";
import { default as indexY44tuQTGcgMeta } from "/builder/cms-spa/pages/content/categories/index.vue?macro=true";
import { default as _91id_93gQRCJkYeptMeta } from "/builder/cms-spa/pages/content/categories/sponsors/[id].vue?macro=true";
import { default as addmJWrEPdIayMeta } from "/builder/cms-spa/pages/content/categories/sponsors/add.vue?macro=true";
import { default as indexxYNqOHuepEMeta } from "/builder/cms-spa/pages/content/categories/sponsors/index.vue?macro=true";
import { default as indexJvgJWNKWisMeta } from "/builder/cms-spa/pages/content/changelog/index.vue?macro=true";
import { default as indexYN6TvwejzUMeta } from "/builder/cms-spa/pages/content/cloneasset/index.vue?macro=true";
import { default as add5an7zMZvr9Meta } from "/builder/cms-spa/pages/content/contenthubs/add.vue?macro=true";
import { default as indexDfhzgXorumMeta } from "/builder/cms-spa/pages/content/contenthubs/index.vue?macro=true";
import { default as _91id_933KfVtB7xdvMeta } from "/builder/cms-spa/pages/content/event/[id].vue?macro=true";
import { default as addtItJE4mXn2Meta } from "/builder/cms-spa/pages/content/event/add.vue?macro=true";
import { default as index1wRRK7dHRxMeta } from "/builder/cms-spa/pages/content/event/index.vue?macro=true";
import { default as indexPtUjMKl1FfMeta } from "/builder/cms-spa/pages/content/featuredevents/index.vue?macro=true";
import { default as _91id_93e2gVnjqDkXMeta } from "/builder/cms-spa/pages/content/handbook/[id].vue?macro=true";
import { default as addVvsPxxFlMYMeta } from "/builder/cms-spa/pages/content/handbook/add.vue?macro=true";
import { default as indexJkdww197jkMeta } from "/builder/cms-spa/pages/content/handbook/index.vue?macro=true";
import { default as _91id_93oxns5TGycFMeta } from "/builder/cms-spa/pages/content/interview/[id].vue?macro=true";
import { default as add7Uus4MTIpyMeta } from "/builder/cms-spa/pages/content/interview/add.vue?macro=true";
import { default as indexyqQuIAwsQfMeta } from "/builder/cms-spa/pages/content/interview/index.vue?macro=true";
import { default as indexpVEytly6DKMeta } from "/builder/cms-spa/pages/content/seotips/index.vue?macro=true";
import { default as _91id_93Fke52HKsBoMeta } from "/builder/cms-spa/pages/content/video/[id].vue?macro=true";
import { default as addz3EixFUTxAMeta } from "/builder/cms-spa/pages/content/video/add.vue?macro=true";
import { default as indexuYKtawdeAWMeta } from "/builder/cms-spa/pages/content/video/index.vue?macro=true";
import { default as _91id_93U5fcVrPmu6Meta } from "/builder/cms-spa/pages/content/whitepaper/[id].vue?macro=true";
import { default as addcZK7MbCcAGMeta } from "/builder/cms-spa/pages/content/whitepaper/add.vue?macro=true";
import { default as indexLqeMeHznoIMeta } from "/builder/cms-spa/pages/content/whitepaper/index.vue?macro=true";
import { default as dashboardxHVgFMnHOTMeta } from "/builder/cms-spa/pages/dashboard.vue?macro=true";
import { default as indexQb9koFNEsdMeta } from "/builder/cms-spa/pages/index.vue?macro=true";
import { default as indexcMSLqY54QdMeta } from "/builder/cms-spa/pages/jobboard/employercandidates/index.vue?macro=true";
import { default as add30ZSxNoVrCMeta } from "/builder/cms-spa/pages/jobboard/jobs/add.vue?macro=true";
import { default as indexI1nmN2mmDbMeta } from "/builder/cms-spa/pages/jobboard/jobs/index.vue?macro=true";
import { default as indexjNOLbh7FzBMeta } from "/builder/cms-spa/pages/jobboard/orders/index.vue?macro=true";
import { default as indexFgo1vUXY7VMeta } from "/builder/cms-spa/pages/jobboard/settings/index.vue?macro=true";
import { default as login7JE4oTazEeMeta } from "/builder/cms-spa/pages/login.vue?macro=true";
import { default as indexJDB6IYr3y8Meta } from "/builder/cms-spa/pages/marketing/allorder/index.vue?macro=true";
import { default as _91id_93zYm5spoPkDMeta } from "/builder/cms-spa/pages/marketing/assetads/[id].vue?macro=true";
import { default as addOQGExNFCQ7Meta } from "/builder/cms-spa/pages/marketing/assetads/add.vue?macro=true";
import { default as indexnLUpqHJSeiMeta } from "/builder/cms-spa/pages/marketing/assetads/index.vue?macro=true";
import { default as _91id_93dj5RBwcoZ1Meta } from "/builder/cms-spa/pages/marketing/blockpopups/[id].vue?macro=true";
import { default as add9TqbeP53tOMeta } from "/builder/cms-spa/pages/marketing/blockpopups/add.vue?macro=true";
import { default as indexwHJsNCT3iGMeta } from "/builder/cms-spa/pages/marketing/blockpopups/index.vue?macro=true";
import { default as addAWIUHfu8wQMeta } from "/builder/cms-spa/pages/marketing/browsernotification/add.vue?macro=true";
import { default as indexA9Xf8YKxGKMeta } from "/builder/cms-spa/pages/marketing/browsernotification/index.vue?macro=true";
import { default as indexY3PSiPEQEHMeta } from "/builder/cms-spa/pages/marketing/custompopups/index.vue?macro=true";
import { default as index93iKN8yXt6Meta } from "/builder/cms-spa/pages/marketing/editpromotions/index.vue?macro=true";
import { default as addJyyR1vxfiAMeta } from "/builder/cms-spa/pages/marketing/globalcoupons/add.vue?macro=true";
import { default as indexxmiXkvVsaKMeta } from "/builder/cms-spa/pages/marketing/globalcoupons/index.vue?macro=true";
import { default as _91id_93IajdjG0D7ZMeta } from "/builder/cms-spa/pages/marketing/interstitial/[id].vue?macro=true";
import { default as addnuPY1p2P9pMeta } from "/builder/cms-spa/pages/marketing/interstitial/add.vue?macro=true";
import { default as indexIY7BBTBvr5Meta } from "/builder/cms-spa/pages/marketing/interstitial/index.vue?macro=true";
import { default as _91id_93TUqyzqazEnMeta } from "/builder/cms-spa/pages/marketing/popuplevels/details/[id].vue?macro=true";
import { default as indexdQolYE3D5LMeta } from "/builder/cms-spa/pages/marketing/popuplevels/index.vue?macro=true";
import { default as _91id_93XxC9Tv2UZmMeta } from "/builder/cms-spa/pages/marketing/popups/[id].vue?macro=true";
import { default as addGRb1pIIybtMeta } from "/builder/cms-spa/pages/marketing/popups/add.vue?macro=true";
import { default as indexJxJjA8rVX0Meta } from "/builder/cms-spa/pages/marketing/popups/index.vue?macro=true";
import { default as _91id_93rJneKAkhonMeta } from "/builder/cms-spa/pages/marketing/pressrelease/[id].vue?macro=true";
import { default as addg3a8ntO9qOMeta } from "/builder/cms-spa/pages/marketing/pressrelease/add.vue?macro=true";
import { default as index1QeEh4SK2pMeta } from "/builder/cms-spa/pages/marketing/pressrelease/index.vue?macro=true";
import { default as index7aLm9D2MwoMeta } from "/builder/cms-spa/pages/marketing/selfserveads/index.vue?macro=true";
import { default as addTjKdBxDZlRMeta } from "/builder/cms-spa/pages/marketing/specialalerts/add.vue?macro=true";
import { default as indexnOGFqSLwN0Meta } from "/builder/cms-spa/pages/marketing/specialalerts/index.vue?macro=true";
import { default as addDvHQztHWWvMeta } from "/builder/cms-spa/pages/marketing/summitcoupons/add.vue?macro=true";
import { default as indexkLYH5dzGI5Meta } from "/builder/cms-spa/pages/marketing/summitcoupons/index.vue?macro=true";
import { default as _91id_93DsVC2zJfIEMeta } from "/builder/cms-spa/pages/marketing/vendordirectory/[id].vue?macro=true";
import { default as addCnhQ90uRoCMeta } from "/builder/cms-spa/pages/marketing/vendordirectory/add.vue?macro=true";
import { default as indexjyMliivhuPMeta } from "/builder/cms-spa/pages/marketing/vendordirectory/index.vue?macro=true";
import { default as _91id_93EAlyPSdmnNMeta } from "/builder/cms-spa/pages/newsletters/articles/[id].vue?macro=true";
import { default as addjqVVqwLlTjMeta } from "/builder/cms-spa/pages/newsletters/articles/add.vue?macro=true";
import { default as addc5FHNqcBu6Meta } from "/builder/cms-spa/pages/newsletters/bannerads/add.vue?macro=true";
import { default as indexzzyrGizG7BMeta } from "/builder/cms-spa/pages/newsletters/bannerads/index.vue?macro=true";
import { default as indexRtisXphR41Meta } from "/builder/cms-spa/pages/newsletters/emailarchive/index.vue?macro=true";
import { default as _91id_93OkYQEzvkceMeta } from "/builder/cms-spa/pages/newsletters/enews/[id].vue?macro=true";
import { default as addjGnktI1MF2Meta } from "/builder/cms-spa/pages/newsletters/enews/add.vue?macro=true";
import { default as addCH71a5fSzzMeta } from "/builder/cms-spa/pages/newsletters/handbooks/add.vue?macro=true";
import { default as indexqJxmhjntFAMeta } from "/builder/cms-spa/pages/newsletters/index.vue?macro=true";
import { default as _91id_9353E2XN94TfMeta } from "/builder/cms-spa/pages/newsletters/interviews/[id].vue?macro=true";
import { default as addVVGmDnFTzQMeta } from "/builder/cms-spa/pages/newsletters/interviews/add.vue?macro=true";
import { default as _91id_93aPdpW56TwwMeta } from "/builder/cms-spa/pages/newsletters/managereferral/[id].vue?macro=true";
import { default as indexZoWNXLPr9sMeta } from "/builder/cms-spa/pages/newsletters/managereferral/index.vue?macro=true";
import { default as _91id_93pUiAN3DkkiMeta } from "/builder/cms-spa/pages/newsletters/vendorassets/[id].vue?macro=true";
import { default as add6Tl9XkN6ChMeta } from "/builder/cms-spa/pages/newsletters/vendorassets/add.vue?macro=true";
import { default as _91id_938eEAXlyrOYMeta } from "/builder/cms-spa/pages/newsletters/webinars/[id].vue?macro=true";
import { default as add01oBhZZkX1Meta } from "/builder/cms-spa/pages/newsletters/webinars/add.vue?macro=true";
import { default as _91id_93hsVIL1XzlVMeta } from "/builder/cms-spa/pages/newsletters/whitepapers/[id].vue?macro=true";
import { default as addZHKULu4zpvMeta } from "/builder/cms-spa/pages/newsletters/whitepapers/add.vue?macro=true";
import { default as indexiGc0MchYoLMeta } from "/builder/cms-spa/pages/reports/analytics/index.vue?macro=true";
import { default as indexB9QkDVvGowMeta } from "/builder/cms-spa/pages/reports/assetcategories/index.vue?macro=true";
import { default as indexoADySL1U3wMeta } from "/builder/cms-spa/pages/reports/assetsviews/index.vue?macro=true";
import { default as indexX6bTaYRnVwMeta } from "/builder/cms-spa/pages/reports/checkblacklist/index.vue?macro=true";
import { default as index7hR38vPt2QMeta } from "/builder/cms-spa/pages/reports/mediakit/index.vue?macro=true";
import { default as indexYJGFFkiRMYMeta } from "/builder/cms-spa/pages/reports/salesforce/index.vue?macro=true";
import { default as indexmOhN6J012tMeta } from "/builder/cms-spa/pages/reports/searchedterms/index.vue?macro=true";
import { default as indexgTcHZyDCDwMeta } from "/builder/cms-spa/pages/reports/webinarsmatrix/index.vue?macro=true";
import { default as _91id_93zY8pNvbzszMeta } from "/builder/cms-spa/pages/tools/captions/[id].vue?macro=true";
import { default as addSVuOBcARUrMeta } from "/builder/cms-spa/pages/tools/captions/add.vue?macro=true";
import { default as indexLvvtRqjWNwMeta } from "/builder/cms-spa/pages/tools/captions/index.vue?macro=true";
import { default as index95q2CqUn7gMeta } from "/builder/cms-spa/pages/tools/emailmdhash/index.vue?macro=true";
import { default as index3lPWiFDqvbMeta } from "/builder/cms-spa/pages/tools/embedvideocode/index.vue?macro=true";
import { default as indexLVHivKIcAFMeta } from "/builder/cms-spa/pages/tools/eventattendeescredits/index.vue?macro=true";
import { default as indexlsqdoUyhfaMeta } from "/builder/cms-spa/pages/tools/extracteloquadata/index.vue?macro=true";
import { default as indexz5bDusfkNXMeta } from "/builder/cms-spa/pages/tools/importcontactscompany/index.vue?macro=true";
import { default as indexbS6OC0Yac5Meta } from "/builder/cms-spa/pages/tools/mergecompanies/index.vue?macro=true";
import { default as indexkLM07kuEauMeta } from "/builder/cms-spa/pages/tools/resetpasswordlinks/index.vue?macro=true";
import { default as indexjtwKPAlh08Meta } from "/builder/cms-spa/pages/tools/trendingbar/index.vue?macro=true";
import { default as _91type_93aIgse3jBuMMeta } from "/builder/cms-spa/pages/tools/upload-resource/[type].vue?macro=true";
import { default as indexTtTuINsd0qMeta } from "/builder/cms-spa/pages/tools/upload-resource/index.vue?macro=true";
import { default as index8W6cNdYsL8Meta } from "/builder/cms-spa/pages/tools/videotranscoding/index.vue?macro=true";
import { default as indexivuFTFbaHEMeta } from "/builder/cms-spa/pages/tools/webinarsaccesstoken/index.vue?macro=true";
import { default as _91id_93rHcmPLGlkSMeta } from "/builder/cms-spa/pages/training/webinar/[id].vue?macro=true";
import { default as conversiont6ws6e1eV5Meta } from "/builder/cms-spa/pages/training/webinar/[slug]/conversion.vue?macro=true";
import { default as addhWw0MxRqHsMeta } from "/builder/cms-spa/pages/training/webinar/add.vue?macro=true";
import { default as bulkregisterlqzP0mZeiAMeta } from "/builder/cms-spa/pages/training/webinar/bulkregister.vue?macro=true";
import { default as conversionYJW3T276ykMeta } from "/builder/cms-spa/pages/training/webinar/conversion.vue?macro=true";
import { default as featuredKLbhrmmSzJMeta } from "/builder/cms-spa/pages/training/webinar/featured.vue?macro=true";
import { default as indexKRjy1HoeLeMeta } from "/builder/cms-spa/pages/training/webinar/index.vue?macro=true";
import { default as unauthorizedPyvuByODmvMeta } from "/builder/cms-spa/pages/unauthorized.vue?macro=true";
import { default as upload_45testbHiM3J0MmnMeta } from "/builder/cms-spa/pages/upload-test.vue?macro=true";
import { default as _91id_93hAm8TT5ryUMeta } from "/builder/cms-spa/pages/userpolicy/badwords/[id].vue?macro=true";
import { default as add3RpsgSqIWbMeta } from "/builder/cms-spa/pages/userpolicy/badwords/add.vue?macro=true";
import { default as indexHttDJdQGZ7Meta } from "/builder/cms-spa/pages/userpolicy/badwords/index.vue?macro=true";
import { default as _91id_93u4Qbf2WZv5Meta } from "/builder/cms-spa/pages/userpolicy/companies/[id].vue?macro=true";
import { default as addxOC2lNdEvfMeta } from "/builder/cms-spa/pages/userpolicy/companies/add.vue?macro=true";
import { default as indexsKZWCBXLr3Meta } from "/builder/cms-spa/pages/userpolicy/companies/index.vue?macro=true";
import { default as _91id_9303NrnAGRxNMeta } from "/builder/cms-spa/pages/userpolicy/companyalias/[id].vue?macro=true";
import { default as adddtjVYCewRTMeta } from "/builder/cms-spa/pages/userpolicy/companyalias/add.vue?macro=true";
import { default as index975qiDsrIyMeta } from "/builder/cms-spa/pages/userpolicy/companyalias/index.vue?macro=true";
import { default as _91id_93QFvp4nPNRvMeta } from "/builder/cms-spa/pages/userpolicy/companyindustry/[id].vue?macro=true";
import { default as addLGnyqAyzpQMeta } from "/builder/cms-spa/pages/userpolicy/companyindustry/add.vue?macro=true";
import { default as indexCQKvNs4bU8Meta } from "/builder/cms-spa/pages/userpolicy/companyindustry/index.vue?macro=true";
import { default as _91id_93qgpR6G1TXbMeta } from "/builder/cms-spa/pages/userpolicy/companytype/[id].vue?macro=true";
import { default as addn8NMBWOYsCMeta } from "/builder/cms-spa/pages/userpolicy/companytype/add.vue?macro=true";
import { default as indexgJibowTzcYMeta } from "/builder/cms-spa/pages/userpolicy/companytype/index.vue?macro=true";
import { default as _91id_934p8K3dwWvoMeta } from "/builder/cms-spa/pages/userpolicy/flaggedusers/[id].vue?macro=true";
import { default as index725PoBzOl1Meta } from "/builder/cms-spa/pages/userpolicy/flaggedusers/index.vue?macro=true";
import { default as indexV0CZyXQ3m4Meta } from "/builder/cms-spa/pages/userpolicy/ismgregistrations/index.vue?macro=true";
import { default as _91id_93AB7oAxEzw3Meta } from "/builder/cms-spa/pages/userpolicy/jobfunction/[id].vue?macro=true";
import { default as addkbt5NbAYpIMeta } from "/builder/cms-spa/pages/userpolicy/jobfunction/add.vue?macro=true";
import { default as index6ThZj97RCUMeta } from "/builder/cms-spa/pages/userpolicy/jobfunction/index.vue?macro=true";
import { default as _91id_93hgcabTlom0Meta } from "/builder/cms-spa/pages/userpolicy/titlelevel/[id].vue?macro=true";
import { default as addhA0PfwTfSKMeta } from "/builder/cms-spa/pages/userpolicy/titlelevel/add.vue?macro=true";
import { default as indexEpKh5d1ialMeta } from "/builder/cms-spa/pages/userpolicy/titlelevel/index.vue?macro=true";
import { default as _91id_93LIjh61DeKOMeta } from "/builder/cms-spa/pages/users/[id].vue?macro=true";
import { default as addf9c1mol4f9Meta } from "/builder/cms-spa/pages/users/add.vue?macro=true";
import { default as _91id_93iHEI8OVoIuMeta } from "/builder/cms-spa/pages/users/admin/[id].vue?macro=true";
import { default as addjTv8kKdn3YMeta } from "/builder/cms-spa/pages/users/admin/add.vue?macro=true";
import { default as indexBdccugPoXcMeta } from "/builder/cms-spa/pages/users/admin/index.vue?macro=true";
import { default as _91id_93dvgCLWezhaMeta } from "/builder/cms-spa/pages/users/groups/[id].vue?macro=true";
import { default as addkuhKmTTDBrMeta } from "/builder/cms-spa/pages/users/groups/add.vue?macro=true";
import { default as indexdXPxikswRGMeta } from "/builder/cms-spa/pages/users/groups/index.vue?macro=true";
import { default as indexSk6Cpa4cz9Meta } from "/builder/cms-spa/pages/users/index.vue?macro=true";
import { default as _91id_93YxAYyrCIFJMeta } from "/builder/cms-spa/pages/users/membership/[id].vue?macro=true";
import { default as addhFX8h9RsznMeta } from "/builder/cms-spa/pages/users/membership/add.vue?macro=true";
import { default as indexE5sFgnvngUMeta } from "/builder/cms-spa/pages/users/membership/index.vue?macro=true";
import { default as _91id_93YcojH0Ea1YMeta } from "/builder/cms-spa/pages/users/permissions/[id].vue?macro=true";
import { default as addNE9oor0HGfMeta } from "/builder/cms-spa/pages/users/permissions/add.vue?macro=true";
import { default as index4kBaT3vOkAMeta } from "/builder/cms-spa/pages/users/permissions/index.vue?macro=true";
import { default as _91id_93dobUPUfQ8rMeta } from "/builder/cms-spa/pages/users/roles/[id].vue?macro=true";
import { default as addgOKYX8G51JMeta } from "/builder/cms-spa/pages/users/roles/add.vue?macro=true";
import { default as indexCjCrPQhP0oMeta } from "/builder/cms-spa/pages/users/roles/index.vue?macro=true";
export default [
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogoltm1Ld7WdMeta || {},
    component: () => import("/builder/cms-spa/pages/changelog.vue")
  },
  {
    name: "clientservices-advisersboard",
    path: "/clientservices/advisersboard",
    component: () => import("/builder/cms-spa/pages/clientservices/advisersboard/index.vue")
  },
  {
    name: "clientservices-athena",
    path: "/clientservices/athena",
    component: () => import("/builder/cms-spa/pages/clientservices/athena/index.vue")
  },
  {
    name: "clientservices-authors-id",
    path: "/clientservices/authors/:id()",
    component: () => import("/builder/cms-spa/pages/clientservices/authors/[id].vue")
  },
  {
    name: "clientservices-authors-add",
    path: "/clientservices/authors/add",
    component: () => import("/builder/cms-spa/pages/clientservices/authors/add.vue")
  },
  {
    name: "clientservices-authors",
    path: "/clientservices/authors",
    component: () => import("/builder/cms-spa/pages/clientservices/authors/index.vue")
  },
  {
    name: "clientservices-campaigns-id",
    path: "/clientservices/campaigns/:id()",
    component: () => import("/builder/cms-spa/pages/clientservices/campaigns/[id].vue")
  },
  {
    name: "clientservices-campaigns-add",
    path: "/clientservices/campaigns/add",
    component: () => import("/builder/cms-spa/pages/clientservices/campaigns/add.vue")
  },
  {
    name: "clientservices-campaigns-details-id",
    path: "/clientservices/campaigns/details/:id()",
    component: () => import("/builder/cms-spa/pages/clientservices/campaigns/details/[id].vue")
  },
  {
    name: "clientservices-campaigns",
    path: "/clientservices/campaigns",
    component: () => import("/builder/cms-spa/pages/clientservices/campaigns/index.vue")
  },
  {
    name: "clientservices-campaignusers-add",
    path: "/clientservices/campaignusers/add",
    component: () => import("/builder/cms-spa/pages/clientservices/campaignusers/add.vue")
  },
  {
    name: "clientservices-campaignusers",
    path: "/clientservices/campaignusers",
    component: () => import("/builder/cms-spa/pages/clientservices/campaignusers/index.vue")
  },
  {
    name: "clientservices-ciscocampaign",
    path: "/clientservices/ciscocampaign",
    component: () => import("/builder/cms-spa/pages/clientservices/ciscocampaign/index.vue")
  },
  {
    name: "clientservices-fortscalecampaign",
    path: "/clientservices/fortscalecampaign",
    component: () => import("/builder/cms-spa/pages/clientservices/fortscalecampaign/index.vue")
  },
  {
    name: "clientservices-hidcampaign",
    path: "/clientservices/hidcampaign",
    component: () => import("/builder/cms-spa/pages/clientservices/hidcampaign/index.vue")
  },
  {
    name: "clientservices-ibmcampaign",
    path: "/clientservices/ibmcampaign",
    component: () => import("/builder/cms-spa/pages/clientservices/ibmcampaign/index.vue")
  },
  {
    name: "clientservices-oncampaign",
    path: "/clientservices/oncampaign",
    component: () => import("/builder/cms-spa/pages/clientservices/oncampaign/index.vue")
  },
  {
    name: "clientservices-updateincompleteleads",
    path: "/clientservices/updateincompleteleads",
    component: () => import("/builder/cms-spa/pages/clientservices/updateincompleteleads/index.vue")
  },
  {
    name: "content-article-id",
    path: "/content/article/:id()",
    component: () => import("/builder/cms-spa/pages/content/article/[id].vue")
  },
  {
    name: "content-article-add",
    path: "/content/article/add",
    component: () => import("/builder/cms-spa/pages/content/article/add.vue")
  },
  {
    name: "content-article",
    path: "/content/article",
    component: () => import("/builder/cms-spa/pages/content/article/index.vue")
  },
  {
    name: "content-assetoverride",
    path: "/content/assetoverride",
    component: () => import("/builder/cms-spa/pages/content/assetoverride/index.vue")
  },
  {
    name: "content-blogs-id",
    path: "/content/blogs/:id()",
    component: () => import("/builder/cms-spa/pages/content/blogs/[id].vue")
  },
  {
    name: "content-blogs-addpost",
    path: "/content/blogs/addpost",
    component: () => import("/builder/cms-spa/pages/content/blogs/addpost.vue")
  },
  {
    name: "content-blogs-bloggers-id",
    path: "/content/blogs/bloggers/:id()",
    component: () => import("/builder/cms-spa/pages/content/blogs/bloggers/[id].vue")
  },
  {
    name: "content-blogs-bloggers-add",
    path: "/content/blogs/bloggers/add",
    component: () => import("/builder/cms-spa/pages/content/blogs/bloggers/add.vue")
  },
  {
    name: "content-blogs-bloggers",
    path: "/content/blogs/bloggers",
    component: () => import("/builder/cms-spa/pages/content/blogs/bloggers/index.vue")
  },
  {
    name: "content-blogs-blogsites-id",
    path: "/content/blogs/blogsites/:id()",
    component: () => import("/builder/cms-spa/pages/content/blogs/blogsites/[id].vue")
  },
  {
    name: "content-blogs-blogsites-addblog",
    path: "/content/blogs/blogsites/addblog",
    component: () => import("/builder/cms-spa/pages/content/blogs/blogsites/addblog.vue")
  },
  {
    name: "content-blogs-blogsites",
    path: "/content/blogs/blogsites",
    component: () => import("/builder/cms-spa/pages/content/blogs/blogsites/index.vue")
  },
  {
    name: "content-blogs",
    path: "/content/blogs",
    component: () => import("/builder/cms-spa/pages/content/blogs/index.vue")
  },
  {
    name: "content-categories-id",
    path: "/content/categories/:id()",
    component: () => import("/builder/cms-spa/pages/content/categories/[id].vue")
  },
  {
    name: "content-categories-add",
    path: "/content/categories/add",
    component: () => import("/builder/cms-spa/pages/content/categories/add.vue")
  },
  {
    name: "content-categories",
    path: "/content/categories",
    component: () => import("/builder/cms-spa/pages/content/categories/index.vue")
  },
  {
    name: "content-categories-sponsors-id",
    path: "/content/categories/sponsors/:id()",
    component: () => import("/builder/cms-spa/pages/content/categories/sponsors/[id].vue")
  },
  {
    name: "content-categories-sponsors-add",
    path: "/content/categories/sponsors/add",
    component: () => import("/builder/cms-spa/pages/content/categories/sponsors/add.vue")
  },
  {
    name: "content-categories-sponsors",
    path: "/content/categories/sponsors",
    component: () => import("/builder/cms-spa/pages/content/categories/sponsors/index.vue")
  },
  {
    name: "content-changelog",
    path: "/content/changelog",
    component: () => import("/builder/cms-spa/pages/content/changelog/index.vue")
  },
  {
    name: "content-cloneasset",
    path: "/content/cloneasset",
    component: () => import("/builder/cms-spa/pages/content/cloneasset/index.vue")
  },
  {
    name: "content-contenthubs-add",
    path: "/content/contenthubs/add",
    component: () => import("/builder/cms-spa/pages/content/contenthubs/add.vue")
  },
  {
    name: "content-contenthubs",
    path: "/content/contenthubs",
    component: () => import("/builder/cms-spa/pages/content/contenthubs/index.vue")
  },
  {
    name: "content-event-id",
    path: "/content/event/:id()",
    component: () => import("/builder/cms-spa/pages/content/event/[id].vue")
  },
  {
    name: "content-event-add",
    path: "/content/event/add",
    component: () => import("/builder/cms-spa/pages/content/event/add.vue")
  },
  {
    name: "content-event",
    path: "/content/event",
    component: () => import("/builder/cms-spa/pages/content/event/index.vue")
  },
  {
    name: "content-featuredevents",
    path: "/content/featuredevents",
    component: () => import("/builder/cms-spa/pages/content/featuredevents/index.vue")
  },
  {
    name: "content-handbook-id",
    path: "/content/handbook/:id()",
    component: () => import("/builder/cms-spa/pages/content/handbook/[id].vue")
  },
  {
    name: "content-handbook-add",
    path: "/content/handbook/add",
    component: () => import("/builder/cms-spa/pages/content/handbook/add.vue")
  },
  {
    name: "content-handbook",
    path: "/content/handbook",
    component: () => import("/builder/cms-spa/pages/content/handbook/index.vue")
  },
  {
    name: "content-interview-id",
    path: "/content/interview/:id()",
    component: () => import("/builder/cms-spa/pages/content/interview/[id].vue")
  },
  {
    name: "content-interview-add",
    path: "/content/interview/add",
    component: () => import("/builder/cms-spa/pages/content/interview/add.vue")
  },
  {
    name: "content-interview",
    path: "/content/interview",
    component: () => import("/builder/cms-spa/pages/content/interview/index.vue")
  },
  {
    name: "content-seotips",
    path: "/content/seotips",
    component: () => import("/builder/cms-spa/pages/content/seotips/index.vue")
  },
  {
    name: "content-video-id",
    path: "/content/video/:id()",
    component: () => import("/builder/cms-spa/pages/content/video/[id].vue")
  },
  {
    name: "content-video-add",
    path: "/content/video/add",
    component: () => import("/builder/cms-spa/pages/content/video/add.vue")
  },
  {
    name: "content-video",
    path: "/content/video",
    component: () => import("/builder/cms-spa/pages/content/video/index.vue")
  },
  {
    name: "content-whitepaper-id",
    path: "/content/whitepaper/:id()",
    component: () => import("/builder/cms-spa/pages/content/whitepaper/[id].vue")
  },
  {
    name: "content-whitepaper-add",
    path: "/content/whitepaper/add",
    component: () => import("/builder/cms-spa/pages/content/whitepaper/add.vue")
  },
  {
    name: "content-whitepaper",
    path: "/content/whitepaper",
    component: () => import("/builder/cms-spa/pages/content/whitepaper/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/builder/cms-spa/pages/dashboard.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builder/cms-spa/pages/index.vue")
  },
  {
    name: "jobboard-employercandidates",
    path: "/jobboard/employercandidates",
    component: () => import("/builder/cms-spa/pages/jobboard/employercandidates/index.vue")
  },
  {
    name: "jobboard-jobs-add",
    path: "/jobboard/jobs/add",
    component: () => import("/builder/cms-spa/pages/jobboard/jobs/add.vue")
  },
  {
    name: "jobboard-jobs",
    path: "/jobboard/jobs",
    component: () => import("/builder/cms-spa/pages/jobboard/jobs/index.vue")
  },
  {
    name: "jobboard-orders",
    path: "/jobboard/orders",
    component: () => import("/builder/cms-spa/pages/jobboard/orders/index.vue")
  },
  {
    name: "jobboard-settings",
    path: "/jobboard/settings",
    component: () => import("/builder/cms-spa/pages/jobboard/settings/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7JE4oTazEeMeta || {},
    component: () => import("/builder/cms-spa/pages/login.vue")
  },
  {
    name: "marketing-allorder",
    path: "/marketing/allorder",
    component: () => import("/builder/cms-spa/pages/marketing/allorder/index.vue")
  },
  {
    name: "marketing-assetads-id",
    path: "/marketing/assetads/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/assetads/[id].vue")
  },
  {
    name: "marketing-assetads-add",
    path: "/marketing/assetads/add",
    component: () => import("/builder/cms-spa/pages/marketing/assetads/add.vue")
  },
  {
    name: "marketing-assetads",
    path: "/marketing/assetads",
    component: () => import("/builder/cms-spa/pages/marketing/assetads/index.vue")
  },
  {
    name: "marketing-blockpopups-id",
    path: "/marketing/blockpopups/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/blockpopups/[id].vue")
  },
  {
    name: "marketing-blockpopups-add",
    path: "/marketing/blockpopups/add",
    component: () => import("/builder/cms-spa/pages/marketing/blockpopups/add.vue")
  },
  {
    name: "marketing-blockpopups",
    path: "/marketing/blockpopups",
    component: () => import("/builder/cms-spa/pages/marketing/blockpopups/index.vue")
  },
  {
    name: "marketing-browsernotification-add",
    path: "/marketing/browsernotification/add",
    component: () => import("/builder/cms-spa/pages/marketing/browsernotification/add.vue")
  },
  {
    name: "marketing-browsernotification",
    path: "/marketing/browsernotification",
    component: () => import("/builder/cms-spa/pages/marketing/browsernotification/index.vue")
  },
  {
    name: "marketing-custompopups",
    path: "/marketing/custompopups",
    component: () => import("/builder/cms-spa/pages/marketing/custompopups/index.vue")
  },
  {
    name: "marketing-editpromotions",
    path: "/marketing/editpromotions",
    component: () => import("/builder/cms-spa/pages/marketing/editpromotions/index.vue")
  },
  {
    name: "marketing-globalcoupons-add",
    path: "/marketing/globalcoupons/add",
    component: () => import("/builder/cms-spa/pages/marketing/globalcoupons/add.vue")
  },
  {
    name: "marketing-globalcoupons",
    path: "/marketing/globalcoupons",
    component: () => import("/builder/cms-spa/pages/marketing/globalcoupons/index.vue")
  },
  {
    name: "marketing-interstitial-id",
    path: "/marketing/interstitial/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/interstitial/[id].vue")
  },
  {
    name: "marketing-interstitial-add",
    path: "/marketing/interstitial/add",
    component: () => import("/builder/cms-spa/pages/marketing/interstitial/add.vue")
  },
  {
    name: "marketing-interstitial",
    path: "/marketing/interstitial",
    component: () => import("/builder/cms-spa/pages/marketing/interstitial/index.vue")
  },
  {
    name: "marketing-popuplevels-details-id",
    path: "/marketing/popuplevels/details/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/popuplevels/details/[id].vue")
  },
  {
    name: "marketing-popuplevels",
    path: "/marketing/popuplevels",
    component: () => import("/builder/cms-spa/pages/marketing/popuplevels/index.vue")
  },
  {
    name: "marketing-popups-id",
    path: "/marketing/popups/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/popups/[id].vue")
  },
  {
    name: "marketing-popups-add",
    path: "/marketing/popups/add",
    component: () => import("/builder/cms-spa/pages/marketing/popups/add.vue")
  },
  {
    name: "marketing-popups",
    path: "/marketing/popups",
    component: () => import("/builder/cms-spa/pages/marketing/popups/index.vue")
  },
  {
    name: "marketing-pressrelease-id",
    path: "/marketing/pressrelease/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/pressrelease/[id].vue")
  },
  {
    name: "marketing-pressrelease-add",
    path: "/marketing/pressrelease/add",
    component: () => import("/builder/cms-spa/pages/marketing/pressrelease/add.vue")
  },
  {
    name: "marketing-pressrelease",
    path: "/marketing/pressrelease",
    component: () => import("/builder/cms-spa/pages/marketing/pressrelease/index.vue")
  },
  {
    name: "marketing-selfserveads",
    path: "/marketing/selfserveads",
    component: () => import("/builder/cms-spa/pages/marketing/selfserveads/index.vue")
  },
  {
    name: "marketing-specialalerts-add",
    path: "/marketing/specialalerts/add",
    component: () => import("/builder/cms-spa/pages/marketing/specialalerts/add.vue")
  },
  {
    name: "marketing-specialalerts",
    path: "/marketing/specialalerts",
    component: () => import("/builder/cms-spa/pages/marketing/specialalerts/index.vue")
  },
  {
    name: "marketing-summitcoupons-add",
    path: "/marketing/summitcoupons/add",
    component: () => import("/builder/cms-spa/pages/marketing/summitcoupons/add.vue")
  },
  {
    name: "marketing-summitcoupons",
    path: "/marketing/summitcoupons",
    component: () => import("/builder/cms-spa/pages/marketing/summitcoupons/index.vue")
  },
  {
    name: "marketing-vendordirectory-id",
    path: "/marketing/vendordirectory/:id()",
    component: () => import("/builder/cms-spa/pages/marketing/vendordirectory/[id].vue")
  },
  {
    name: "marketing-vendordirectory-add",
    path: "/marketing/vendordirectory/add",
    component: () => import("/builder/cms-spa/pages/marketing/vendordirectory/add.vue")
  },
  {
    name: "marketing-vendordirectory",
    path: "/marketing/vendordirectory",
    component: () => import("/builder/cms-spa/pages/marketing/vendordirectory/index.vue")
  },
  {
    name: "newsletters-articles-id",
    path: "/newsletters/articles/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/articles/[id].vue")
  },
  {
    name: "newsletters-articles-add",
    path: "/newsletters/articles/add",
    component: () => import("/builder/cms-spa/pages/newsletters/articles/add.vue")
  },
  {
    name: "newsletters-bannerads-add",
    path: "/newsletters/bannerads/add",
    component: () => import("/builder/cms-spa/pages/newsletters/bannerads/add.vue")
  },
  {
    name: "newsletters-bannerads",
    path: "/newsletters/bannerads",
    component: () => import("/builder/cms-spa/pages/newsletters/bannerads/index.vue")
  },
  {
    name: "newsletters-emailarchive",
    path: "/newsletters/emailarchive",
    component: () => import("/builder/cms-spa/pages/newsletters/emailarchive/index.vue")
  },
  {
    name: "newsletters-enews-id",
    path: "/newsletters/enews/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/enews/[id].vue")
  },
  {
    name: "newsletters-enews-add",
    path: "/newsletters/enews/add",
    component: () => import("/builder/cms-spa/pages/newsletters/enews/add.vue")
  },
  {
    name: "newsletters-handbooks-add",
    path: "/newsletters/handbooks/add",
    component: () => import("/builder/cms-spa/pages/newsletters/handbooks/add.vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    component: () => import("/builder/cms-spa/pages/newsletters/index.vue")
  },
  {
    name: "newsletters-interviews-id",
    path: "/newsletters/interviews/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/interviews/[id].vue")
  },
  {
    name: "newsletters-interviews-add",
    path: "/newsletters/interviews/add",
    component: () => import("/builder/cms-spa/pages/newsletters/interviews/add.vue")
  },
  {
    name: "newsletters-managereferral-id",
    path: "/newsletters/managereferral/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/managereferral/[id].vue")
  },
  {
    name: "newsletters-managereferral",
    path: "/newsletters/managereferral",
    component: () => import("/builder/cms-spa/pages/newsletters/managereferral/index.vue")
  },
  {
    name: "newsletters-vendorassets-id",
    path: "/newsletters/vendorassets/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/vendorassets/[id].vue")
  },
  {
    name: "newsletters-vendorassets-add",
    path: "/newsletters/vendorassets/add",
    component: () => import("/builder/cms-spa/pages/newsletters/vendorassets/add.vue")
  },
  {
    name: "newsletters-webinars-id",
    path: "/newsletters/webinars/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/webinars/[id].vue")
  },
  {
    name: "newsletters-webinars-add",
    path: "/newsletters/webinars/add",
    component: () => import("/builder/cms-spa/pages/newsletters/webinars/add.vue")
  },
  {
    name: "newsletters-whitepapers-id",
    path: "/newsletters/whitepapers/:id()",
    component: () => import("/builder/cms-spa/pages/newsletters/whitepapers/[id].vue")
  },
  {
    name: "newsletters-whitepapers-add",
    path: "/newsletters/whitepapers/add",
    component: () => import("/builder/cms-spa/pages/newsletters/whitepapers/add.vue")
  },
  {
    name: "reports-analytics",
    path: "/reports/analytics",
    component: () => import("/builder/cms-spa/pages/reports/analytics/index.vue")
  },
  {
    name: "reports-assetcategories",
    path: "/reports/assetcategories",
    component: () => import("/builder/cms-spa/pages/reports/assetcategories/index.vue")
  },
  {
    name: "reports-assetsviews",
    path: "/reports/assetsviews",
    component: () => import("/builder/cms-spa/pages/reports/assetsviews/index.vue")
  },
  {
    name: "reports-checkblacklist",
    path: "/reports/checkblacklist",
    component: () => import("/builder/cms-spa/pages/reports/checkblacklist/index.vue")
  },
  {
    name: "reports-mediakit",
    path: "/reports/mediakit",
    component: () => import("/builder/cms-spa/pages/reports/mediakit/index.vue")
  },
  {
    name: "reports-salesforce",
    path: "/reports/salesforce",
    component: () => import("/builder/cms-spa/pages/reports/salesforce/index.vue")
  },
  {
    name: "reports-searchedterms",
    path: "/reports/searchedterms",
    component: () => import("/builder/cms-spa/pages/reports/searchedterms/index.vue")
  },
  {
    name: "reports-webinarsmatrix",
    path: "/reports/webinarsmatrix",
    component: () => import("/builder/cms-spa/pages/reports/webinarsmatrix/index.vue")
  },
  {
    name: "tools-captions-id",
    path: "/tools/captions/:id()",
    component: () => import("/builder/cms-spa/pages/tools/captions/[id].vue")
  },
  {
    name: "tools-captions-add",
    path: "/tools/captions/add",
    component: () => import("/builder/cms-spa/pages/tools/captions/add.vue")
  },
  {
    name: "tools-captions",
    path: "/tools/captions",
    component: () => import("/builder/cms-spa/pages/tools/captions/index.vue")
  },
  {
    name: "tools-emailmdhash",
    path: "/tools/emailmdhash",
    component: () => import("/builder/cms-spa/pages/tools/emailmdhash/index.vue")
  },
  {
    name: "tools-embedvideocode",
    path: "/tools/embedvideocode",
    component: () => import("/builder/cms-spa/pages/tools/embedvideocode/index.vue")
  },
  {
    name: "tools-eventattendeescredits",
    path: "/tools/eventattendeescredits",
    component: () => import("/builder/cms-spa/pages/tools/eventattendeescredits/index.vue")
  },
  {
    name: "tools-extracteloquadata",
    path: "/tools/extracteloquadata",
    component: () => import("/builder/cms-spa/pages/tools/extracteloquadata/index.vue")
  },
  {
    name: "tools-importcontactscompany",
    path: "/tools/importcontactscompany",
    component: () => import("/builder/cms-spa/pages/tools/importcontactscompany/index.vue")
  },
  {
    name: "tools-mergecompanies",
    path: "/tools/mergecompanies",
    component: () => import("/builder/cms-spa/pages/tools/mergecompanies/index.vue")
  },
  {
    name: "tools-resetpasswordlinks",
    path: "/tools/resetpasswordlinks",
    component: () => import("/builder/cms-spa/pages/tools/resetpasswordlinks/index.vue")
  },
  {
    name: "tools-trendingbar",
    path: "/tools/trendingbar",
    component: () => import("/builder/cms-spa/pages/tools/trendingbar/index.vue")
  },
  {
    name: "tools-upload-resource-type",
    path: "/tools/upload-resource/:type()",
    meta: _91type_93aIgse3jBuMMeta || {},
    component: () => import("/builder/cms-spa/pages/tools/upload-resource/[type].vue")
  },
  {
    name: "tools-upload-resource",
    path: "/tools/upload-resource",
    meta: indexTtTuINsd0qMeta || {},
    component: () => import("/builder/cms-spa/pages/tools/upload-resource/index.vue")
  },
  {
    name: "tools-videotranscoding",
    path: "/tools/videotranscoding",
    component: () => import("/builder/cms-spa/pages/tools/videotranscoding/index.vue")
  },
  {
    name: "tools-webinarsaccesstoken",
    path: "/tools/webinarsaccesstoken",
    component: () => import("/builder/cms-spa/pages/tools/webinarsaccesstoken/index.vue")
  },
  {
    name: "training-webinar-id",
    path: "/training/webinar/:id()",
    component: () => import("/builder/cms-spa/pages/training/webinar/[id].vue")
  },
  {
    name: "training-webinar-slug-conversion",
    path: "/training/webinar/:slug()/conversion",
    component: () => import("/builder/cms-spa/pages/training/webinar/[slug]/conversion.vue")
  },
  {
    name: "training-webinar-add",
    path: "/training/webinar/add",
    component: () => import("/builder/cms-spa/pages/training/webinar/add.vue")
  },
  {
    name: "training-webinar-bulkregister",
    path: "/training/webinar/bulkregister",
    component: () => import("/builder/cms-spa/pages/training/webinar/bulkregister.vue")
  },
  {
    name: "training-webinar-conversion",
    path: "/training/webinar/conversion",
    component: () => import("/builder/cms-spa/pages/training/webinar/conversion.vue")
  },
  {
    name: "training-webinar-featured",
    path: "/training/webinar/featured",
    component: () => import("/builder/cms-spa/pages/training/webinar/featured.vue")
  },
  {
    name: "training-webinar",
    path: "/training/webinar",
    component: () => import("/builder/cms-spa/pages/training/webinar/index.vue")
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => import("/builder/cms-spa/pages/unauthorized.vue")
  },
  {
    name: "upload-test",
    path: "/upload-test",
    component: () => import("/builder/cms-spa/pages/upload-test.vue")
  },
  {
    name: "userpolicy-badwords-id",
    path: "/userpolicy/badwords/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/badwords/[id].vue")
  },
  {
    name: "userpolicy-badwords-add",
    path: "/userpolicy/badwords/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/badwords/add.vue")
  },
  {
    name: "userpolicy-badwords",
    path: "/userpolicy/badwords",
    component: () => import("/builder/cms-spa/pages/userpolicy/badwords/index.vue")
  },
  {
    name: "userpolicy-companies-id",
    path: "/userpolicy/companies/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/companies/[id].vue")
  },
  {
    name: "userpolicy-companies-add",
    path: "/userpolicy/companies/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/companies/add.vue")
  },
  {
    name: "userpolicy-companies",
    path: "/userpolicy/companies",
    component: () => import("/builder/cms-spa/pages/userpolicy/companies/index.vue")
  },
  {
    name: "userpolicy-companyalias-id",
    path: "/userpolicy/companyalias/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/companyalias/[id].vue")
  },
  {
    name: "userpolicy-companyalias-add",
    path: "/userpolicy/companyalias/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/companyalias/add.vue")
  },
  {
    name: "userpolicy-companyalias",
    path: "/userpolicy/companyalias",
    component: () => import("/builder/cms-spa/pages/userpolicy/companyalias/index.vue")
  },
  {
    name: "userpolicy-companyindustry-id",
    path: "/userpolicy/companyindustry/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/companyindustry/[id].vue")
  },
  {
    name: "userpolicy-companyindustry-add",
    path: "/userpolicy/companyindustry/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/companyindustry/add.vue")
  },
  {
    name: "userpolicy-companyindustry",
    path: "/userpolicy/companyindustry",
    component: () => import("/builder/cms-spa/pages/userpolicy/companyindustry/index.vue")
  },
  {
    name: "userpolicy-companytype-id",
    path: "/userpolicy/companytype/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/companytype/[id].vue")
  },
  {
    name: "userpolicy-companytype-add",
    path: "/userpolicy/companytype/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/companytype/add.vue")
  },
  {
    name: "userpolicy-companytype",
    path: "/userpolicy/companytype",
    component: () => import("/builder/cms-spa/pages/userpolicy/companytype/index.vue")
  },
  {
    name: "userpolicy-flaggedusers-id",
    path: "/userpolicy/flaggedusers/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/flaggedusers/[id].vue")
  },
  {
    name: "userpolicy-flaggedusers",
    path: "/userpolicy/flaggedusers",
    component: () => import("/builder/cms-spa/pages/userpolicy/flaggedusers/index.vue")
  },
  {
    name: "userpolicy-ismgregistrations",
    path: "/userpolicy/ismgregistrations",
    component: () => import("/builder/cms-spa/pages/userpolicy/ismgregistrations/index.vue")
  },
  {
    name: "userpolicy-jobfunction-id",
    path: "/userpolicy/jobfunction/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/jobfunction/[id].vue")
  },
  {
    name: "userpolicy-jobfunction-add",
    path: "/userpolicy/jobfunction/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/jobfunction/add.vue")
  },
  {
    name: "userpolicy-jobfunction",
    path: "/userpolicy/jobfunction",
    component: () => import("/builder/cms-spa/pages/userpolicy/jobfunction/index.vue")
  },
  {
    name: "userpolicy-titlelevel-id",
    path: "/userpolicy/titlelevel/:id()",
    component: () => import("/builder/cms-spa/pages/userpolicy/titlelevel/[id].vue")
  },
  {
    name: "userpolicy-titlelevel-add",
    path: "/userpolicy/titlelevel/add",
    component: () => import("/builder/cms-spa/pages/userpolicy/titlelevel/add.vue")
  },
  {
    name: "userpolicy-titlelevel",
    path: "/userpolicy/titlelevel",
    component: () => import("/builder/cms-spa/pages/userpolicy/titlelevel/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/builder/cms-spa/pages/users/[id].vue")
  },
  {
    name: "users-add",
    path: "/users/add",
    component: () => import("/builder/cms-spa/pages/users/add.vue")
  },
  {
    name: "users-admin-id",
    path: "/users/admin/:id()",
    component: () => import("/builder/cms-spa/pages/users/admin/[id].vue")
  },
  {
    name: "users-admin-add",
    path: "/users/admin/add",
    component: () => import("/builder/cms-spa/pages/users/admin/add.vue")
  },
  {
    name: "users-admin",
    path: "/users/admin",
    component: () => import("/builder/cms-spa/pages/users/admin/index.vue")
  },
  {
    name: "users-groups-id",
    path: "/users/groups/:id()",
    component: () => import("/builder/cms-spa/pages/users/groups/[id].vue")
  },
  {
    name: "users-groups-add",
    path: "/users/groups/add",
    component: () => import("/builder/cms-spa/pages/users/groups/add.vue")
  },
  {
    name: "users-groups",
    path: "/users/groups",
    component: () => import("/builder/cms-spa/pages/users/groups/index.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/builder/cms-spa/pages/users/index.vue")
  },
  {
    name: "users-membership-id",
    path: "/users/membership/:id()",
    component: () => import("/builder/cms-spa/pages/users/membership/[id].vue")
  },
  {
    name: "users-membership-add",
    path: "/users/membership/add",
    component: () => import("/builder/cms-spa/pages/users/membership/add.vue")
  },
  {
    name: "users-membership",
    path: "/users/membership",
    component: () => import("/builder/cms-spa/pages/users/membership/index.vue")
  },
  {
    name: "users-permissions-id",
    path: "/users/permissions/:id()",
    component: () => import("/builder/cms-spa/pages/users/permissions/[id].vue")
  },
  {
    name: "users-permissions-add",
    path: "/users/permissions/add",
    component: () => import("/builder/cms-spa/pages/users/permissions/add.vue")
  },
  {
    name: "users-permissions",
    path: "/users/permissions",
    component: () => import("/builder/cms-spa/pages/users/permissions/index.vue")
  },
  {
    name: "users-roles-id",
    path: "/users/roles/:id()",
    component: () => import("/builder/cms-spa/pages/users/roles/[id].vue")
  },
  {
    name: "users-roles-add",
    path: "/users/roles/add",
    component: () => import("/builder/cms-spa/pages/users/roles/add.vue")
  },
  {
    name: "users-roles",
    path: "/users/roles",
    component: () => import("/builder/cms-spa/pages/users/roles/index.vue")
  }
]