import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import ManageReferralsCollection from "~/packages/core/models/emailcenter/ManageReferralsCollection";
import ManageReferralModel from "~/packages/core/models/emailcenter/ManageReferralModel";

class ManageReferralApi extends DefaultApi {
    protected path:string = 'manage_referral'
    protected collection:BaseCollection = ManageReferralsCollection
    protected model:BaseModel = ManageReferralModel

    async searchReferrals(query: string, payload?: {}, cast = true) {
        try {
            let url = `manage_referral/all?fields[]=code&fields[]=id&order_direction=asc&search_fields=code&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new ManageReferralsCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default ManageReferralApi