import { type ModelOptions } from "~/packages/core/models/BaseModel";
import Model from "~/packages/core/models/Model";
import VendorDirectoryCollection from "~/packages/core/models/marketing/VendorDirectoryCollection";

class AuthorModel extends Model {
  static permission_name:string = "author"
  casts() {
    return {
      name: "string",
      name_first: "string",
      name_last: "string",
      title: "string",
      company: "string",
      description: "string",
      email_description: "string",
      email: "string",
      website: "string",
      is_staff: "string",
      is_editor: "string",
      is_vendor: "string",
      vendor_id: "number",
      small_image: "string",
      large_image: "string",
      wide_image: "string",
      twitter: "string",
      linkedin: "string",
      google_plus: "string",
      show_speaker: "number",
      active: "string",
      featured: "string",
      video_tag: "string",
      url: "string",
      image: "string",
      author_id: "number",
      vendor_directory: VendorDirectoryCollection,
    };
  }
  /**** GETTERS ****/

  /**
   * Getter example use case
   */
  // @ts-ignore
  get fullName(): string {
    return this.name_first + " " + this.name_last
  }

  get id() {
    // @ts-ignore
    return this.author_id
  }
  /**** GETTERS END ****/
  /**
   * uncomment this method in the model, if current model has different, key name, than
   * the default ('id')
   */
  //setting vendor id on sponsor form
  set VendorID(vendorID: Number) {
    if (this.vendor_id != vendorID) this.vendor_id = vendorID;
  }

  set IsStaff(isStaff:any){
    this.is_staff = isStaff == 1 || isStaff == "yes" ? "yes" : "no"
  }

  set IsEditor(isEditor:any){
    this.is_editor = isEditor == 1 || isEditor == "yes" ? "yes" : "no"
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: 'author_id'
    }
  }

}

export default AuthorModel;
