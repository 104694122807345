import Model from "~/packages/core/models/Model";
import { type ModelOptions } from "~/packages/core/models/BaseModel";


class RelatedAssetsModel extends Model {
    static permission_name:string = "override_related"
    casts() {
        return {
            type: "string",
            related_id: "number",
            expiration_date: "string",
            date_modified: "string",
            order_id: "uid"
        };
    }
    /**** GETTERS ****/
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'order_id'
        }
    }

    get id() {
        return this.order_id;
    }

    /** Methods **/
     isValidModel (model:this){
        return model.type && model.related_id && model.date_modified;
    };

    assetTypeOptions = [
            {
                optionsText: "Article",
                optionsValue: "article",
            },
            {
                optionsText: "Podcast(Interview)",
                optionsValue: "podcast",
            },
            {
                optionsText: "Handbook",
                optionsValue: "handbook",
            },
            {
                optionsText: "Webinar",
                optionsValue: "webinar",
            },
            {
                optionsText: "Whitepaper",
                optionsValue: "whitepaper",
            },
        ]
}

export default RelatedAssetsModel;
