import Model from "~/packages/core/models/Model";

class EmailArchiveModel extends Model {
  casts(): {} {
    return {
        id: "number",
        md_type_id:"number",
        title:"string",
        subject:"string",
        website:"string",
        author:"string",
        content:"string",
        content_txt:"string",
        date_posted:"string",
        w3c_errors:"number",
        locked:"number",
        litmus_id:"number",
        emailid:"number",
    }
  }
}

export default EmailArchiveModel