import Model from "~/packages/core/models/Model";
import CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";
import AssetModel from "~/packages/core/models/AssetModel";

class PopupSiteModel extends Model {

    casts() {
        return {
            id: "number",
            assets_popups_id: "number",
            site: "string",
            override: "boolean",
            active: "boolean",
            order: "number",
            aorder: "number"
        };
    }

}

export default PopupSiteModel;
