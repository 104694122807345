import PermissionCollection from '~/packages/core/models/PermissionCollection'
import PermissionModel from '~/packages/core/models/PermissionModel'
import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";

class PermissionApi extends DefaultApi {
    protected path:string = 'permissions'
    protected collection:BaseCollection = PermissionCollection
    protected model:BaseModel = PermissionModel

    async list_all( cast = true){
        try{
            const url = `${this.path}/all`
            const { data: response } = await this.makeRequest(url, this.GET)

            return cast ? new PermissionCollection(response) : response
        }
        catch (error) {
            useLog().trace(error)
        }
    }

    async getPermissionActions() {
        try {
            let url = 'permissions/actions'

            const { data: response } = await this.makeRequest(url, this.GET)

            return response
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default PermissionApi
