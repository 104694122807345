import Model from "~/packages/core/models/Model";
import {toString} from "lodash";

class UserStatisticsModel extends Model {
    casts() {
        return {
            nr: "number",
            ws: "string",
            account_name: "string",
            user_state: "string",
            raw_title: "string",
            full_name: "string",
            full_name_separated_by_comma: "string",
            reg_errors_flags: "string",
            active_website: "string",
            company: "string",
            state: "string",
            title_relation: "string"
        }
    }

    /** GETTERS  **/
    get id() {
        // @ts-ignore
        return toString(this.nr) + toString(this.ws)
    }
}

export default UserStatisticsModel