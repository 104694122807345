import Model from "~/packages/core/models/Model";
import {toString} from "lodash";

class AssetCategoriesModel extends Model {
    static override permission_name: string = "asset_views_report_gate";
    override casts() : {} {
        return {
            text:"string",
            startDate:"string",
            endDate:"string",
            catIDS: "array",
            campaignIDS: "array",
            campaignStatus: "string",
        }
    }

    /** GETTERS **/
    get id() {
        return toString(this.text);
    }

    campaignStatusOptions = [
        {id: 'All', label: 'All'},
        {id: 'Inactive', label: 'Inactive'},
        {id: 'Pending', label: 'Pending'},
        {id: 'Current', label: 'Current'},
        {id: 'Problematic', label: 'Problematic'},
        {id: 'Completed', label: 'Completed'},
    ];
    
}

export default AssetCategoriesModel;