import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import JobFunctionModel from "~/packages/core/models/JobFunctionModel";
import JobFunctionsCollection from "~/packages/core/models/JobFunctionsCollection";

class JobFunctionsApi extends DefaultApi {
    protected path:string = 'job_functions'
    protected collection:BaseCollection = JobFunctionsCollection
    protected model:BaseModel = JobFunctionModel
}

export default JobFunctionsApi