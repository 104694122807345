import Model from "~/packages/core/models/Model";

class CompanyAliasModel extends Model {
   static permission_name: string = "company_alias";
  casts(): {} {
    return {
      id: "number",
      reference_company_id: "number",
      alias_name: "string",
      alias_domain: "string",
      stamp: "string",
      source: "string",
    };
  }
}

export default CompanyAliasModel;
