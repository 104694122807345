import Model from "~/packages/core/models/Model";
import { merge } from "lodash";
import TimeModel from "~/packages/core/models/training/TimeModel";

class SlideModel extends Model {
  casts() {
    return merge(super.casts(), {
      id: "uid",
      webinar_id: "number",
      slide_name: "string",
      video: "string",
      content: TimeModel,
      date: "string",
      slides_data: "array",
      slide_groups: "number",
    });
  }
}

export default SlideModel;
