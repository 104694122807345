import Model from "~/packages/core/models/Model";

class SubscriptionMatrixModel extends Model {
    casts(): {} {
        return {
            industry: "object",
            track: "object",
            region: "object",
        };
    }

    get id(): string {
        return 'subscription-matrix';
    }
}

export default SubscriptionMatrixModel;
