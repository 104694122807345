import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CompanyIndustriesModel from "~/packages/core/models/CompanyIndustriesModel";
import CompanyIndustriesCollection from "~/packages/core/models/CompanyIndustriesCollection";

class CompanyIndustriesApi extends DefaultApi {
    protected path:string = 'company_industries'
    protected collection:BaseCollection = CompanyIndustriesCollection
    protected model:BaseModel = CompanyIndustriesModel
}

export default CompanyIndustriesApi