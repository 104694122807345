import Collection from "~/packages/core/models/Collection";
import BannerAdsModel from "~/packages/core/models/emailcenter/bannerads/BannerAdsModel";


class BannerAdsCollection extends Collection {
    //define BannerAdsModel model to return model
    model(): typeof BannerAdsModel {
        return BannerAdsModel
    }

    /**** GETTERS ****/
    get bannerAdAsyncSelectOptions() {
        return this.map((banner_ad: any) => {
            return { value: banner_ad.banner_id, image_file: banner_ad.image_file, id: banner_ad.banner_id }
        });
    }
    get bannerAdSelectOptions() {
        return this.map((banner_ad: any) => {
            return {
                optionsText: banner_ad.image_file,
                optionsValue: banner_ad.banner_id
            }
        });
    }

}

export default BannerAdsCollection