import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CompanyModel from "~/packages/core/models/CompanyModel";
import CompaniesCollection from "~/packages/core/models/CompaniesCollection";

class CompaniesApi extends DefaultApi {
    protected path:string = 'companies'
    protected collection:BaseCollection = CompaniesCollection
    protected model:BaseModel = CompanyModel

    async mergeCompanies(url:any) {
        try{
            const { data:response, error } = await this.makeRequest(`${this.path}/merge?${url}`, this.POST)
            this.handleError(error)
            //@ts-ignore
            return response.value
        }
        catch(error){
            this.handleError(error)
            useLog().trace(error)
        }
    }
}

export default CompaniesApi