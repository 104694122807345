import Model from "~/packages/core/models/Model";

class PreferenceModel extends Model {
  casts(): {} {
    return {
      id: "number",
      name: "string",
      criteria: "array",
      type: "string",
    };
  }

  modelToCriteria(model:any) {
    const criteria = [];
    const excludeKeys = ['id', '_getters_list', 'empty', 'fields', 'name']; // Add keys you want to exclude here

    // Handle fields array
    if (model.fields && Array.isArray(model.fields)) {
      model.fields.forEach((field:any) => {
        criteria.push({
          name: "fields[]",
          id: `field_${field}`,
          type: "checkbox",
          value: field
        });
      });
    }

    // Handle other fields
    for (const [key, value] of Object.entries(model)) {
      if (!excludeKeys.includes(key) && value !== null && value !== undefined && value !== false && value !== '') {
        criteria.push({
          name: key,
          id: key,
          type: "checkbox",
          value: value.toString()
        });
      }
    }

    return criteria;
  }

}
export default PreferenceModel;
