import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import TitleLevelsModel from "~/packages/core/models/TitleLevelsModel";
import TitleLevelsCollection from "~/packages/core/models/TitleLevelsCollection";

class TitleLevelsApi extends DefaultApi {
    protected path:string = 'title_levels'
    protected collection:BaseCollection = TitleLevelsCollection
    protected model:BaseModel = TitleLevelsModel
}

export default TitleLevelsApi