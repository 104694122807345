import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignHistoryCollection from "~/packages/core/models/clientservices/CampaignHistoryCollection";
import CampaignHistoryModel from "~/packages/core/models/clientservices/CampaignHistoryModel";

class CampaignHistoryApi extends DefaultApi {
    protected path:string = 'campaign_history'
    protected collection:BaseCollection = CampaignHistoryCollection
    protected model:BaseModel = CampaignHistoryModel
}

export default CampaignHistoryApi