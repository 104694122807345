import Model from "~/packages/core/models/Model";

class CompanyIndustriesModel extends Model {
  static permission_name = "company_industry"
  casts(): {} {
    return {
        id: "number",
        industry:"string"
    }
  }
}

export default CompanyIndustriesModel