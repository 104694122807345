import BaseModel, { type ModelOptions } from "~/packages/core/models/BaseModel";
import { replace, snakeCase } from "lodash";
import AuthProcess from "~/config/Constants/AuthProcess";

class Model extends BaseModel {
  static permission_name: string = "";
  casts() {
    return {};
  }
  /**** GETTERS ****/

  /**
   * Returns an HTML anchor element with the filename as text and filepath as href
   * currently being used in the TranscodingModel and WebinarConversionModel
   * @returns {string} HTML anchor element or empty string if no files exist
   */
  get fileLink(): string {
    if (!Array.isArray(this.files) || this.files.length === 0) {
      return "";
    }

    const { file, filepath } = this.files[0];
    return `<a class="underline cursor-pointer" href="${filepath}" target="_blank" rel="noopener noreferrer">${file}</a>`;
  }

  /**** Methods****/

  // Permissions actions methods
  // In case there is a model that escapes this pattern, the methods can be overridden
  // in order to use it on models follow this example: ArticleModel.getCreateActionName()
  static getCreateActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.CREATE);
  }

  static getReadActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.READ);
  }

  static getUpdateActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.UPDATE);
  }

  static getDeleteActionName() {
    return this.getActionNameForCurrentModel(AuthProcess.DELETE);
  }

  /**
   * By default the action names for models should be the model name without 'Model' string in it and instead of
   * camel case, it is turned into snake case (!!!also the model name should be at singular form)
   * @param action
   */
  static getActionNameForCurrentModel(action: string = AuthProcess.CREATE) {
    const permission_name = this.permission_name
      ? this.permission_name
      : snakeCase(replace(this.name, "Model", ""));
    return permission_name + "_" + action;
  }
}

// @ts-ignore
export default Model