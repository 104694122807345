import Model from "~/packages/core/models/Model";

class MembershipModel extends Model {
    static permission_name:string = "membership_groups"
    casts() {
        return {
            id: "number",
            membership_id: "string",
            isc2_membership_number: "string",
            website: "string",
            user_id: "number",
            is_attendee: "string",
            type: "string",
            paid_up: "number",
            exp_date: "string",
            start_date: "string",
            subscription_period: "number",
            members_no: "number",
            paid_type: "string",
            paid_method: "string",
            subscription_amount_charged: "string",
            subscription_sales_tax: "string",
            canceled_reason: "string",
            currency: "string",
            status: "number",
            final_exp_date: "string",
            is_valid: "number",
            auto_renewal:"string",
            job_function: "string"
        }
    }

    /** GETTERS  **/

    get WebsiteOptions () {
        return [
            {
                "optionsText": "cybersecuritycontent.com",
                "optionsValue": "K"
            },
        ]
    }
    get TypeOptions () {
        return [
            {
                "optionsText": "Trial",
                "optionsValue": "Trial"
            },
            {
                "optionsText": "Individual (Renewal)",
                "optionsValue": "Individual"
            },
            {
                "optionsText": "Multipass (Renewal)",
                "optionsValue": "Multipass"
            },
            {
                "optionsText": "Corporate (Renewal)",
                "optionsValue": "Corporate"
            },
            {
                "optionsText": "Individual (Non-Renewal)",
                "optionsValue": "Individual"
            },
            {
                "optionsText": "Multipass (Non-Renewal)",
                "optionsValue": "Multipass"
            },
            {
                "optionsText": "Corporate (Non-Renewal)",
                "optionsValue": "Corporate"
            },
        ]
    }

    /** GETTERS **/
}

export default MembershipModel