/*
* useVendorDirectory extends defaultApi composable to use Api state and Common Api Notifications
 */

class useVendorDirectory extends useDefaultApi {
    protected api:string = 'vendor_directory'
    protected excluded:string[] = []

    async searchVendors(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchVendors(query)
    }
}

export default  useVendorDirectory;