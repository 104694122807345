import Model from "~/packages/core/models/Model";

class CustomPopupModel extends Model {
  static permission_name:string = "custom_popup_view"
  casts(): {} {
    return {
      id: "number",
      website: "string",
      user_id: "string",
      content_type: "string",
      content_id: "string",
      page_type: "string",
      stamp: "string",
      asset_type: "string",
    };
  }
}

export default CustomPopupModel;
