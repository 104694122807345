import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class TranscodingModel extends Model {
  static permission_name: string = "transcoding_gate";
  casts(): {} {
    return {
      actions: "array",
      filename: "string",
      files: "array",
    };
  }

  getDefaultOptions(): ModelOptions {
    return {
      identifier: "filename",
    };
  }
}

export default TranscodingModel;