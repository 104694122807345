import Collection from "~/packages/core/models/Collection";
import UserModel from "~/packages/core/models/UserModel";

class UsersCollection extends Collection {
    //define user model to return model
    model(): typeof UserModel {
        return UserModel
    }
}

export default UsersCollection