//package docs : https://driverjs.com/docs
//github repo : https://github.com/kamranahmedse/driver.js
import {driver} from 'driver.js';
import 'driver.js/dist/driver.css';

export default defineNuxtPlugin((nuxtApp) => {
    const { driverStore } = useStoreGlobalState()

    const driverConfig =  driver({
        popoverClass: 'driverjs-theme',
        showProgress: true,
        allowClose: true,
        doneBtnText: 'Done',
        closeBtnText: 'Close',
        nextBtnText: 'Next',
        prevBtnText: 'Previous',
        onDeselected: () => {
            if (!driverConfig.isActive()) {
                //@ts-ignore
                driverStore.driverState[driverConfig.data.tourName] = true
            }
        },
    });

    nuxtApp.provide('driver', {
        startTour: (elements:any, tourName:string) => {
            const steps = elements.map((el:any) => {
                    return {
                        element: el.element,
                        popover: {
                            title: el.title,
                            description: el.description,
                        },
                    }
                });
            driverConfig.setSteps(steps);
            driverConfig.data = { tourName };
            driverConfig.drive();
        },
    });

});
