import Collection from "~/packages/core/models/Collection";
import SubscriptionMatrixModel from "~/packages/core/models/users/SubscriptionMatrixModel";

class SubscriptionMatrixCollection extends Collection {
    //define SubscriptionMatrixModel to return model
    model(): typeof SubscriptionMatrixModel {
        return SubscriptionMatrixModel
    }
}

export default SubscriptionMatrixCollection