/*
* useCompanies extends defaultApi composable to use Api state and Common Api Notifications
 */

import {useGlobalState} from "~/store/useGlobalState";

class useCompanies extends useDefaultApi {
    protected api:string = 'companies'
    protected excluded:string[] = ['company_types','country','state','company_industry']

    async mergeCompanies(companies:any) {
        useGlobalState().isApiLoading = true;
        try {
            const response = await useApi()[this.api].mergeCompanies(companies)
            if(response.value.status === "success") {
                // @ts-ignore
                useNotification().notify('success', response.value.message);
            }
        }catch (error){
            // @ts-ignore
            useNotification().notify('error',"Something went wrong please try again!");
        }
        finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default  useCompanies;