import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import BadWordsCollection from "~/packages/core/models/BadWordsCollection";
import BadWordsModel from "~/packages/core/models/BadWordsModel";

class BadWordsApi extends DefaultApi {
    protected path:string = 'bad_words'
    protected collection:BaseCollection = BadWordsCollection
    protected model:BaseModel = BadWordsModel
}

export default BadWordsApi