import Collection from '~/packages/core/models/Collection';
import FileDetailModel from "~/packages/core/models/FileDetailModel";

class FileDetailsCollection extends Collection {
    /**
     * Define associated model to the FileDetailsCollection
     */
    model(): typeof FileDetailModel {
        return FileDetailModel;
    }

    /*Getters*/
}

export default FileDetailsCollection;