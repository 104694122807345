import Model from "~/packages/core/models/Model";

class JobTypeModel extends Model {
  casts(): {} {
    return {
        id:"number",
        name:"string",
    }
  }
}

export default JobTypeModel;