import DefaultApiNoCrud from "~/packages/core/services/DefaultApiNoCrud";

class EmailMd5HashApi extends DefaultApiNoCrud {
    protected path:string = 'generate_report_emails_md5_hashes'

    async uploadFile(file:any){
        try {
            const { data:response, error } = await this.makeFileRequest(this.path, this.PATCH, file)

            this.handleError(error);
            //@ts-ignore
            return response.value;
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }
}

export default EmailMd5HashApi;