import Model from "~/packages/core/models/Model";

class UserEnewsModel extends Model
{
    override casts() {
        return {
            "id": "number",
            "user_id": "number",
            "type": "number",
            "ismg_vendor": "number",
            "b_enews": "boolean",
            "b_training": "boolean",
            "c_enews": "boolean",
            "c_training": "boolean",
            "a_enews": "boolean",
            "a_training": "boolean",
            "h_enews": "boolean",
            "h_training": "boolean",
            "b_weekend_only": "boolean",
            "c_weekend_only": "boolean",
            "timestamp": "string",
            "d_enews": "boolean",
            "d_training": "boolean",
            "e_enews": "boolean",
            "e_training": "boolean",
            "j_enews": "boolean",
            "j_training": "boolean",
            "f_enews": "boolean",
            "f_training": "boolean",
            "q_enews": "boolean",
            "q_training": "boolean",
            "x_enews": "boolean",
            "x_training": "boolean",
            "z_enews": "boolean",
            "z_training": "boolean",
            "1_enews": "boolean",
            "1_training": "boolean",
            "w_enews": "boolean",
            "w_training": "boolean",
            "p_enews": "boolean",
            "p_training": "boolean",
            "u_enews": "boolean",
            "u_training": "boolean",
            "v_enews": "boolean",
            "v_training": "boolean",
            "y_enews": "boolean",
            "y_training": "boolean",
            "s_enews": "boolean",
            "s_training": "boolean",
            "l_enews": "boolean",
            "l_training": "boolean",
            "2_enews": "boolean",
            "2_training": "boolean",
            "3_enews": "boolean",
            "3_training": "boolean",
            "4_enews": "boolean",
            "4_training": "boolean",
            "5_enews": "boolean",
            "5_training": "boolean",
            "6_enews": "boolean",
            "6_training": "boolean",
            "7_enews": "boolean",
            "7_training": "boolean",
            "acq": "boolean",
            "is_sub": "boolean",
            "sub_unsubscribe": "boolean",
            "sub_unsubscribe_datetime": "string",
            "acq_unsubscribe": "boolean",
            "acq_unsubscribe_datetime": "string",
            "k_enews": "boolean",
            "k_training": "boolean",
            "i_events": "boolean",
            "t_enews": "boolean",
            "t_training": "boolean",
            "g_enews": "boolean",
            "g_training": "boolean",
            "m_enews": "boolean",
            "m_training": "boolean",
            "r_enews": "boolean",
            "r_training": "boolean",
            "created_at": "string",
            "updated_at": "string",
            "0_enews": "boolean",
            "0_training": "boolean",
            "o_enews": "boolean",
            "o_training": "boolean"
        }
    }

    /** GETTERS  **/

    /** GETTERS **/
}

export default UserEnewsModel