import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import UsersStatisticsCollection from "~/packages/core/models/UsersStatisticsCollection";
import UserStatisticsModel from "~/packages/core/models/UserStatisticsModel";

class UsersStatisticsApi extends DefaultApi {
    protected path:string = 'users_statistics'
    protected collection:BaseCollection = UsersStatisticsCollection
    protected model:BaseModel = UserStatisticsModel
 }

export default UsersStatisticsApi