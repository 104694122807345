<!-- BooleanCheckFilter.vue -->
<template>
  <div class="boolean-filter p-5">
    <!-- Identification Section Heading -->
    <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">Filter by Status</h3>

    <!-- Use Custom Checkbox Component -->
    <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      <!-- True Checkbox -->
      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="checked-checkbox-list"
            type="checkbox"
            v-model="checked"
            @change="onCheckboxChange(true)"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label for="checked-checkbox-list" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            <svg class="inline w-6 h-6 text-green-500 ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
            </svg>
          </label>
        </div>
      </li>

      <!-- Unchecked (False) Option -->
      <li class="w-full dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="unchecked-checkbox-list"
            type="checkbox"
            v-model="unchecked"
            @change="onCheckboxChange(false)"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label for="unchecked-checkbox-list" class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            <svg class="inline w-6 h-6 text-red-500 ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
            </svg>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
  export default defineComponent({
    props: {
      params: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        checked: false,
        unchecked: false,
      };
    },
    methods: {
      onCheckboxChange(value: boolean) {
        this.params.filterChangedCallback();
      },
      isFilterActive() {
        return this.checked || this.unchecked;
      },
      getModel() {
        if (!this.isFilterActive()) return null;

        const value = this.checked ? 1 : (this.unchecked ? 0 : null);
        return { filterType: 'number', type: 'equals', filter: value };
      },
      doesFilterPass(params: any) {
        const value = params.data[this.params.colDef.field];
        return (this.checked && value === true) || (this.unchecked && value === false);
      },
      setModel(model: any) {
        this.checked = model?.checked || false;
        this.unchecked = model?.unchecked || false;
      },
    },
  });
</script>
