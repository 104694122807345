import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import EventsCollection from "~/packages/core/models/content/EventsCollection";
import EventModel from "~/packages/core/models/content/EventModel";

class EventsApi extends DefaultApi {
    protected path:string = 'events'
    protected collection:BaseCollection = EventsCollection
    protected model:BaseModel = EventModel
}


export default EventsApi