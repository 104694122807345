export default defineNuxtPlugin(() => {
   const NuxtRouter = useRouter();
   const NuxtRoute = useRoute();

    return {
        provide: {
            NuxtRouter,
            NuxtRoute
        },
    };
});