import Model from "~/packages/core/models/Model";

class PrequalModel extends Model {
    casts() {
        return {
            id: "uid",
            type: "string",
            question: "string",
            choices: "string",
            required: "number",
            other: "number",
        };
    }
    /**** GETTERS ****/

    //qna options
     qnaOptions = [
        {
            optionsText: "Text",
            optionsValue: "text",
        },
        {
            optionsText: "DropDown",
            optionsValue: "dropdown",
        },
        {
            optionsText: "Checkbox",
            optionsValue: "checkbox",
        },
    ];
}

export default PrequalModel;
