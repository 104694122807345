import BaseCollection from '~/packages/core/models/BaseCollection';
import Model from '~/packages/core/models/Model';

class Collection extends BaseCollection {
    /**
     * Define associated model to the ArticleCollection
     */
    model(): typeof Model {
        return Model;
    }

    get assetSelectOptions() {
        return this.map((asset: any) => {
            return { value: asset.title, title: asset.title, id: asset.id }
        });
    }
}

export default Collection;
