import RelatedAssetsModel from '~/packages/core/models/RelatedAssetsModel';
import Collection from '~/packages/core/models/Collection';

class RelatedAssetsCollection extends Collection {
    /**
     * Define associated model to the RelatedAssetsCollection
     */
    model(): typeof RelatedAssetsModel {
        return RelatedAssetsModel;
    }
}

export default RelatedAssetsCollection;
