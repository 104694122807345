class useUserPermissions extends useDefaultApi{
    protected api = 'permission'

    async getPermissionActions(): Promise<void> {
        try {
            return await useApi()[this.api].getPermissionActions()
        }catch (error){
            useLog().trace(error);
        }
    }
}

export default useUserPermissions;