import Model from "~/packages/core/models/Model";

class AssetAdsModel extends Model {
  static permission_name:string = "asset_ad"
  casts() {
    return {
      id: "number",
      asset_type: "string",
      asset_id: "number",
    };
  }

  // asset types
  asset_types = [
    {
      optionsText: "Article",
      optionsValue: "article",
    },
    {
      optionsText: "Blog",
      optionsValue: "blog",
    },
    {
      optionsText: "Blog-Index",
      optionsValue: "blog-index",
    },
    {
      optionsText: "Index",
      optionsValue: "index",
    },
    {
      optionsText: "Interview",
      optionsValue: "podcast",
    },
    {
      optionsText: "Press Release",
      optionsValue: "pr",
    },
    {
      optionsText: "Regulation",
      optionsValue: "regulation",
    },
    {
      optionsText: "Survey",
      optionsValue: "survey",
    },
    {
      optionsText: "White Paper",
      optionsValue: "whitepaper",
    },
    {
      optionsText: "Webinar",
      optionsValue: "webinar",
    },
    {
      optionsText: "Hand Book",
      optionsValue: "handbook",
    },
    {
      optionsText: "Event",
      optionsValue: "event",
    },
  ]
}

export default AssetAdsModel;
