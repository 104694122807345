import Model from "~/packages/core/models/Model";

class TitleLevelsModel extends Model {
  static permission_name:string = "title"
  casts(): {} {
    return {
        id: "number",
        title: "string",
        visible: "number",
    }
  }
}

export default TitleLevelsModel