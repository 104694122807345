import RoleModel from '~/packages/core/models/RoleModel'
import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import RoleCollection from "~/packages/core/models/RoleCollection";

class RoleApi extends DefaultApi {
    protected path:string = 'roles'
    protected collection:BaseCollection = RoleCollection
    protected model:BaseModel = RoleModel

    async createPermissions(role: RoleModel) {
        return await this.handlePermissions(role)
    }

    async updatePermissions(role: RoleModel) {
        return await this.handlePermissions(role, 'update')
    }

    protected async handlePermissions(role: RoleModel, action: string = 'create') {
        // let method = action == 'create' ? this.POST : this.PUT
        let method = this.POST

        try {
            // @ts-ignore
            let url = 'roles/' + role.id + '/permissions'

            const { data: response } = await this.makeRequest(url, method, role.getModelData())

            return response
        } catch (error) {
            useLog().trace(error)
        }
    }

    // async getPermissions(id: any, cast: boolean =  true) {
    //     try {
    //         let url = 'roles/' + id + '/permissions'
    //
    //         const { data: response } = await this.makeRequest(url, this.GET)
    //
    //         return cast ? new RoleModel(response.value) : response
    //     } catch (error) {
    //         useLog().trace(error)
    //     }
    // }
}

export default RoleApi
