import type StaticActionInterface from "~/packages/core/actions/interfaces/StaticActionInterface";

export default class BaseStaticAction implements StaticActionInterface {

    // Instance method implementation
    instance(): void {}

    static execute(...args: any[]) {

    }
}