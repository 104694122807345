import Collection from "~/packages/core/models/Collection";
import InterstitialModel from "~/packages/core/models/marketing/InterstitialModel";

class InterstitialsCollection extends Collection {
    //define InterstitialModel to return model
    model(): typeof InterstitialModel {
        return InterstitialModel
    }

    /**** GETTERS ****/

}

export default InterstitialsCollection