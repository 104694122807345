<template>
  <button
      :class="[
      'py-2.5 px-5 me-2 mb-2 text-sm font-medium focus:outline-none rounded-lg',
      variantClasses[variant],
      isDisabled ? '!bg-gray-500 opacity-50 cursor-not-allowed' : ''
    ]"
      :disabled="isDisabled"
  >
    <slot></slot>
  </button>
</template>

<script setup>
const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: variant => ['primary', 'secondary', 'green', 'red'].includes(variant)
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
});

const variantClasses = {
  primary: 'text-white bg-primary hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:bg-primary dark:hover:bg-blue-700 dark:focus:ring-blue-800',
  secondary: 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700',
  green: 'text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800',
  red: 'text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900'
};

</script>
