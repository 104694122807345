import defaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import BannerAdsCollection from "~/packages/core/models/emailcenter/bannerads/BannerAdsCollection";
import BannerAdsModel from "~/packages/core/models/emailcenter/bannerads/BannerAdsModel";
import {ObjectToQuery} from "~/packages/core/utility/ObjectToQuery";

class BannerAdsApi extends defaultApi {
    protected path:string = 'banner_ads'
    protected collection:BaseCollection = BannerAdsCollection
    protected model:BaseModel = BannerAdsModel

    async searchBannerAds(query: string, payload?: {}, cast = true) {
        try {
            console.log(query)
            const url_query_string = payload ? '?' + ObjectToQuery(payload) : ''
            let url = `${this.path}/all${url_query_string}&fields[]=banner_id&fields[]=image_file&order_by=image_file&order_direction=asc&search_fields=image_file,banner_id&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new BannerAdsCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default BannerAdsApi;
