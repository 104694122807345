import {useGlobalState} from "~/store/useGlobalState";
import Model from "~/packages/core/models/Model";

class PermissionModel extends Model {
    casts() {
        return {
            id: "string",
            title: "string",
            entity: "string",
            action_name: "string",
            description: "string",
            is_active: "boolean",
            created_at: "string",
            updated_at: "string"
        };
    }
    /**** GETTERS ****/

    /**
     * Getter example use case
     */

    get action () {
        // @ts-ignore
        return this.entity + '_' + this.action_name
    }
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + "...";
    //   }
    // }
    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    // getDefaultOptions(): ModelOptions {
    //     return {
    //         identifier: 'post_id'
    //     }
    // }
}

export default PermissionModel;
