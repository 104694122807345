import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import PreferencesCollection from "~/packages/core/models/tools/PreferencesCollection";
import PreferenceModel from "~/packages/core/models/tools/PreferenceModel";

class PreferencesApi extends DefaultApi {
    protected path:string = 'preferences'
    protected collection:BaseCollection = PreferencesCollection
    protected model:BaseModel = PreferenceModel
}


export default PreferencesApi