import Collection from '~/packages/core/models/Collection';
import FeaturedMenuModel from "~/packages/core/models/tools/FeaturedMenuModel";

class FeaturedMenusCollection extends Collection {
    /**
     * Define associated model to the FeaturedMenusCollection
     */
    model(): typeof FeaturedMenuModel {
        return FeaturedMenuModel;
    }

    /** Getters **/
}

export default FeaturedMenusCollection;
