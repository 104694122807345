import PressreleaseModel from '~/packages/core/models/PressreleaseModel';
import Collection from '~/packages/core/models/Collection';

class PressreleasesCollection extends Collection {
  /**
   * Define associated model to the PressreleasesCollection
   */
  model(): typeof PressreleaseModel {
    return PressreleaseModel;
  }

  /*Getters*/
  get selectOptions() {
    return this.map((asset: any) => {
      return {
        value: asset.id,
        title: asset.id + " - " + asset.title,
        id: asset.id,
      };
    });
  }
}

export default PressreleasesCollection;