import Collection from '~/packages/core/models/Collection';
import CampaignModel from "~/packages/core/models/clientservices/CampaignModel";

class CampaignsCollection extends Collection {
    /**
     * Define associated model to the CampaignsCollection
     */
    model(): typeof CampaignModel {
        return CampaignModel;
    }

    /** Getters **/
    get selectOptions() {
        return this.map((campaign: any) => {
            return { value: campaign.campaign_id, cname: campaign.campaign_id + ' - ' + campaign.cname, id: campaign.campaign_id }
        });
    }
}

export default CampaignsCollection;
