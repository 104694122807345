import Model from "~/packages/core/models/Model";

class UserGroupModel extends Model {
    static permission_name: string = "user";
    casts() {
        return {
            id: "number",
            name: "string",
            dateAdd: "string",
            users_count: "number",
            is_admin: "string"
        }
    }

    /** GETTERS **/
    
}

export default UserGroupModel