import Collection from "~/packages/core/models/Collection";
import SlotModel from "~/packages/core/models/training/SlotModel";

class SlotsCollection extends Collection {
    //define SlotModel to return model
    model(): typeof SlotModel {
        return SlotModel
    }

    /**** GETTERS ****/

}

export default SlotsCollection