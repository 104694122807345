<template>
    <div class="website-filter p-5 m-5">
      <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">Filter by Website</h3>
      <Suspense>
        <template #default>
          <!-- Use Custom Select Component -->
          <shared-forms-select
              v-if="sites_list"
              v-model="selectedWebsite"
              label=""
              :options="sites_list?.sitesOptions"
              input-id="reset-form"
              default-option="Select Website"
          />
          <Icon
              name="svg-spinners:12-dots-scale-rotate"
              v-else
              class="mr-2"
              size="20"
          />
        </template>
      </Suspense>

      <!-- Clear Filter Button -->
      <button
          v-if="selectedWebsite"
          class="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          @click="clearFilter"
      >
        Clear Filter
      </button>

    </div>
</template>

<script lang="ts">
  import type SitesCollection from "~/packages/core/models/settings/SitesCollection";

  export default {
    props: {
      params: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        sites_list: null as SitesCollection | null,
        selectedWebsite: null as string | any,
      };
    },

    methods: {
      onWebsiteChange() {
        this.params.filterChangedCallback();
      },

      isFilterActive() {
        return this.selectedWebsite !== null;
      },

      getModel() {
        if (!this.isFilterActive()) return null;
        return { filterType: 'string', type: 'contains', filter: this.selectedWebsite };
      },

      doesFilterPass(params: any) {
        const websiteChar = params.data[this.params.colDef.field];
        return websiteChar === this.selectedWebsite;
      },

      setModel(model: any) {
        this.selectedWebsite = model?.filter || null;
      },

      clearFilter() {
        this.selectedWebsite = null;
        this.params.filterChangedCallback();
      },

      async fetchSites() {
        const sites = new useSites();
        try {
          this.sites_list = await sites.list({ payload: {} });
        } catch (error) {
          useLog().error('Failed to load websites:', error);
        }
      },
    },

    watch: {
      selectedWebsite: {
        handler: 'onWebsiteChange',
      },
    },

    mounted() {
      this.fetchSites();
    },
  }
</script>
