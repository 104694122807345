import Collection from '~/packages/core/models/Collection';
import CampaignHistoryModel from "~/packages/core/models/clientservices/CampaignHistoryModel";

class CampaignHistoryCollection extends Collection {
    /**
     * Define associated model to the CampaignHistoryCollection
     */
    model(): typeof CampaignHistoryModel {
        return CampaignHistoryModel;
    }

    /** Getters **/
}

export default CampaignHistoryCollection;
