import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignsCollection from "~/packages/core/models/clientservices/CampaignsCollection";
import CampaignModel from "~/packages/core/models/clientservices/CampaignModel";

class PopupLevelsApi extends DefaultApi {
    protected path: string = 'campaigns'
    protected collection: BaseCollection = CampaignsCollection
    protected model: BaseModel = CampaignModel
}


export default PopupLevelsApi