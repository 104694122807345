import Model from "~/packages/core/models/Model";
import {toString} from "lodash";

class StatesModel extends Model {
  casts(): {} {
    return {
        code: "string",
        name: "string",
        country_code: "string"
    }
  }

    /**** GETTERS ****/

    // we need this getter in order to make sure that the current model has a unique id as the country is connecting
    // countries with states, don't have a primary key
    get id() {
        // @ts-ignore
        return toString(this.code);
    }
}

export default StatesModel