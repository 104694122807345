import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignsCollection from "~/packages/core/models/clientservices/CampaignsCollection";
import CampaignModel from "~/packages/core/models/clientservices/CampaignModel";

class CampaignsApi extends DefaultApi {
    protected path:string = 'campaigns'
    protected collection:BaseCollection = CampaignsCollection
    protected model:BaseModel = CampaignModel

    //search campaigns
    async searchCampaigns(query: string, payload?: {}, cast = true) {
        try {
            let url = `${this.path}/all?fields[]=campaign_id&fields[]=cname&order_by=cname&order_direction=asc&search_fields=cname,campaign_id&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new this.collection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }

    //generate report
    async generateReport(data:Object){
        try {
            const { data:response, error } = await this.makeFileRequest(this.path + '/report', this.POST, data)

            this.handleError(error);
            //@ts-ignore
            return response.value;
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }

    //generate snapshot report
    async generateSnapshotReport(data:Object){
        try {
            const { data:response, error } = await this.makeFileRequest(this.path + '/snapshot-report', this.POST, data)

            this.handleError(error);
            //@ts-ignore
            return response.value;
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }
}

export default CampaignsApi