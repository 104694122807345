/*
* useWebinarConversion extends defaultApi composable to use Api state and Common Api Notifications
 */

import {useGlobalState} from "~/store/useGlobalState";

class useWebinarConversion extends useDefaultApi {
    protected api:string = 'webinar_conversion_api'
    protected excluded:string[] = []

    /**
     * transcode Master File
     **/
    async transcodeMasterFile(options:{filename:string,webinar_id:number}) {
        useGlobalState().isApiLoading = true;
        try {
            //@ts-ignore
            const entity = await useApi()[this.api].transcodeMasterFile(options);
            if (entity.value.type == "success") {
                // @ts-ignore
                useNotification().notify(
                    "success",
                    entity.value.messages[0],
                );
            }
        } catch (error) {
            //@ts-ignore
            useNotification().notify("error", `${error.message}`);
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }
}

export default  useWebinarConversion;