import BaseCollection from '~/packages/core/models/BaseCollection'
import RoleModel from '~/packages/core/models/RoleModel'

class RoleCollection extends BaseCollection {
    /**
     * Define associated model to the ArticleCollection
     */
    model(): typeof RoleModel {
        return RoleModel;
    }

    get SelectOptions(): {} {
        return this.map((role: RoleModel) => {
            // @ts-ignore
            return { value: role.id, title: role.title, id: role.id}
        });
    }
}

export default RoleCollection;
