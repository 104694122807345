import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import {get} from "lodash";
import LaravelDecrypt from "~/packages/core/actions/cryptography/LaravelDecrypt";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class HasAbility extends BaseStaticAction {
    static execute(ability?: string) {
        const { getAuthorizationPermissions } = useAuth()

        // get encrypted permissions
        let epermissions = getAuthorizationPermissions();

        if(epermissions) {
            // decrypt permissions before checking them
            let permissions = LaravelDecrypt.execute(epermissions, import.meta.env.VITE_API_KEY);
            // @ts-ignore
            if(get(permissions, [ability])) {
                // hide element
                return true
            }
        }

        return false
    }
}