import Collection from "~/packages/core/models/Collection";
import JobCurrencyModel from "~/packages/core/models/jobboard/JobCurrencyModel";

class JobCurrenciesCollection extends Collection {
    /**
     * Define associated model to the JobCurrenciesCollection
     */
    model(): typeof JobCurrencyModel {
        return JobCurrencyModel;
    }

    /**** GETTERS ****/
    // @ts-ignore
    //modifying the object that we get from response to show currencies in select
    get currencySelectOptions(): {} {
        return this.map((currency: JobCurrencyModel) => {
            // @ts-ignore
            return { optionsText: currency.name, optionsValue: currency.id}
        });
    }
}

export default JobCurrenciesCollection;