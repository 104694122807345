import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import BlogsCollection from "~/packages/core/models/content/BlogsCollection";
import BlogsModel from "~/packages/core/models/content/BlogsModel";

class BlogsApi extends DefaultApi {
    protected path:string = 'blogs'
    protected collection:BaseCollection = BlogsCollection
    protected model:BaseModel = BlogsModel

    async searchBlogs(query: string, payload?: {}, cast = true) {
        try {
            let url = `blogs/all?fields[]=blog_id&fields[]=name&order_by=name&order_direction=asc&search_fields=name,blog_id&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new BlogsCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}


export default BlogsApi