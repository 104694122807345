import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import useLog from "~/packages/core/composables/useLog";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class NotifyByAPIResponse extends BaseStaticAction {
    static execute(response?: any, type: string = 'error'): void {
        if(!response) {
            useLog().info([{response}])
            return;
        }

        let messages: string[] = []

        if (response.meta?.response_details?.messages.length) {
            type = response.meta?.response_details?.type || type
            messages = response.meta.response_details.messages
        }

        if (response.messages?.length) {
            type = response.type || type
            messages = response.messages
        }

        this.notify(messages, type)
    }

    private static notify(messages: string[], type: string) {
        messages.forEach((message: string) => {
            useNotification().notify(type, message)
        })
    }
}