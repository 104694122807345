import GroupModel from "~/packages/core/models/GroupModel";
import {merge, replace, snakeCase} from "lodash";
import AuthProcess from "~/config/Constants/AuthProcess";

class FeaturedEventModel extends GroupModel {
    static permission_name = 'event_featured_group';
    casts() {
        return merge(super.casts(), {
            id: "uid",
            active: "string",
            events: "array"
        });
    }
    /**** GETTERS ****/
    /** Methods **/
    async selectOptions (query:any){
     const eventOptions = await  new useAssets().getSearchedAsset('event', query, { fields:['title', 'id'], order_direction:'asc', search_fields:'title,id' });
        return eventOptions?.eventSelectOptions;
}

}

export default FeaturedEventModel;
