import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CaptionsCollection from "~/packages/core/models/tools/CaptionsCollection";
import CaptionModel from "~/packages/core/models/tools/CaptionModel";
import type {Payload} from "~/packages/core/types/Api";

class CaptionsApi extends DefaultApi {
    protected path:string = 'captions'
    protected collection:BaseCollection = CaptionsCollection
    protected model:BaseModel = CaptionModel

    //create entity
    async create(model:BaseModel, payload?:Payload, url?:any, cast:boolean = true){
        try {
            const request_url = this.getRequestUrl(this.path, url, { payload });
            // remove the asset from the request as we don't need it
            delete model.asset
            const { data:response, error } = await this.makeFileRequest(request_url, this.POST, model.getMultipartFormData())

            this.handleError(error);

            const entity_model = this.model
            //@ts-ignore
            return cast ? new entity_model(response.value) : response
        }
        catch(error){
            useLog().trace(error)
        }
    }
}


export default CaptionsApi