import Model from "~/packages/core/models/Model";
class JobFunctionModel extends Model {
  static permission_name:string = "job"
  casts(): {} {
    return {
        id: "number",
        job: "string",
        visible: "number",
    }
  }
}

export default JobFunctionModel