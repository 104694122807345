import Model from "~/packages/core/models/Model";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class TarnscodingStatusModel extends Model {
    static permission_name: string = "transcoding_gate";
    casts(): {} {
        return {
            filename: "string",
            width: "string",
            height: "string",
            status: "string",
            created: "string",
            finished: "string",
            updated: "string"
        }
    }

    getDefaultOptions(): ModelOptions {
        return {
            identifier: "filename",
        };
    }
}

export default TarnscodingStatusModel;