import Collection from "~/packages/core/models/Collection";
import JobModel from "~/packages/core/models/jobboard/JobModel";

class JobsCollection extends Collection {
    /**
     * Define associated model to the JobsCollection
     */
    model(): typeof JobModel {
        return JobModel;
    }
}

export default JobsCollection;
