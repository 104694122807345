import Collection from "~/packages/core/models/Collection";
import UserStatisticsModel from "~/packages/core/models/UserStatisticsModel";

class UsersStatisticsCollection extends Collection {
    //define user model to return model
    model(): typeof UserStatisticsModel {
        return UserStatisticsModel
    }
}

export default UsersStatisticsCollection