import Collection from "~/packages/core/models/Collection";
import CampaignFilterModel from "~/packages/core/models/marketing/CampaignFilterModel";

class CampaignFiltersCollection extends Collection {
    //define CampaignFilterModel to return model
    model(): typeof CampaignFilterModel {
        return CampaignFilterModel
    }

    /**** GETTERS ****/
    get campaignFilterSelectOptions(): {} {
        return this.map((campaign_filter: CampaignFilterModel) => {
            //@ts-ignore
            return { value: campaign_filter.id, name: campaign_filter.name, id: campaign_filter.id }
        });
    }
}

export default CampaignFiltersCollection