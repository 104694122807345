import Model from "~/packages/core/models/Model";
import { toString } from "lodash";
class CategoryPivotModel extends Model {
    casts() {
        return {
            cat_id: "number",
            content_id: "number",
            content_type: "string",
            is_basic: "number"
        };
    }
    /**** GETTERS ****/

    // we need this getter in order to make sure that the current model has a unique id as the pivot table connecting
    // categories with assets, don't have a primary key
    get id() {
        // @ts-ignore
        return toString(this.cat_id) + toString(this.content_id)
    }

    /**
     * Getter example use case
     */
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + "...";
    //   }
    // }
    /**** GETTERS END ****/
}

export default CategoryPivotModel;
