import BlogsModel from '~/packages/core/models/content/BlogsModel';
import Collection from '~/packages/core/models/Collection';

class BlogsCollection extends Collection {
    /**
     * Define associated model to the BlogsCollection
     */
    model(): typeof BlogsModel {
        return BlogsModel;
    }

    /*Getters*/
    get blogsSelectOptions(): {} {
        return this.map((blog: BlogsModel) => {
            //@ts-ignore
            return { value: blog.blog_id, name: blog.name, id: blog.blog_id }
        });
    }
}

export default BlogsCollection;