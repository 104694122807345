import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CategorySponsorsCollection from "~/packages/core/models/content/CategorySponsorsCollection";
import CategorySponsorModel from "~/packages/core/models/content/CategorySponsorModel";
import {ObjectToQuery} from "~/packages/core/utility/ObjectToQuery";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";

class CategorySponsorsApi extends DefaultApi {
    protected path:string = 'category_sponsors'
    protected collection:BaseCollection = CategorySponsorsCollection
    protected model:BaseModel = CategorySponsorModel

    async searchCategories(query: string, payload?: {}, cast = true) {
        try {
            const url_query_string = payload ? ObjectToQuery(payload) : ''
            let url = `categories/all?${url_query_string}&fields[]=cat_id&fields[]=name&order_by=name&order_direction=asc&search_fields=cat_id,name&search=${query}`;
            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new CategoriesCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}


export default CategorySponsorsApi