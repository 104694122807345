import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignAssetModel from "~/packages/core/models/clientservices/CampaignAssetModel";
import CampaignAssetsCollection from "~/packages/core/models/clientservices/CampaignAssetsCollection";

class CampaignAssetsApi extends DefaultApi {
    protected path:string = 'campaign_assets'
    protected collection:BaseCollection = CampaignAssetsCollection
    protected model:BaseModel = CampaignAssetModel
}

export default CampaignAssetsApi