import Model from "~/packages/core/models/Model";
import {merge} from "lodash";

class CountedAssetViewModel extends Model {
    override casts() {
        return merge(super.casts(), {
            content_id: 'number',
            content_type: 'string',
            views: 'string'
        });
    }

    /**** GETTERS END ****/
    override getDefaultOptions(): ModelOptions {
        return {
            identifier: 'content_id'
        }
    }
    /**SETTERS */
}

export default CountedAssetViewModel;
