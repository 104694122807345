import Model from "~/packages/core/models/Model";

class FileDetailModel extends Model {
    casts(): {} {
        return {
            id: "uid",
            file: "any"
        }
    }
}

export default FileDetailModel;