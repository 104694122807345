import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import GetFileNameFromPath from "~/packages/core/actions/utility/GetFileNameFromPath";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class IsDebug extends BaseStaticAction {
    static execute(): boolean {
        const config = useRuntimeConfig();

        return config.public.debug == 'true' ? true : false;
    }
}