/*
* useBloggers extends defaultApi composable to use Api state and Common Api Notifications
 */

class useBloggers extends useDefaultApi {
    protected api:string = 'bloggers'
    protected excluded:string[] = []

    async searchBloggers(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchBloggers(query)
    }
}

export default  useBloggers;