import Model from "~/packages/core/models/Model";
import {merge} from "lodash";

class TimeModel extends Model {
    casts(){
        return merge(super.casts(), {
            times:"object"
        });
    }
}

export default TimeModel;