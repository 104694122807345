import FeaturedWebinarModel from '~/packages/core/models/FeaturedWebinarModel';
import Collection from '~/packages/core/models/Collection';

class FeaturedWebinarsCollection extends Collection {
    /**
     * Define associated model to the FeaturedWebinarsCollection
     */
    model(): typeof FeaturedWebinarModel {
        return FeaturedWebinarModel;
    }

    /** Methods **/
    handleCreateUpdate (group:any) {
        let groupFound = false;
        // @ts-ignore
        for (const existing_group of this) {
            if (existing_group.id === group.id) {
                groupFound = true;
                break;
            }
        }
        return groupFound
    }
}

export default FeaturedWebinarsCollection;
