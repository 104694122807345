import Model from "~/packages/core/models/Model";
import {useGlobalState} from "~/store/useGlobalState";
import {type Payload} from "~/packages/core/types/Api";
import {type Groups} from "~/packages/core/interfaces/global";

//@ts-ignore
class GroupModel extends Model implements Groups {
    static permission_name:string = ''
    casts() {
        return {

        };
    }
    /**** GETTERS ****/
    /** Methods **/
 async list (type:string,payload?:Payload) {
    return await useApi().groupsApi.list(type,payload);
}

async create (type:string, model:any, relation:string) {
    useGlobalState().isApiLoading = true;
    try{
        const group = await useApi().groupsApi.create(type,model);
        if(group) {
            //@ts-ignore
            if (group.metadata.status === "200") {
                // @ts-ignore
                useNotification().notify('success', group.metadata.response_details.messages[0]);
            }
            // @ts-ignore
            model.id = group.id
            if (model) {
                await useApi().groupsApi.handleRelation(type, model, relation)
            }
        }
    }
    catch(error){
        // @ts-ignore
        useNotification().notify('error',"Something went wrong please try again!");
    } finally {
        useGlobalState().isApiLoading = false;
    }
}

    async update (type:string, model:any, relation:string) {
        useGlobalState().isApiLoading = true;
        try{
            const group = await useApi().groupsApi.update(type,model);
            if(group) {
                //@ts-ignore
                if (group.metadata.status === "200") {
                    // @ts-ignore
                    useNotification().notify('success', group.metadata.response_details.messages[0]);
                }
                if (model) {
                    await useApi().groupsApi.handleRelation(type, model, relation)
                }
            }
        }
        catch(error){
            // @ts-ignore
            useNotification().notify('error',"Something went wrong please try again!");
        } finally {
            useGlobalState().isApiLoading = false;
        }
    }

    async deleteGroup  (type:string, model:any){
        useGlobalState().isApiLoading = true;
    try{
        const group = await useApi().groupsApi.delete(type, model);
        if(group.meta.status === "200"){
            // @ts-ignore
            useNotification().notify('success',group.meta.response_details.messages[0]);
        }
        location.reload()
    }
    catch(error){
        // @ts-ignore
        useNotification().notify('error',"Something went wrong please try again!");
    }
    finally {
        useGlobalState().isApiLoading = false
    }
}

}

export default GroupModel;
