class Models {
  readonly ARTICLE: string = "ArticleModel";
  readonly INTERVIEW: string = "InterviewModel";
  readonly WHITEPAPER: string = "WhitepaperModel";
  readonly HANDBOOK: string = "HandbookModel";
  readonly EVENT: string = "EventModel";
  readonly BLOGPOST: string = "BlogPostModel";
}

export default new Models();
