import Model from "~/packages/core/models/Model";

class JobDurationModel extends Model {
  casts(): {} {
    return {
      id: "number",
      name: "string",
      price: "number",
      value: "number",
    };
  }
}

export default JobDurationModel;