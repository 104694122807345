import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import InterstitialsCollection from "~/packages/core/models/marketing/InterstitialsCollection";
import InterstitialModel from "~/packages/core/models/marketing/InterstitialModel";

class InterstitialsApi extends DefaultApi {
  protected path:string = 'interstitials'
  protected collection:BaseCollection = InterstitialsCollection
  protected model:BaseModel = InterstitialModel
}


export default InterstitialsApi