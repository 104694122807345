import GroupModel from "~/packages/core/models/GroupModel";
import {merge} from "lodash";
import {Groups} from "~/packages/core/interfaces/global";

class FeaturedWebinarModel extends GroupModel implements Groups {
    static permission_name:string = "webinar_featured"
    casts() {
        return merge(super.casts(), {
            id: "uid",
            active: "string",
            webinars_featured: "array"
        });
    }
    /**** GETTERS ****/
    /** Methods **/
    //TODO: Bind webinar options once webinar asset is up
    async selectOptions (query:any){
     const eventOptions = await new useAssets().getSearchedAsset('event', query, { fields:['title', 'id'], order_direction:'asc', search_fields:'title,id' });
        return eventOptions?.eventSelectOptions;
}
}

export default FeaturedWebinarModel;
