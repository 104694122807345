import Collection from '~/packages/core/models/Collection';
import CountryModel from "~/packages/core/models/CountryModel";

class CountriesCollection extends Collection {
    /**
     * Define associated model to the CountriesCollection
     */
    model(): typeof CountryModel {
        return CountryModel;
    }

    /*Getters*/
    get SelectOptions(): {} {
        return this.map((country: CountryModel) => {
            // @ts-ignore
            return { value: country.code, name: country.name }
        });
    }
}

export default CountriesCollection;