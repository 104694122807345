import DefaultApiNoCrud from "~/packages/core/services/DefaultApiNoCrud";
import type Model from "~/packages/core/models/Model";

class ServerUploadApi extends DefaultApiNoCrud{
    protected path:string = "server-upload";

    async uploadFile(model:Model){
        try {
            const { data:response, error } = await this.makeFileRequest(this.path, this.POST, model.getMultipartFormData())

            this.handleError(error);
            //@ts-ignore
            return response.value;
            // const entity_model = this.model
            // //@ts-ignore
            // return cast ? new entity_model(response.value) : response
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }
}

export default ServerUploadApi