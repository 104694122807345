import Collection from "~/packages/core/models/Collection";
import WebinarConversionModel from "~/packages/core/models/training/WebinarConversionModel";

class WebinarConversionCollection extends Collection {
    /**
     * Define associated model to the WebinarConversionCollection
     */
    model(): typeof WebinarConversionModel {
        return WebinarConversionModel;
    }
}

export default WebinarConversionCollection;