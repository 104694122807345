import { useGlobalState } from "~/store/useGlobalState";
import { useDriverStore } from "~/store/DriverStore";

const useStoreGlobalState = () => {
    return {
      globalState : useGlobalState(),
      driverStore : useDriverStore()
    }
}

export default useStoreGlobalState