import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import TranscodingCollection from "~/packages/core/models/tools/TranscodingCollection";
import TranscodingModel from "~/packages/core/models/tools/TranscodingModel";
import type {Payload} from "~/packages/core/types/Api";
import TranscodingStatusCollection from "~/packages/core/models/tools/TranscodingStatusCollection";

class TranscodingApi extends DefaultApi {
    protected path:string = 'transcoding'
    protected collection:BaseCollection = TranscodingCollection
    protected model:BaseModel = TranscodingModel

    //method just returns status response array as is for grid
    async statusList(payload?:Payload, url?:any, cast:boolean = true){
        const response = await super.list(payload, url, false);
        return cast ? new TranscodingStatusCollection(response.value) : response.value;
    }

    //transcode file
    async transcodeMasterFile(options:{filename:string}){
        try{
            const request_url = this.path + '/transcode';
            const { data:response } = await this.makeRequest(request_url, this.POST, options)
            //@ts-ignore
            return response
        }
        catch(error){
            useLog().trace(error)
        }
    }


    //delete transcoding
    async deleteTranscoding(options:{filename:string, container:string}){
        try{
            const request_url = this.path + '/delete';
            const { data:response } = await this.makeRequest(request_url, this.POST, options)
            //@ts-ignore
            return response
        }
        catch(error){
            useLog().trace(error)
        }
    }

    //rename transcoding
    async renameTranscoding(options:{filename:string, container:string, new_name:string}){
        try{
            const request_url = this.path + '/rename';
            const { data:response } = await this.makeRequest(request_url, this.POST, options)
            //@ts-ignore
            return response
        }
        catch(error){
            useLog().trace(error)
        }
    }
}


export default TranscodingApi