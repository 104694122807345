import Model from "~/packages/core/models/Model";

class JobCurrencyModel extends Model {
  casts(): {} {
    return {
      id: "number",
      name: "string",
      one_usd_rate: "number",
    };
  }
}

export default JobCurrencyModel;
