import { type ModelOptions } from "~/packages/core/models/BaseModel";
import { merge } from "lodash";
import AuthProcess from "~/config/Constants/AuthProcess";
import ArticleModel from "~/packages/core/models/ArticleModel";
import CategoriesCollection from "~/packages/core/models/CategoriesCollection";
import RelatedAssetsCollection from "~/packages/core/models/RelatedAssetsCollection";

class InterviewModel extends ArticleModel {
    /**
     * While interviews have a different type convention (instead of 'interview' as asset type, it must be 'podcast')
     * we need to force the right type
     */
    static permission_name: string = 'podcast';
    static asset_model_type = 'podcast'
    static fields: string[] = ['title', 'podcast_id']
    static search_fields: string = "title,podcast_id"
    casts() {
        return merge(super.casts(), ({
            podcast_id: "number",
            exerpt: "string",
            transcript_id: "number",
            swf_file: "string",
            streaming_link: "string",
            direct_link: "string",
            image: "string",
            homepage_image: "string",
            image_large: "string",
            image_caption: "string",
            on_home_page: "number",
            running_time: "string",
            price: "string",
            views: "number",
            html_page_name: "string",
            stamp: "string",
            popup_details: "object",
            canonical_url: "string",
            title_original: "string",
            cloned_hierarchy: "string",
            lang: "string",
            asset_image: "string",
            url: "string",
            email_content: "string",
            categories: CategoriesCollection,
            related: RelatedAssetsCollection,
        }));
    }
    /**** GETTERS ****/

    /**
     * Getter example use case
     */
    // @ts-ignore
    // get exerpt(): string {
    //   if (this.description) {
    //     return this.description.substring(0, 30) + "...";
    //   }
    // }
    get id(){
        return this.podcast_id
    }
    /**** GETTERS END ****/
    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
        return {
            identifier: 'podcast_id'
        }
    }


    /**
     * We override this method because interviews don't fit in the general identification convention ('podcast' instead of 'interview')
     *
     * @param action
     */
    static getActionNameForCurrentModel(action: string = AuthProcess.CREATE) {
        return this.asset_model_type + '_' + action
    }

    /**SETTERS */

    //email content
    set InterviewEmailContent(email_content: string) {
        this.email_content = email_content === "" ? this.description : email_content;
    }
}

export default InterviewModel;
