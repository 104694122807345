import Model from "~/packages/core/models/Model";

class BaseContentModel extends Model {
  casts(): {} {
    return {
      version: "string",
      top_link_title_1: "string",
      name: "string",
      sender_name: "string",
      sender_type: "string",
      hyperlink_text: "string",
      subject: "string",
      referral_utype: "string",
      referral_custom: "string",
      referral_segment_code: "string",
      footer_id: "string",
      item1_type: "string",
      item1_id: "string",
      item1_web: "string",
      item1_linktp: "string",
      item2_type: "string",
      item2_id: "string",
      item2_web: "string",
      item2_linktp: "string",
      item3_type: "string",
      item3_id: "string",
      item3_web: "string",
      item3_linktp: "string",
    };
  }

  assetTypesMapping = {
    AR: "article",
    BL: "blogpost",
    PD: "interview",
    PR: "pressrelease",
    WP: "whitepaper",
    WB: "webinar",
    HB: "handbook",
  };

  getNormalizedAssetType(type: string) {
    if (!type) return null;
    return this.assetTypesMapping[type as keyof typeof this.assetTypesMapping];
  }

  extractAsset = (type: string) => {
    return async (query: string) => {
      const asset_model = useAssetModel(type).model;
      const response = await new useAssets().getSearchedAsset(type, query, {
        fields: asset_model.fields,
        order_direction: "asc",
        search_fields: asset_model.search_fields,
      });
      return response?.selectOptions;
    };
  };

  fetchExistingAssetSelectOptions = async (
    assetSelect: any,
    type: string,
    id: string
  ) => {
    const assetType = this.getNormalizedAssetType(type);
    const asset_model = useAssetModel(assetType).model;
    const asset = await new useAssets().get(
      id,
      { payload: { fields: asset_model.fields } },
      `/${assetType}`
    );
    assetSelect.assetsOptions?.push({
      title: asset.asset_id + " - " + asset.title,
      id: asset.asset_id,
    });
  };
}

export default BaseContentModel