import Model from "~/packages/core/models/Model";

class PopupSettingsModel extends Model {
    casts() {
        return {
            id: "string",
            site: "string",
            websites: "string",
            override_show_each_session: "number",
            override_display_interval: "number"
        };
    }
}

export default PopupSettingsModel;
