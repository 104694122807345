import Model from "~/packages/core/models/Model";

class BadWordsModel extends Model {
    static permission_name:string = "bad_word"
  casts(): {} {
    return {
        id: "number",
        name:"string",
        date_modified:"string"
    }
  }
}

export default BadWordsModel