import Collection from "~/packages/core/models/Collection";
import CompanyAliasModel from "~/packages/core/models/userpolicy/CompanyAliasModel";

class CompanyAliasesCollection extends Collection {
    //define CompanyAliasModel to return model
    model(): typeof CompanyAliasModel {
        return CompanyAliasModel
    }
}

export default CompanyAliasesCollection