import Collection from "~/packages/core/models/Collection";
import EmailArchiveModel from "~/packages/core/models/EmailArchiveModel";

class EmailArchiveCollection extends Collection {
    //define EmailArchiveModel to return model
    model(): typeof EmailArchiveModel {
        return EmailArchiveModel
    }

    /**** GETTERS ****/
}

export default EmailArchiveCollection