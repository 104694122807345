import Collection from '~/packages/core/models/Collection';
import AdvisorsBoardModel from "~/packages/core/models/clientservices/AdvisorsBoardModel";

class AdvisorsBoardCollection extends Collection {
    /**
     * Define associated model to the AdvisorsBoardCollection
     */
    model(): typeof AdvisorsBoardModel {
        return AdvisorsBoardModel;
    }

    /** Getters **/
}

export default AdvisorsBoardCollection;
