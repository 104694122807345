import { defineStore } from 'pinia';
import type { menuDataConfig, tabsDataConfig, AssetConfig, EmailTemplateState } from "~/packages/core/interfaces/global"
import gridData from '~/config/common/grid.json';
import GetMenuData from "~/packages/core/actions/config/GetMenuData";

export const useGlobalState = defineStore('globalState', {
  state: () => {
    return {
      // Main menu state
      isMenuOpen: false as Boolean,
      //Menu Data
      Menus: GetMenuData.execute() as menuDataConfig[],

      emailTemplate: {
        selectedType: null
      } as EmailTemplateState,

      //API Loading State
      isApiLoading: false as any,

      // column definations for data grid
      ArticleDefinations: gridData.assets as AssetConfig,
      RolesDefinitions: gridData.role,
    };
  },
  actions: {
  },
});



