/*
* useEmailTemplate extends defaultApi composable to use Api state and Common Api Notifications
 */

import BaseModel from "~/packages/core/models/BaseModel";
import {type Payload} from "~/packages/core/types/Api";
import {useGlobalState} from "~/store/useGlobalState";
import GoBack from "~/packages/core/actions/utility/GoBack";

class useEmailTemplate extends useDefaultApi {
  protected api: string = "email_templates";
  protected excluded: string[] = [
    "AssetOptions",
    "FooterIdOptions",
    "content_object",
    "directLinkOptions",
    "featuredItemOptions",
    "referralOptions",
    "referral_segment",
    "sidebarOptions",
    "templateTypes",
    "type_data",
    "webinar_timezone_options",
  ];
}

export default  useEmailTemplate;