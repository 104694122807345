import type BaseModel from "~/packages/core/models/BaseModel";
import NotifyByAPIResponse from "~/packages/core/actions/utility/NotifyByAPIResponse";
import type PopupSettingsModel from "~/packages/core/models/marketing/PopupSettingsModel";
/*
* usePopups extends defaultApi composable to use Api state and Common Api Notifications
 */

class usePopups extends useDefaultApi {
    protected api:string = 'popups'
    protected excluded:string[] = []

    /**
     * create will create a single entity by taking the required data as model
     **/
    async setStatus(model: BaseModel, url?:string) {
        await this.handleRequest( async () => {
            return await useApi()[this.api].setStatus(model.getModelData(this.excluded), url)
        })
    }

    /**
     * create will create a single entity by taking the required data as model
     **/
    async reorder(reordered_ids:string, url?:string) {
        useNotification().notify('info', 'Reordering process has started! Please wait...', {autoClose: 10000})
        await this.handleRequest( async () => {
            return await useApi()[this.api].reorder(reordered_ids, url);
        })
    }

    /**
     * get popups settings
     **/
    async getSettings() {
        return await this.processApiResponse( async () => {
            return await useApi()[this.api].getSettings();
        })
    }

    /**
     * get popups settings
     **/
    async setSettings(model:PopupSettingsModel) {
        await this.handleRequest( async () => {
            return await useApi()[this.api].setSettings(model)
        })
    }

    async handleRequest(fn:any) {
        const response = await this.processApiResponse(async () => {
            return await fn()
        })

        NotifyByAPIResponse.execute(response);
    }
}

export default  usePopups;