import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import BloggersCollection from "~/packages/core/models/content/BloggersCollection";
import BloggerModel from "~/packages/core/models/content/BloggerModel";

class BloggersApi extends DefaultApi {
    protected path:string = 'bloggers'
    protected collection:BaseCollection = BloggersCollection
    protected model:BaseModel = BloggerModel

    async searchBloggers(query: string, payload?: {}, cast = true) {
        try {
            let url = `bloggers/all?fields[]=blogger_id&fields[]=name&order_by=name&order_direction=asc&search_fields=name,blogger_id&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new BloggersCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}


export default BloggersApi