import Model from "~/packages/core/models/Model";
import CampaignFilterCriteriaModel from "~/packages/core/models/clientservices/CampaignFilterCriteriaModel";
import FileDetailsCollection from "~/packages/core/models/FileDetailsCollection";

class CampaignFilterModel extends Model {
  casts() {
    return {
      id: "number",
      name: "string",
      criteria: CampaignFilterCriteriaModel,
      file_details: FileDetailsCollection,
      excluded_leads_last_filename: "string",
      excluded_leads_last_filename_timestamp: "string",
      companies_last_filename: "string",
      companies_last_filename_timestamp: "string",
      field_name: "string"
    };
  }

  getMultipartFormData() {
    const formData = new FormData();

    // Add all model fields to formData
    Object.keys(this.getModelData()).forEach((key) => {
      // @ts-ignore
      let value = this[key];

      // @ts-ignore
      if ((Array.isArray(value) || typeof value === 'object') && !(this[key] instanceof File)) {
        value = JSON.stringify(value);
      }
    });

    if (this.file_details && this.file_details.length > 0) {
      this.file_details.forEach((fileDetail:any) => {
        formData.append(`file_details[field_name]`, this.field_name);
        if (fileDetail.file instanceof File) {
          formData.append(`file_details[file]`, fileDetail.file, fileDetail.file.name);
        }
      });
    }

    return formData;
  }

  report_options = [
    { value: "snapshot-report", label: "Generate Snapshot Report (current campaigns only)" },
    { value: "overall-report", label: "Generate Overall Report" }
  ]
}

export default CampaignFilterModel;
