//@ts-ignore
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  return useHead({
    title: 'ISMG | CMS',
    meta: [{ name: 'description', content: 'ISMG CMS' }],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
      },
      // {
      //   rel: 'stylesheet',
      //   type: 'text/css',
      //   href: 'https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.6.5/flowbite.min.css',
      // },
    ],
    script: [
      // {
      //   src: `${config.app.baseURL}/js/vendor/flowbite.js`,
      // },
      // {
      //   src: 'https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.6.5/flowbite.min.js',
      //   tagPosition: 'bodyClose'
      // },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.6.5/datepicker.min.js',
        tagPosition: 'bodyClose'
      },
    ],
  });
});
