import revive_payload_client_4sVQNw7RlN from "/builder/cms-spa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builder/cms-spa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builder/cms-spa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builder/cms-spa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builder/cms-spa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builder/cms-spa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builder/cms-spa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builder/cms-spa/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builder/cms-spa/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builder/cms-spa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/builder/cms-spa/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builder/cms-spa/.nuxt/sentry-client-config.mjs";
import plugin_client_i8AMfKeYnY from "/builder/cms-spa/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_1UohGbtF8v from "/builder/cms-spa/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import canDirective_zjMP8m4qoN from "/builder/cms-spa/packages/core/plugins/canDirective.ts";
import popUpDirective_MKkV7uhcei from "/builder/cms-spa/packages/core/plugins/popUpDirective.ts";
import AgGrid_UgoraNZ4i9 from "/builder/cms-spa/packages/core/plugins/AgGrid.ts";
import Head_sDBVlIVVKH from "/builder/cms-spa/packages/core/plugins/Head.ts";
import Multiselect_OOS5wTgr0v from "/builder/cms-spa/packages/core/plugins/Multiselect.ts";
import Texteditor_GahYDVYco3 from "/builder/cms-spa/packages/core/plugins/Texteditor.ts";
import Toast_VsiQJ2BaMv from "/builder/cms-spa/packages/core/plugins/Toast.ts";
import UppyDash_IcOT6Qzu7e from "/builder/cms-spa/packages/core/plugins/UppyDash.ts";
import DatePicker_QbZs27Vo5P from "/builder/cms-spa/packages/core/plugins/DatePicker.ts";
import driver_IB2KGlkV4E from "/builder/cms-spa/packages/core/plugins/driver.ts";
import NuxtAppRouter_MuDj4a6rQm from "/builder/cms-spa/packages/core/plugins/NuxtAppRouter.ts";
import clarity_CMPfznvF46 from "/builder/cms-spa/packages/core/plugins/clarity.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_i8AMfKeYnY,
  plugin_1UohGbtF8v,
  canDirective_zjMP8m4qoN,
  popUpDirective_MKkV7uhcei,
  AgGrid_UgoraNZ4i9,
  Head_sDBVlIVVKH,
  Multiselect_OOS5wTgr0v,
  Texteditor_GahYDVYco3,
  Toast_VsiQJ2BaMv,
  UppyDash_IcOT6Qzu7e,
  DatePicker_QbZs27Vo5P,
  driver_IB2KGlkV4E,
  NuxtAppRouter_MuDj4a6rQm,
  clarity_CMPfznvF46
]