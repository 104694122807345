/*
* useManageReferral extends defaultApi composable to use Api state and Common Api Notifications
 */

class useManageReferral extends useDefaultApi {
    protected api:string = 'manage_referral'
    protected excluded:string[] = []

    async searchReferrals(query: string) {
        //@ts-ignore
        return await useApi()[this.api].searchReferrals(query)
    }
}

export default  useManageReferral;