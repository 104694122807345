import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";

/**
 * This class is a single action class that Base64 encodes any provided input property.
 * If the input is not a string, it converts it to a JSON string before encoding.
 */
export default class ValueToBase64 extends BaseStaticAction
{
    static override execute(input: any): string {
        // Convert non-string inputs to JSON strings for Base64 encoding
        const data = typeof input === "string" ? input : JSON.stringify(input);

        try {
            return btoa(data); // Encode the string in Base64
        } catch (error) {
            throw new Error("Failed to Base64 encode the input property.");
        }
    }
}
