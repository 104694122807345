import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import WebinarConversionCollection from "~/packages/core/models/training/WebinarConversionCollection";
import WebinarConversionModel from "~/packages/core/models/training/WebinarConversionModel";

class WebinarConversionApi extends DefaultApi {
    protected path:string = 'webinar_conversion'
    protected collection:BaseCollection = WebinarConversionCollection
    protected model:BaseModel = WebinarConversionModel

    //transcode file
    async transcodeMasterFile(options:{filename:string, webinar_id:number}){
        try{
            const request_url = this.path + '/transcode';
            const { data:response } = await this.makeRequest(request_url, this.POST, options)
            //@ts-ignore
            return response
        }
        catch(error){
            useLog().trace(error)
        }
    }
}


export default WebinarConversionApi