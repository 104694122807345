import type CasterInterface from "~/packages/core/models/casters/CasterInterface";

export default class CasterBase implements CasterInterface {
    protected data:any

    constructor(data:any) {
        this.data = data

        return this.cast()
    }
    cast():any {}
}