import Collection from "~/packages/core/models/Collection";
import EmailTemplateModel from "~/packages/core/models/emailcenter/EmailTemplateModel";


class EmailTemplatesCollection extends Collection {
    //define EmailTemplateModel model to return model
    model(): typeof EmailTemplateModel {
        return EmailTemplateModel
    }
}

export default EmailTemplatesCollection