import Collection from '~/packages/core/models/Collection';
import AthenaAssetKeywordsModel from "~/packages/core/models/clientservices/AthenaAssetKeywordsModel";

class AthenaAssetKeywordsCollection extends Collection {
  /**
   * Define associated model to the AthenaAssetKeywordsCollection
   */
  model(): typeof AthenaAssetKeywordsModel {
    return AthenaAssetKeywordsModel;
  }
  
  /** Getters **/
  get selectOptions() {
    return this.map((campaign: any) => {
      return {...campaign, value: campaign.id, campaign_name: campaign.campaign_name }
    });
  }
}

export default AthenaAssetKeywordsCollection;
