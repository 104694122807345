import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import MembershipsCollection from "~/packages/core/models/MembershipsCollection";
import MembershipModel from "~/packages/core/models/MembershipModel";

class MembershipsApi extends DefaultApi {
    protected path:string = 'memberships'
    protected collection:BaseCollection = MembershipsCollection
    protected model:BaseModel = MembershipModel
}

export default MembershipsApi