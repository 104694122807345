import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import FlaggedUsersCollection from "~/packages/core/models/userpolicy/FlaggedUsersCollection";
import FlaggedUserModel from "~/packages/core/models/userpolicy/FlaggedUserModel";

class FlaggedUsersApi extends DefaultApi {
    protected path:string = 'flagged_users'
    protected collection:BaseCollection = FlaggedUsersCollection
    protected model:BaseModel = FlaggedUserModel
}

export default FlaggedUsersApi