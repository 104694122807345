import Model from "~/packages/core/models/Model";

class CompanyTypeModel extends Model {
  casts(): {} {
    return {
        id: "number",
        type:"string"
    }
  }
}

export default CompanyTypeModel