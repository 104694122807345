import Collection from "~/packages/core/models/Collection";
import CompanyTypeModel from "~/packages/core/models/CompanyTypeModel";

class CompanyTypesCollection extends Collection {
    //define Company_types model to return model
    model(): typeof CompanyTypeModel {
        return CompanyTypeModel
    }

    /**** GETTERS ****/
    //modifying the object that we get from response to show company_types list in Multiselect
    get SelectOptions(): {} {
        return this.map((company_type: CompanyTypeModel) => {
            // @ts-ignore
            return { value: company_type.id, title: company_type.type }
        });
    }
}

export default CompanyTypesCollection