import BaseModel from "~/packages/core/models/BaseModel";
import ImportModel from "~/packages/core/models/tools/ImportModel";
import DefaultApiNoCrud from "~/packages/core/services/DefaultApiNoCrud";
import DownloadBlob from "~/packages/core/actions/utility/DownloadBlob";

class ImportApi extends DefaultApiNoCrud {
    protected path:string = 'import-contact-companies'
    // @ts-ignore
    protected model:BaseModel = ImportModel

    async getFields() {
        try{
            const request_url = this.path + '/fields';
            const {data:response} = await this.makeRequest(request_url, this.GET);
            //@ts-ignore
            return response.value.data
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }

    async importCheck(model:ImportModel){
        try {
            const request_url = this.path + '/check';

            const response = await this.makeRequest(request_url, this.POST, {
                post_data: model.getModelData(),
                responseType: 'blob'
            })

            this.handleError(response.error);

            // Extract the Blob from the response
            const blob = response.data.value; // Assuming response.value contains the Blob object

            // Define the desired filename
            const filename = 'report_for_' + model.uploaded_file + '.zip';

            // Create a URL for the Blob
            DownloadBlob.execute(blob, filename);

            return response;

            // const entity_model = this.model
            // //@ts-ignore
            // return cast ? new entity_model(response.value) : response
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }

    async importStart(model:ImportModel){
        try {
            const request_url = this.path + '/import';

            const { data:response, error } = await this.makeRequest(request_url, this.POST, model.getModelData())

            this.handleError(error);

            return response.value;
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }

    async uploadFile(model:ImportModel){
        try {
            const request_url = this.path + '/upload-file';

            const { data:response, error } = await this.makeFileRequest(request_url, this.POST, model.getMultipartFormData())

            this.handleError(error);
            //@ts-ignore
            return response.value;
            // const entity_model = this.model
            // //@ts-ignore
            // return cast ? new entity_model(response.value) : response
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }

    async isImportRunning() {
        try {
            const request_url = this.path + '/is-import-running';

            const { data:response, error } = await this.makeFileRequest(request_url, this.GET)

            this.handleError(error);
            //@ts-ignore
            return response.value;
            // const entity_model = this.model
            // //@ts-ignore
            // return cast ? new entity_model(response.value) : response
        }
        catch(error){
            this.handleError(error);
            useLog().trace(error)
        }
    }
}


export default ImportApi