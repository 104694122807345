import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AssetAdsCollection from "~/packages/core/models/marketing/AssetAdsCollection";
import AssetAdsModel from "~/packages/core/models/marketing/AssetAdsModel";

class AssetAdsApi extends DefaultApi {
  protected path:string = 'asset_ads'
  protected collection:BaseCollection = AssetAdsCollection
  protected model:BaseModel = AssetAdsModel
}


export default AssetAdsApi