import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import AssetCollection from "~/packages/core/models/AssetCollection";
import AssetModel from "~/packages/core/models/AssetModel";
import { ObjectToQuery } from "~/packages/core/utility/ObjectToQuery";
import { type Payload } from "~/packages/core/types/Api";

class AssetsApi extends DefaultApi {
    protected path:string = 'assets'
    protected collection:BaseCollection = AssetCollection
    protected model:BaseModel = AssetModel

    async searchAssets(type: string, query: string, payload?:Payload, cast = true) {
        try {
            const url_query_string = payload ? '?' + ObjectToQuery(payload) : ''
            let url = `${this.path}/${type}/all${url_query_string}&search=${query}`;

            const { data: response, error } = await this.makeRequest(url, this.GET);

            this.handleError(error)

            const assetCollection = useAssetCollection(type).collection;
            return cast ? new assetCollection(response.value) : response;
        } catch (error) {
            this.handleError(error)
            useLog().trace(error)
        }
    }

    async list (payload?:Payload, url?:any, cast:boolean = true){
        try{
            const request_url = this.getRequestUrl(this.path, url, { payload });
            const {data:response, error} = await this.makeRequest(request_url, this.GET);
            this.handleError(error)
            const assetCollection = useAssetCollection(url.replace(/\//g, '')).collection;
            return cast ? new assetCollection(response.value) : response;
        }
        catch (error){
            this.handleError(error)
            useLog().trace(error)
        }
    }

    // Retrieve single entity
    async get(id:any, payload?:Payload, url?:any, cast:boolean = true){
        try{
            const request_url = this.getRequestUrl(this.path, url, {
                payload,
                id,
            });
            const {data:response} = await this.makeRequest(request_url, this.GET);
            const assetModel = useAssetModel(url.replace(/\//g, '')).model;
            return cast ? new assetModel(response.value) : response;
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }

    //create entity
    async create(model:BaseModel, payload?:Payload, url?:any, cast:boolean = true){
        try{
            const request_url = this.getRequestUrl(this.path, url, { payload });
            const { data:response } = await this.makeRequest(request_url, this.POST, model)
            const assetModel = useAssetModel(url.replace(/\//g, '')).model;
            return cast ? new assetModel(response.value) : response;
        }
        catch(error){
            useLog().trace(error)
        }
    }

    //update entity
    async update(model:BaseModel, id:any, payload?:Payload, url?:any, cast:boolean = true){
        try{
            const request_url = this.getRequestUrl(this.path, url, {
                payload,
                id,
            });
            const {data:response} = await this.makeRequest(request_url, this.PUT, model)
            const assetModel = useAssetModel(url.replace(/\//g, '')).model;
            return cast ? new assetModel(response.value) : response;
        }
        catch(error){
            useLog().trace(error)
        }
    }

    // handle relations
    override async handleRelation(relation:string, model:any, payload?:Payload, url?:any, exclude?:string[]) {
        return super.handleRelation(relation, model, payload, url, exclude)
    }


}


export default AssetsApi