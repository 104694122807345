import Collection from "~/packages/core/models/Collection";
import PopupSiteModel from "~/packages/core/models/marketing/PopupSiteModel";

class PopupSitesCollection extends Collection {
    //define PopupModel to return model
    model(): typeof PopupSiteModel {
        return PopupSiteModel
    }

    /**** GETTERS ****/

}

export default PopupSitesCollection