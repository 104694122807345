import Collection from "~/packages/core/models/Collection";
import CaptionModel from "~/packages/core/models/tools/CaptionModel";

class CaptionsCollection extends Collection {
    /**
     * Define associated model to the CaptionsCollection
     */
    model(): typeof CaptionModel {
        return CaptionModel;
    }
}

export default CaptionsCollection;