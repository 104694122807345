import Collection from "~/packages/core/models/Collection";
import BadWordsModel from "~/packages/core/models/BadWordsModel";

class BadWordsCollection extends Collection {
    //define BadWordsModel model to return model
    model(): typeof BadWordsModel {
        return BadWordsModel
    }

    /**** GETTERS ****/
}

export default BadWordsCollection