import ArticleModel from "~/packages/core/models/ArticleModel";
import InterviewModel from "~/packages/core/models/InterviewModel";
import WhitepaperModel from "~/packages/core/models/WhitepaperModel";
import HandbookModel from "~/packages/core/models/HandbookModel";
import EventModel from "~/packages/core/models/content/EventModel";
import BlogPostModel from "~/packages/core/models/BlogPostModel";
import PressreleaseModel from "~/packages/core/models/PressreleaseModel";
import WebinarModel from "~/packages/core/models/training/WebinarModel";

const modelByType = {
    article: ArticleModel,
    video: ArticleModel,
    interview: InterviewModel,
    podcast: InterviewModel,
    whitepaper: WhitepaperModel,
    webinar: WebinarModel,
    handbook: HandbookModel,
    event: EventModel,
    blogpost: BlogPostModel,
    pressrelease: PressreleaseModel
};
const useAssetModel = (type: string) => {
    //@ts-ignore
    const model = modelByType[type];
    return { model };
};
export default useAssetModel;
