<template>
  <div >
    <span>{{sizes.join(' | ')}}</span>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  params: {
    type: null,
  },
});
 const sizes = [] as any[]

props.params.data.files.forEach(async (file:any) => {
  sizes.push(file.size);
})
</script>
