import BaseApi from "~/packages/core/services/BaseApi";
import BaseModel from "~/packages/core/models/BaseModel";

class UploadApi extends BaseApi {
    protected path:string = 'upload'

    /**
     * This method should pull the newly generated name for the resource to upload
     *
     * @param model
     * @param modelName
     * @param columnName
     */
    async generateUploadFileName(model:BaseModel, modelName: string, columnName: string){
        try{
            let data:any = {
                entityData: model.getModelData(),
                modelName,
                columnName
            };

            return await this.makeRequest(this.path + '/generate-upload-file-name' , this.POST, data)
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }

    async setUploadFileName(model:BaseModel, modelName: string, columnName: string) {
        try{
            let data:any = {
                entityData: model.getModelData(),
                modelName,
                columnName
            };

            const { data:response } = await this.makeRequest(this.path + '/set-upload-file-name' , this.POST, data)
            //@ts-ignore
            return response.value
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }

    async generateThumbFromPdf(model:BaseModel, modelName: string, pdfColName: string, columnName: string, container) {
        try{
            let data:any = {
                entityData: model.getModelData(),
                modelName,
                columnName,
                pdfColName,
                container
            };

            const { data:response } = await this.makeRequest(this.path + '/generate-thumb-from-pdf' , this.POST, data)
            //@ts-ignore
            return response.value
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }

    async removeDBResource(model:BaseModel, modelName: string, columnName: string) {
        try{
            let data:any = {
                entityData: model.getModelData(),
                modelName,
                columnName
            };

            const { data:response } = await this.makeRequest(this.path + '/remove-db-resource' , this.DELETE, data)
            //@ts-ignore
            return response.value
        }
        catch(error){
            useLog().trace(error)
            throw error
        }
    }

    async removeCloudResource(filename:string, container: string) {
        try{
            let data:any = {
                filename,
                container
            };

            const { data:response, error } = await this.makeRequest(this.path + '/remove-cloud-resource', this.DELETE, data)

            this.handleError(error)

            //@ts-ignore
            return response.value
        } catch(error) {
            this.handleError(error)
        }
    }
}

export default UploadApi