import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import StatesCollection from "~/packages/core/models/StatesCollection";
import StatesModel from "~/packages/core/models/StatesModel";

class StatesApi extends DefaultApi {
    protected path:string = 'states'
    protected collection:BaseCollection = StatesCollection
    protected model:BaseModel = StatesModel
}


export default StatesApi