import Collection from '~/packages/core/models/Collection';
import CampaignAssetModel from "~/packages/core/models/clientservices/CampaignAssetModel";

class CampaignAssetsCollection extends Collection {
    /**
     * Define associated model to the CampaignAssetsCollection
     */
    model(): typeof CampaignAssetModel {
        return CampaignAssetModel;
    }

    /** Getters **/
}

export default CampaignAssetsCollection;
