import GroupModel from "~/packages/core/models/GroupModel";
import {merge} from "lodash";

class AdvisorsBoardModel extends GroupModel {
    static permission_name: string = "board_of_advisers";
    casts() {
        return merge(super.casts(), {
            id: "uid",
            active: "string",
            authors: "array"
        });
    }
    /**** GETTERS ****/
    /** Methods **/
    async selectOptions (query:any){
        const authors = await new useAuthors().searchAuthors( query);
        return authors?.authorSelectOptions;
    }

}

export default AdvisorsBoardModel;
