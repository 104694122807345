import defaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CampaignUsersCollection from "~/packages/core/models/clientservices/CampaignUsersCollection";
import CampaignUserModel from "~/packages/core/models/clientservices/CampaignUserModel";

class CampaignUsersApi extends defaultApi {
    protected path: string = 'campaign_users'
    protected collection:BaseCollection = CampaignUsersCollection
    protected model:BaseModel = CampaignUserModel
    async searchCampaignUsers(query: string, payload?: {}, cast = true) {
        try {
            let url = `${this.path}/all?fields[]=id&fields[]=name_first& fields[]=name_last&order_by=name_first&order_direction=asc&search_fields=name_first,name_last&search=${query}`;

            const { data: response } = await this.makeRequest(url, this.GET);
            // @ts-ignore
            return cast ? new CampaignUsersCollection(response.value) : response;
        } catch (error) {
            useLog().trace(error)
        }
    }
}

export default CampaignUsersApi;
