import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import JobsCollection from "~/packages/core/models/jobboard/JobsCollection";
import JobModel from "~/packages/core/models/jobboard/JobModel";

class JobsApi extends DefaultApi {
    protected path:string = 'jobs'
    protected collection:BaseCollection = JobsCollection
    protected model:BaseModel = JobModel
}


export default JobsApi