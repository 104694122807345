import Model from "~/packages/core/models/Model";

class SiteModel extends Model {
  casts(): {} {
    return {
      site_character: "string",
      FQDN: "string",
      FQDN2: "string",
      BASESURL: "string",
      WNAME1: "string",
      FAMILY: "string",
      DOMAIN: "string",
      WNAME2: "string",
      WNAME4: "string",
    };
  }

  get id() {
    return this.site_character;
  }
}

export default SiteModel;