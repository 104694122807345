import Collection from "~/packages/core/models/Collection";
import JobTypeModel from "~/packages/core/models/jobboard/JobTypeModel";

class JobTypesCollection extends Collection {
    /**
     * Define associated model to the JobsCollection
     */
    model(): typeof JobTypeModel {
        return JobTypeModel;
    }
}

export default JobTypesCollection;