import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import PopupsCollection from "~/packages/core/models/marketing/PopupsCollection";
import PopupModel from "~/packages/core/models/marketing/PopupModel";
import PopupSettingsModel from "~/packages/core/models/marketing/PopupSettingsModel";

class PopupsApi extends DefaultApi {
  protected path:string = ''
  protected collection:BaseCollection = PopupsCollection
  protected model:BaseModel = PopupModel

  /**
   * The status is set depending on the path (anonymous or subscriber)
   * for anonymous the `override` field is set
   * for subscriber the `active` field is set
   * @param model
   * @param url
   */
  async setStatus(model:BaseModel, url?:string){
    const { data:response } = await this.processApiRequest(async () => {
      const request_url = this.getRequestUrl(this.path, `${url}/set_status`, {});
      return await this.makeRequest(request_url, this.POST, model)
    })

    return response.value;
  }

  /**
   * Reordering is set depending on the path (anonymous or subscriber)
   * for anonymous the `aorder` field is set
   * for subscriber the `order` field is set
   * @param model
   * @param url
   */
  async reorder(reordered_ids:string, url?:string){
    const { data:response } = await this.processApiRequest(async () => {
      const request_url = this.getRequestUrl(this.path, `${url}/reorder`, {});
      return await this.makeRequest(request_url, this.POST, {reordered_ids})
    })

    return response.value;
  }

  /**
   * get popups settings
   *
   * @param model
   * @param url
   */
  async getSettings(){
    const { data:response } = await this.processApiRequest(async () => {
      const request_url = this.getRequestUrl(this.path, `popups/get_settings`);
      return await this.makeRequest(request_url, this.GET)
    })

    return new PopupSettingsModel(response.value);
  }

  /**
   * Reordering is set depending on the path (anonymous or subscriber)
   * for anonymous the `aorder` field is set
   * for subscriber the `order` field is set
   * @param model
   */
  async setSettings(model:PopupSettingsModel){
    const { data:response } = await this.processApiRequest(async () => {
      const request_url = this.getRequestUrl(this.path, `popups/set_settings`, {});
      return await this.makeRequest(request_url, this.POST, model.getModelData())
    })

    return response.value;
  }
}


export default PopupsApi