import ArticleCollection from "../models/ArticleCollection";
import EventsCollection from "../models/content/EventsCollection";
import HandbooksCollection from "../models/HandbooksCollection";
import InterviewCollection from "../models/InterviewCollection";
import WhitepapersCollection from "../models/WhitepapersCollection";
import BlogPostsCollection from "../models/BlogPostsCollection";
import PressreleasesCollection from "~/packages/core/models/PressreleasesCollection";
import WebinarsCollection from "~/packages/core/models/training/WebinarsCollection";

const collectionByType = {
    article: ArticleCollection,
    video: ArticleCollection,
    interview: InterviewCollection,
    podcast: InterviewCollection,
    whitepaper: WhitepapersCollection,
    webinar: WebinarsCollection,
    handbook: HandbooksCollection,
    event: EventsCollection,
    blogpost: BlogPostsCollection,
    pressrelease:PressreleasesCollection
};

const useAssetCollection = (type: string) => {
    //@ts-ignore
    const collection = collectionByType[type];
    return { collection }
    // let collection = new collectionByType[type](response);
    // return collection
};
export default useAssetCollection;
