//@ts-ignore
export default defineNuxtPlugin(() => {
    // setup clarity
    if (process.client) {
        const clarityScript = document.createElement('script');
        clarityScript.type = 'text/javascript';
        clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/ofhywot5r2";
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "ofhywot5r2");
      `;
        document.head.appendChild(clarityScript);
    }
});
