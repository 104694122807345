import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseModel from "~/packages/core/models/BaseModel";
import AssetViewsModel from "~/packages/core/models/reports/AssetViewsModel";

class AssetViewsApi extends DefaultApi {
  protected path: string = "assets_views";
  protected model: BaseModel = AssetViewsModel;

  //generate report
  async generateReport(data: Object) {
    try {
      const { data: response, error } = await this.makeRequest(
        this.path,
        this.POST,
        data
      );

      this.handleError(error);
      //@ts-ignore
      return response.value;
    } catch (error) {
      this.handleError(error);
      useLog().trace(error);
    }
  }
}

export default AssetViewsApi