import Model from "~/packages/core/models/Model";
import { merge } from "lodash";
import type {ModelOptions} from "~/packages/core/models/BaseModel";

class SlotModel extends Model {
  casts() {
    return merge(super.casts(), {
      slot_id: "uid",
      webinar_id: "number",
      salesforce_id: "string",
      date: "string",
      time: "string",
      duration: "string",
      capacity: "string",
      autoresponder: "string",
      attendees: "string",
      login_link: "string",
      bigmarkerid: "string",
      formated_date: "string",
      date_time:"string",
    });
  }

  /** GETTERS **/
  getDefaultOptions(): ModelOptions {
    return {
      identifier: "slot_id",
    };
  }
}

export default SlotModel