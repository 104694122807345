  import BaseModel, { ModelOptions } from '~/packages/core/models/BaseModel'

  /**
   * default model Options
   * for now we only have the identifier,
   * but other options might come in handy
   * in the future
   */
  const defaultOptions = {
    // model key name
    identifier: 'user_id',
  };

  /**
   *
   */
  class MadminUserModel extends BaseModel {
    casts() {
      return {
        user_id: 'integer',
        azure_id: 'string',
        fullname: 'string',
        username: 'string',
        last_login: 'string',
        access_level: 'string'
      }
    }

    /**
     * uncomment this method in the model, if current model has different, key name, than
     * the default ('id')
     */
    getDefaultOptions(): ModelOptions {
      return defaultOptions
    }
  }

  export default MadminUserModel
