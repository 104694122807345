import DefaultApi from "~/packages/core/services/DefaultApi";
import BaseCollection from "~/packages/core/models/BaseCollection";
import BaseModel from "~/packages/core/models/BaseModel";
import CompanyAliasesCollection from "~/packages/core/models/userpolicy/CompanyAliasesCollection";
import CompanyAliasModel from "~/packages/core/models/userpolicy/CompanyAliasModel";

class CompanyAliasesApi extends DefaultApi {
    protected path:string = 'companies_aliases'
    protected collection:BaseCollection = CompanyAliasesCollection
    protected model:BaseModel = CompanyAliasModel
}

export default CompanyAliasesApi