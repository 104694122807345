import Model from "~/packages/core/models/Model";
import {toString} from "lodash";

class CountryModel extends Model {
  casts(): {} {
    return {
      code: "string",
      name: "string",
    };
  }

    get id() {
        // @ts-ignore
        return toString(this.code);
    }
}

export default CountryModel;
