import HandbookModel from '~/packages/core/models/HandbookModel';
import Collection from '~/packages/core/models/Collection';

class HandbooksCollection extends Collection {
    /**
     * Define associated model to the HandbooksCollection
     */
    model(): typeof HandbookModel {
        return HandbookModel;
    }

    /*Getters*/
    get selectOptions() {
        return this.map((asset: any) => {
            return { value: asset.id, title: asset.id + ' - ' + asset.title, id: asset.id }
        });
    }
}

export default HandbooksCollection;